import { observable, action } from "mobx";
import { checkInput } from "../constants/validation";
import axiFetch from "../config/fetch";

export default class CollectionStore {
  @observable
  data = null;

  @observable
  pagination = 0;

  @observable
  currentPage = 1;

  @observable
  bakeryDetail = null;

  @observable
  imageFile = null;

  @observable
  search = "";

  @observable
  searchOn = null;

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  openModal = false;

  @observable
  newArName = null;

  @observable
  newEnName = null;

  @observable
  btnDisable = false;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.newEnName,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.newArName,
      require: true,
      type: "string",
      min: 3,
    });

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewCollection(history);
  }

  @action
  getData = async (history) => {
    this.isLoading = false;
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    };
    const value = await axiFetch.request(
      "/collection/list",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.data = value.data.items;
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0);
      this.isLoading = true;
    } else {
      this.isLoading = true;
    }
  };

  @action
  handleSaveNewCollection = async (history) => {
    this.isLoading = false;
    this.btnDisable = true;
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
    };
    const value = await axiFetch.request(
      "collection/add",
      false,
      false,
      false,
      "post",
      false,
      data,
      history
    );
    if (value) {
      this.openModal = false;
      this.btnDisable = false;
      history.push(`/app/collection/detail/${value.data.response.id}`);
    } else {
      this.isLoading = true;
      this.btnDisable = false;
    }
  };

  @action
  changeOpenModal = () => {
    this.openModal = true;
  };

  @action
  toggleModal = () => {
    this.openModal = false;
    this.newArName = null;
    this.newEnName = null;
    this.en_name_validation = null;
    this.ar_name_validation = null;
  };

  @action
  changeNewEnName = (name) => {
    this.newEnName = name;
  };

  @action
  changeNewArName = (name) => {
    this.newArName = name;
  };

  @action
  changePage(e) {
    this.offset = (e - 1) * 12;
    this.currentPage = e;
    this.getData();
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value;
  };

  @action
  handleKeyPress = (e, history) => {
    if (e.key === "Enter") {
      this.searchOn = ["name_en", "name_ar"];
      this.offset = 0;
      this.currentPage = 1;
      this.getData(history);
    }
  };

  @action
  reset() {
    this.data = null;

    this.pagination = 0;

    this.currentPage = 1;

    this.bakeryDetail = null;

    this.imageFile = null;

    this.search = null;

    this.searchOn = null;

    this.limit = 12;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.openModal = false;

    this.newArName = null;

    this.newEnName = null;

    this.btnDisable = false;

    this.en_name_validation = null;

    this.ar_name_validation = null;
  }
}
