import React, { Component, Fragment } from 'react'
import { Row, Button, Card, CardBody } from 'reactstrap'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import IntlMessages from '../../../util/IntlMessages'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import DndCard from '../../../components/Card/dndCard'

// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

@inject('stores')
@observer
class Homepage extends Component {
  @observable
  store = this.props.stores.customHome
  constructor(props) {
    super(props)
    this.state = { items: null }
    // this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    // this.setState({ items: this.store.layout });
    this.store.getDefault(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  // onDragEnd(result) {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const items = reorder(
  //     this.state.items,
  //     result.source.index,
  //     result.destination.index
  //   );

  //   this.setState({
  //     items,
  //   });
  // }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.appHomepage" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.store.saveHomePage(this.props.history)}
                >
                  <IntlMessages id="pages.save" />
                </Button>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Colxx xxs="12" className="d-flex ">
              <Row className="col-1 d-flex flex-column align-items-center ">
                {this.store.items.map((item, index) => (
                  <Button
                    key={index + 1}
                    disabled={(item.selected && !item.isMulti) ||( item.isMulti && this.store.collection.length=== this.store.collectionsId.length )? true : false}
                    onClick={() => {
                      this.store.handleChangeLayout(item)
                    }}
                    color="primary"
                    className="btn-shadow text-center p-0 m-0 mb-2"
                    style={{
                      width: '75px',
                      height: '75px',
                      borderRadius: '0.75rem',
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Row>
              <Row className="col-8">
                <Colxx xxs="12">
                  {this.store.layout ? (
                    <Card
                      className="col-12"
                      style={{ height: 'max-content', overflow: 'unset' }}
                    >
                      <CardBody>
                        <DndCard data={this.store.layout} />
                      </CardBody>
                    </Card>
                  ) : null}
                </Colxx>
              </Row>
            </Colxx>
          </>
        )}
      </Fragment>
    )
  }
}

export default Homepage
