import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

@inject("stores")
@observer
class ProfileList extends Component {
  @observable
  store = this.props.stores.profile;

  componentDidMount() {
    this.store.getProfileDetail(this.props.history);
  }

  componentWillMount() {
    this.store.reset();
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          isOpen={this.store.openPassModal}
          toggle={() => this.store.togglePassModal()}
          wrapClassName="modal-right"
          backdrop="static"
        >
          <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
          <ModalBody>
            <Colxx xxs="12" lg="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.old-password" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="password"
                  type="text"
                  value={this.store.password}
                  onChange={(e) => this.store.changePassword(e.target.value)}
                />
              </p>
              <span className="req-validation ">
                {this.store.password_validation
                  ? this.store.password_validation.msg
                    ? this.store.password_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            <Colxx xxs="12" lg="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.new-password" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="recent_password"
                  type="text"
                  value={this.store.recent_password}
                  onChange={(e) =>
                    this.store.changeRecentPassword(e.target.value)
                  }
                />
              </p>
              <span className="req-validation ">
                {this.store.recent_password_validation
                  ? this.store.recent_password_validation.msg
                    ? this.store.recent_password_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            <Colxx xxs="12" lg="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.confirm-password" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="confirm_pass"
                  value={this.store.confirm_pass}
                  type="text"
                  onChange={(e) =>
                    this.store.changeConfirmPassword(e.target.value)
                  }
                />
              </p>
              <span className="req-validation ">
                {this.store.confirm_pass_validation
                  ? this.store.confirm_pass_validation.msg
                    ? this.store.confirm_pass_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.store.isLoading}
              color="primary"
              className="btn-shadow"
              size="lg"
              onClick={() => this.store.changePassValidation()}
            >
              Save
            </Button>
            <Button
              className="btn-outline-secondary btn-lg btn-shadow"
              onClick={() => this.store.togglePassModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.profile" />}
                match={this.props.match}
              />
              <Button
                color="primary"
                className="btn-shadow float-right mx-2"
                size="lg"
                onClick={() => this.store.changePassModal()}
              >
                <IntlMessages id="pages.change-password" />
              </Button>
              <Button
                disabled={this.store.saveBtnDisable}
                color="primary"
                className="btn-shadow float-right mx-2"
                size="lg"
                onClick={() => this.store.saveProfileValidation()}
              >
                Save
              </Button>
              <Separator className="mb-5" />
              <Colxx xxs="12">
                <Row className="d-flex justify-content-center">
                  <Colxx
                    xxs="12"
                    lg="12"
                    className="mb-6 border-bottom"
                    style={{ marginBottom: "25px" }}
                  >
                    <Row>
                      <Colxx xxs="12" sm="8" lg="6" className="mb-4">
                        <Card className="mb-2">
                          <CardBody>
                            <CardTitle className="mb-0">
                              <span className="font-weight-bold text-uppercase">
                                {`Profile`}
                              </span>
                            </CardTitle>
                            <Separator className="mb-5" />

                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="Name" />
                                <span className="req"> *</span>
                              </p>
                              <p className="mb-0">
                                <Input
                                  name="name"
                                  value={
                                    this.store.profileDetail
                                      ? this.store.profileDetail.name
                                      : ""
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeName(e.target.value)
                                  }
                                />
                              </p>
                              <span className="req-validation mb-3">
                                {this.store.name_validation
                                  ? this.store.name_validation.msg
                                    ? this.store.name_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2 mt-3">
                                <IntlMessages id="pages.email" />
                              </p>
                              <p className="mb-3">
                                {this.store.profileDetail
                                  ? ` ${this.store.profileDetail.email} `
                                  : ""}
                              </p>
                            </Colxx>
                            <Colxx xxs="12" lg="12">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.type" />
                              </p>
                              <p className="mb-3">
                                {this.store.profileDetail
                                  ? ` ${this.store.profileDetail.type} `
                                  : ""}
                              </p>
                            </Colxx>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Colxx>
                </Row>
              </Colxx>
            </Colxx>
          </Row>
        )}
      </>
    );
  }
}

export default ProfileList;
