import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import AreaList from './area'
import DayList from './day'
import classnames from 'classnames'
import CustomCake from './custom'
import Uploader from '../../../components/Uploader'

@inject('stores')
@observer
class BakeryDetail extends Component {
  @observable
  store = this.props.stores.bakeryDetail

  @observable
  login = this.props.stores.login

  @observable
  customCake = this.props.stores.customCake

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false }
  }
  async componentDidMount() {
    let path = this.login.bakeryId
    this.setState({ id: path, activeFirstTab: '1' })
    this.store.getBakeryDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  toggleTab = (id) => {
    this.setState({ activeFirstTab: id })
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFileMain = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderMain(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.showUploaderCover &&
      !this.store.coverImageFile &&
      this.store.validation(this.state.id, this.props.history)
  }

  changeImageFileCover = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploderCover(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.validation(this.state.id, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.bakery" />}
                match={this.props.match}
              />
              {/* <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button> */}
              <DeleteModal
                target={
                  this.store.bakeryDetail
                    ? this.store.bakeryDetail.name_en
                    : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteBakery(this.state.id, this.props.history)
                }
              />
              {this.state.activeFirstTab === '3' ? (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.store.areaValidation(this.state.id, this.props.history)
                  }
                >
                  <IntlMessages id="pages.saveArea" />
                </Button>
              ) : this.state.activeFirstTab === '2' ||
                this.state.activeFirstTab === '1' ? (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => {
                    this.store.showUploaderMain && this.store.showUploaderCover
                      ? this.store.validation(this.state.id, this.props.history)
                      : this.handleSave()
                  }}
                >
                  <IntlMessages id="pages.save" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.customCake.handleSave(
                      this.state.id,
                      this.props.history
                    )
                  }
                >
                  <IntlMessages id="pages.saveCustomCake" />
                </Button>
              )}
              <Colxx xs="12" className="p-0">
                <Nav tabs className="separator-tabs m-0 mb-5 ">
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '1',
                      })}
                      onClick={() => {
                        this.toggleTab('1')
                      }}
                      to="#"
                    >
                      <IntlMessages id="pages.details" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '2',
                      })}
                      onClick={() => this.toggleTab('2')}
                      to="#"
                    >
                      <IntlMessages id="pages.timing" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '3',
                      })}
                      onClick={() => this.toggleTab('3')}
                      to="#"
                    >
                      <IntlMessages id="pages.areas" />
                    </NavLink>
                  </NavItem>

                  <NavItem className="pointer">
                    <NavLink
                      className={classnames('pointer', {
                        active: this.state.activeFirstTab === '4',
                      })}
                      onClick={() => this.toggleTab('4')}
                      to="#"
                    >
                      <IntlMessages id="pages.customCake" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Colxx>
            </Colxx>
            {this.state.activeFirstTab === '1' ? (
              <>
                <Colxx xxs="12">
                  <Row className="d-flex ">
                    <Colxx
                      xxs="12"
                      lg="12"
                      className="mb-6 border-bottom"
                      style={{ marginBottom: '25px' }}
                    >
                      <Row>
                        <Colxx xxs="12" sm="6" lg="3" className="mb-4">
                          <Card className="mb-2 h-100">
                            <CardBody>
                              <CardTitle className="mb-3">
                                <span className="font-weight-bold text-uppercase">
                                  {`Image`}
                                </span>
                              </CardTitle>
                              <Separator className="mb-5" />
                              {this.store.showUploaderMain ? (
                                <>
                                  {' '}
                                  <Button
                                    color="primary"
                                    size="lg"
                                    className="pointer mb-4 col-12"
                                    style={{}}
                                    onClick={() => {
                                      this.store.handleImageUploaderMain()
                                    }}
                                  >
                                    <Label
                                      className="btn btn-default no-padding col-12"
                                      style={{
                                        padding: '0px',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      <strong>Change Image</strong>
                                    </Label>
                                  </Button>
                                  <Thumb
                                    key="cat-image"
                                    className="almost-full-width"
                                    file={this.store.imageFile}
                                    src={
                                      this.store.bakeryDetail.image
                                        ? this.store.bakeryDetail.image.url
                                        : '/assets/img/noimage.png'
                                    }
                                  />
                                </>
                              ) : (
                                <Uploader
                                  multiple={true}
                                  ref={(instance) =>
                                    (this.productDetail = instance)
                                  }
                                  images={this.state.imageFile}
                                  getImage={this.state.getImage}
                                  flag={true}
                                  ratio="1:1"
                                  size={{ width: 400, height: 400 }}
                                  handleImageUploader={() =>
                                    this.store.handleImageUploaderMain()
                                  }
                                  changeImageFile={(data) => {
                                    this.changeImageFileMain(data)
                                  }}
                                />
                              )}
                              {/* <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-3 col-12"
                                style={{}}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Image</strong>
                                  <input
                                    className="col-12 p-0 "
                                    id="image"
                                    name="image"
                                    type="file"
                                    accept="image/x-png,image/jpeg"
                                    onChange={(event) => {
                                      this.store.changeImageFile(
                                        event.currentTarget.files[0]
                                      )
                                    }}
                                    hidden
                                  />
                                </Label>
                              </Button>
                              <Thumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFile}
                                src={
                                  this.store.bakeryDetail.image
                                    ? this.store.bakeryDetail.image.url
                                    : '/assets/img/noimage.png'
                                }
                              /> */}
                              <span className="req-validation ">
                                {this.store.imageFile_validation
                                  ? this.store.imageFile_validation.msg
                                    ? this.store.imageFile_validation.msg
                                    : null
                                  : null}
                              </span>
                            </CardBody>
                          </Card>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3" className="mb-4">
                          <Card className="mb-2 h-100">
                            <CardBody>
                              <CardTitle className="mb-3">
                                <span className="font-weight-bold text-uppercase">
                                  {`Cover Image`}
                                </span>
                              </CardTitle>
                              <Separator className="mb-5" />
                              {this.store.showUploaderCover ? (
                                <>
                                  {' '}
                                  <Button
                                    color="primary"
                                    size="lg"
                                    className="pointer mb-4 col-12"
                                    style={{}}
                                    onClick={() => {
                                      this.store.handleImageUploaderCover()
                                    }}
                                  >
                                    <Label
                                      className="btn btn-default no-padding col-12"
                                      style={{
                                        padding: '0px',
                                        marginBottom: '0px',
                                      }}
                                    >
                                      <strong>Change Cover Image</strong>
                                    </Label>
                                  </Button>
                                  <Thumb
                                    key="cat-image"
                                    className="almost-full-width"
                                    file={this.store.coverImageFile}
                                    src={
                                      this.store.bakeryDetail.cover_image
                                        ? this.store.bakeryDetail.cover_image
                                            .url
                                        : '/assets/img/noimage.png'
                                    }
                                  />
                                </>
                              ) : (
                                <Uploader
                                  multiple={true}
                                  ref={(instance) =>
                                    (this.productDetail = instance)
                                  }
                                  images={this.state.imageFile}
                                  getImage={this.state.getImage}
                                  flag={true}
                                  ratio="1:1"
                                  size={{ width: 400, height: 400 }}
                                  handleImageUploader={() =>
                                    this.store.handleImageUploaderCover()
                                  }
                                  changeImageFile={(data) => {
                                    this.changeImageFileCover(data)
                                  }}
                                />
                              )}
                              {/* <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-3 col-12"
                                style={{}}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Cover Image</strong>
                                  <input
                                    className="col-12 p-0 "
                                    id="image"
                                    name="image"
                                    type="file"
                                    accept="image/x-png,image/jpeg"
                                    onChange={(event) => {
                                      this.store.changeCoverImageFile(
                                        event.currentTarget.files[0]
                                      )
                                    }}
                                    hidden
                                  />
                                </Label>
                              </Button>
                              <Thumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.coverImageFile}
                                src={
                                  this.store.bakeryDetail.cover_image
                                    ? this.store.bakeryDetail.cover_image.url
                                    : '/assets/img/noimage.png'
                                }
                              /> */}
                              <span className="req-validation ">
                                {this.store.coverImageFile_validation
                                  ? this.store.coverImageFile_validation.msg
                                    ? this.store.coverImageFile_validation.msg
                                    : null
                                  : null}
                              </span>
                            </CardBody>
                          </Card>
                        </Colxx>
                        <Colxx xxs="12" sm="12" lg="6" className="mb-4 ">
                          <Card className="mb-2 h-100">
                            <CardBody>
                              <CardTitle className="mb-3 col-12">
                                <span className="font-weight-bold text-uppercase">
                                  {`Detail`}
                                </span>
                              </CardTitle>
                              <Separator className="mb-5" />
                              <Colxx xxs="12" className="d-flex flex-wrap">
                                <Colxx xxs="12" lg="6">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.name" />
                                    <span className="req"> *</span>
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="name"
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.name_en
                                          : ''
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        this.store.changeEnName(e.target.value)
                                      }
                                    />
                                    <span className="req-validation ">
                                      {this.store.en_name_validation
                                        ? this.store.en_name_validation.msg
                                          ? this.store.en_name_validation.msg
                                          : null
                                        : null}
                                    </span>
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" lg="6">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.name-ar" />
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="name_ar"
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.name_ar
                                          : ''
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        this.store.changeArName(e.target.value)
                                      }
                                      className="ar"
                                    />
                                  </div>
                                  <span className="req-validation ">
                                    {this.store.ar_name_validation
                                      ? this.store.ar_name_validation.msg
                                        ? this.store.ar_name_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </Colxx>

                                <Colxx xxs="12" lg="6" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.phone" />
                                    <span className="req"> *</span>
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="phone"
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.phone
                                          : ''
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        this.store.changePhone(e.target.value)
                                      }
                                    />
                                  </div>
                                  <span className="req-validation ">
                                    {this.store.phone_validation
                                      ? this.store.phone_validation.msg
                                        ? this.store.phone_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </Colxx>

                                <Colxx xxs="12" lg="6" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.pre_order" />
                                  </p>
                                  <div className="mb-0">
                                    <FormikSwitch
                                      className="custom-switch custom-switch-primary-inverse mr-2"
                                      name={`pre_order`}
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail
                                              .pre_order === 'Available'
                                            ? true
                                            : false
                                          : true
                                      }
                                      onChange={() =>
                                        this.store.handleChangePreOrder(
                                          this.store.bakeryDetail.pre_order
                                        )
                                      }
                                      // onBlur={handleBlur}
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" lg="6" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.is_available" />
                                  </p>
                                  <div className="mb-0">
                                    <FormikSwitch
                                      className="custom-switch custom-switch-primary-inverse mr-2"
                                      name={`is_available`}
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.is_available
                                          : true
                                      }
                                      onChange={() =>
                                        this.store.handleChangeIsAvailable(
                                          this.store.bakeryDetail.is_available
                                        )
                                      }
                                      // onBlur={handleBlur}
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" lg="6" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.custom_cake_available" />
                                  </p>
                                  <div className="mb-0">
                                    <FormikSwitch
                                      className="custom-switch custom-switch-primary-inverse mr-2"
                                      name={`is_available`}
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail
                                              .custom_cake_available
                                          : true
                                      }
                                      onChange={() =>
                                        this.store.handleChangeCustomCakeAvailable(
                                          this.store.bakeryDetail
                                            .custom_cake_available
                                        )
                                      }
                                      // onBlur={handleBlur}
                                    />
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" lg="12" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.address" />
                                    <span className="req"> *</span>
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="address"
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.address
                                          : ''
                                      }
                                      type="textarea"
                                      onChange={(e) =>
                                        this.store.changeAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                  <span className="req-validation ">
                                    {this.store.address_validation
                                      ? this.store.address_validation.msg
                                        ? this.store.address_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </Colxx>
                                <Colxx xxs="12" lg="12" className="mt-3">
                                  <p className="text-muted text-small mb-2">
                                    <IntlMessages id="pages.arAddress" />
                                    <span className="req"> *</span>
                                  </p>
                                  <div className="mb-0">
                                    <Input
                                      name="arabicAddress"
                                      className="ar"
                                      value={
                                        this.store.bakeryDetail
                                          ? this.store.bakeryDetail.address_ar
                                            ? this.store.bakeryDetail.address_ar
                                            : ''
                                          : ''
                                      }
                                      type="textarea"
                                      onChange={(e) =>
                                        this.store.changeArAddress(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <span className="req-validation ">
                                    {this.store.ar_address_validation
                                      ? this.store.ar_address_validation.msg
                                        ? this.store.ar_address_validation.msg
                                        : null
                                      : null}
                                  </span>
                                </Colxx>
                              </Colxx>
                            </CardBody>
                          </Card>
                        </Colxx>
                      </Row>
                    </Colxx>
                  </Row>
                </Colxx>
              </>
            ) : this.state.activeFirstTab === '2' ? (
              <DayList />
            ) : this.state.activeFirstTab === '3' ? (
              <AreaList />
            ) : (
              <CustomCake id={this.state.id} />
            )}
          </Row>
        )}
      </Fragment>
    )
  }
}

export default BakeryDetail
