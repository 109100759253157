import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CollectionList from './list';
import CollectionDetail from './detail';



const Collection = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={CollectionList} />
            <Route path={`${match.url}/detail/:id`} component={CollectionDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Collection;