import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ShapeList from './list';
import ShapeDetail from './detail';



const Shape = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={ShapeList} />
            <Route path={`${match.url}/detail/:id`} component={ShapeDetail} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Shape;