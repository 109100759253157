import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Row, Button } from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardThumbList from '../../../components/Card/cardThumblist'
import UserModal from '../../../components/Modal/userModal'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer } from 'react-toastify'

@inject('stores')
@observer
class UserList extends Component {
  @observable
  store = this.props.stores.user

  @observable
  login = this.props.stores.login

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <BreadcrumbContainer
                  heading={<IntlMessages id="menu.users" />}
                  match={this.props.match}
                />
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() => this.store.changeOpenModal()}
                >
                  <IntlMessages id="pages.add-new" />
                </Button>
                <UserModal
                  openModal={this.store.openModal}
                  toggleModal={() => this.store.toggleModal()}
                  name={this.store.name}
                  email={this.store.email}
                  typesList={this.store.typesList}
                  bakeriesList={this.store.bakeriesList}
                  selectedType={this.store.selectedType}
                  selectedBakery={this.store.selectedBakery}
                  name_validation={this.store.name_validation}
                  email_validation={this.store.email_validation}
                  type_validation={this.store.type_validation}
                  bakery_validation={this.store.bakery_validation}
                  changeName={(data) => this.store.changeName(data)}
                  changeEmail={this.store.changeEmail}
                  setType={(value) => this.store.setType(value)}
                  setBakery={(value) => this.store.setBakery(value)}
                  handleSave={() => this.store.validation(this.props.history , this.login.userType, this.login.bakeryId)}
                  btnDisable={this.store.btnDisable}
                  target="user"
                  userType = {this.login.userType}
                />
                <Separator className="mb-5" />
              </Colxx>
            </Row>

            <Row>
              <Colxx xxs="12">
                <Row>
                  {this.store.data
                    ? this.store.data.map((ingredient) => (
                        <CardThumbList
                          key={ingredient.id}
                          data={ingredient}
                          path="users/detail"
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default UserList
