import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import _ from 'lodash'
import axiFetch from '../config/fetch'
import { successNotify } from '../util/Notify'

export default class CakeDetailStore {
  @observable
  isLoading = false

  @observable
  openItemModal = false

  @observable
  arName = null

  @observable
  enName = null

  @observable
  price = null

  @observable
  enDescription = null

  @observable
  arDescription = null

  @observable
  productList = null

  @observable
  imageFile = null

  @observable
  images = null

  @observable
  fillings = []

  @observable
  shapes = []

  @observable
  flavors = []

  @observable
  category = null

  @observable
  categoryId = null

  @observable
  categoriesList = []

  @observable
  shapeList = []

  @observable
  shapesId = []

  @observable
  flavorList = []

  @observable
  flavorsId = []

  @observable
  fillingList = []

  @observable
  fillingsId = []

  @observable
  layerList = [{ value: 'nothing', label: '---' }]

  @observable
  layer = null

  @observable
  layerId = null

  @observable
  servingList = []

  @observable
  serving = null

  @observable
  servingId = null

  @observable
  data = null

  @observable
  cakeDetail = null

  @observable
  orderImages = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  serving_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  bakery_validation = null

  @observable
  searchProduct = null

  @observable
  searchOnProduct = null

  @observable
  limitProduct = 12

  @observable
  offsetProduct = 0

  @observable
  orderProduct = { id: -1 }

  @observable
  filterProduct = {}

  @observable
  currentPageProduct = 1

  @observable
  isLoadingProduct = false

  @observable
  openDeleteModal = false

  @observable
  openProductModal = false

  @observable
  pagination = null

  @observable
  paginationProduct = null

  @observable
  is_available = true

  @observable
  old_price = null

  @observable
  discount = true

  @observable
  bakeriesList = []

  @observable
  bakery = null

  @observable
  outerShellsList = []

  @observable
  outerShell = null

  @observable
  en_description_validation = null

  @observable
  showUploader = true

  @action
  handleImageUploader = () => {
    this.showUploader = !this.showUploader
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file, id, history) {
    if (file) {
      this.imageFile = await this.base64ToBlob(file, 'image/jpg')

      const data = {
        target_id: id,
        image: this.imageFile,
      }
      let formData = new FormData()
      for (let key in data) formData.append(key, data[key])

      const value = await axiFetch.request(
        'cake/media/add',
        false,
        null,
        false,
        'post',
        false,
        formData,
        history
      )
      if (value) {
        this.images = value.data
        successNotify('Item has been added successfully')
        this.handleImageUploader()
      }
    }
  }

  @action
  handleDownloadImage = () => {
    var a = document.createElement('a')
    a.href = this.images[0].url
    a.download = 'download'
    a.target = 'blank'
    a.click()
    a = null
  }

  @action
  async validation(id) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.enName,
      require: true,
      type: 'string',
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.arName,
      require: true,
      type: 'string',
    })
    this.category_validation = await checkInput({
      name: 'Category',
      value: this.category,
      require: true,
    })

    this.bakery_validation = await checkInput({
      name: 'Bakery',
      value: this.bakery,
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.price,
      require: true,
      type: 'number',
      min: 1,
    })
    this.serving_validation = await checkInput({
      name: 'Serving',
      value: this.serving,
      require: true,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.enDescription,
      require: true,
      type: 'string',
      min: 3,
    })

    this.price_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.serving_validation.res &&
      this.category_validation.res &&
      this.bakery_validation.res &&
      this.en_description_validation.res &&
      this.handleUpdateCake(id)
  }

  @action
  async getCakeDetail(id, history) {
    this.isLoading = false
    this.imageFile = null
    const value = await axiFetch.request(
      'cake',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      await value.data.shape.map((item) =>
        this.shapes.push({
          value: item.id,
          label: item.name,
          image: item.image.url,
        })
      )
      await value.data.flavor.map((item) =>
        this.flavors.push({
          value: item.id,
          label: item.name,
          image: item.image.url,
        })
      )

      await value.data.filling.map((item) =>
        this.fillings.push({
          value: item.id,
          label: item.name,
          image: item.image.url,
        })
      )
      this.images = value.data.images
      this.price = value.data.price
      this.cakeDetail = value.data
      
        this.category = value.data.category.id ?[
        {
          value: value.data.category.id,
          label: value.data.category.name,
          image: value.data.category.image.url,
        },
      ]:[]
    
      
      this.categoryId = value.data.category.id
      this.layer = value.data.layer.id
        ? { value: value.data.layer.id, label: value.data.layer.name }
        : { value: 'nothing', label: '---' }
      this.layerId = value.data.layer.id && value.data.layer.id
      this.serving = {
        value: value.data.serving.id,
        label: value.data.serving.name,
      }
      this.bakery = {
        value: value.data.bakery.id,
        label: value.data.bakery.name,
        image: value.data.bakery.image.url,
      }
      this.outerShell = value.data.outershell.id && {
        value: value.data.outershell.id,
        label: value.data.outershell.name,
        image: value.data.outershell.image.url,
      }
      this.servingId = value.data.serving.id
      this.enName = value.data.name_en
      this.arName = value.data.name_ar
      this.enDescription = value.data.description_en
      this.arDescription = value.data.description_ar
      this.productList = value.data.similar
      this.is_available = value.data.is_available
      this.old_price = value.data.old_price
      this.discount = value.data.old_price ? true : false
      this.isLoading = true
      this.getDependencies(history)
    }
  }

  @action
  async getDependencies(history) {
    this.isLoading = false
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    this.data = value.data
    await value.data.categories.map((category) =>
      this.categoriesList.push({
        value: category.id,
        label: category.title,
        image: category.image.url,
      })
    )
    this.categoriesList = _.differenceWith(
      this.categoriesList,
      this.category,
      _.isEqual
    )

    await value.data.flavors.map((flavor) =>
      this.flavorList.push({
        value: flavor.id,
        label: flavor.name,
        image: flavor.image.url,
      })
    )
    this.flavorList = _.differenceWith(this.flavorList, this.flavors, _.isEqual)

    await value.data.shapes.map((shape) =>
      this.shapeList.push({
        value: shape.id,
        label: shape.name,
        image: shape.image.url,
      })
    )

    this.shapeList = _.differenceWith(this.shapeList, this.shapes, _.isEqual)

    await value.data.fillings.map((filling) =>
      this.fillingList.push({
        value: filling.id,
        label: filling.name,
        image: filling.image.url,
      })
    )
    this.fillingList = _.differenceWith(
      this.fillingList,
      this.fillings,
      _.isEqual
    )

    await value.data.layers.map((layer) =>
      this.layerList.push({ value: layer.id, label: layer.name })
    )

    await value.data.servings.map((item) =>
      this.servingList.push({ value: item.id, label: item.name })
    )

    await value.data.bakeries.map((bakery) =>
      this.bakeriesList.push({
        value: bakery.id,
        label: bakery.name_en,
        image: bakery.image.url,
      })
    )

    await value.data.outerShells.map((outerShell) =>
      this.outerShellsList.push({
        value: outerShell.id,
        label: outerShell.name_en,
        image: outerShell.image.url,
      })
    )

    this.isLoading = true
  }

  @action
  async deleteCake(id, history) {
    this.isLoading = false
    const value = await axiFetch.request(
      'cake',
      false,
      id,
      false,
      'get',
      'delete',
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/product')
      }, 1000)
    } else {
      this.isLoading = true
    }
  }

  @action
  handleUpdateCake = async (id, history) => {
    this.isLoading = false
    const similar = []

    await this.productList.forEach((item) => {
      similar.push(item.id)
    })
    await this.fillings.forEach((item) => this.fillingsId.push(item.value))

    await this.flavors.forEach((item) => this.flavorsId.push(item.value))

    await this.shapes.forEach((item) => this.shapesId.push(item.value))

    if (!this.orderImages) {
      this.orderImages = []
      await this.images.forEach((item) => this.orderImages.push(item.id))
    }
    const data = {
      name_en: this.enName,
      name_ar: this.arName,
      price: this.price,
      description_en: this.enDescription,
      images: JSON.stringify(this.orderImages),
      serving_id: this.serving.value,
      category_id: this.category[0].value,
      bakery_id: this.bakery.value,
      similar: JSON.stringify(similar),
      is_available: this.is_available,
      old_price: this.discount ? this.old_price : null,
    }

    if (this.arDescription) {
      data.description_ar = this.arDescription
    }
    if (this.layer) {
      data.layer_id = this.layer.value !== 'nothing' ? this.layer.value : null
    }
    if (this.outerShell) {
      data.outershell_id = this.outerShell.value
    }
    if (this.fillingsId.length) {
      data.fillings = JSON.stringify(this.fillingsId)
    }

    if (this.shapesId.length) {
      data.shapes = JSON.stringify(this.shapesId)
    }

    if (this.flavorsId.length) {
      data.flavors = JSON.stringify(this.flavorsId)
    }

    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'cake',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getCakeDetail(id, history)
    } else {
      this.isLoading = true
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeOpenItemModal = () => {
    this.openItemModal = true
  }

  @action
  toggleItemModal = () => {
    this.openItemModal = false
  }

  @action
  changeEnName(value) {
    this.enName = value
  }

  @action
  changeArName(value) {
    this.arName = value
  }

  @action
  changeEnDescription(value) {
    this.enDescription = value
  }

  @action
  changeArDescription(value) {
    this.arDescription = value
  }

  @action
  changePrice(value) {
    this.price = value
  }

  @action
  setItems(data, target) {
    switch (target) {
      case 'flavor':
        this.setFlavors(data)
        break
      case 'filling':
        this.setFillings(data)
        break
      case 'shape':
        this.setShapes(data)
        break
      case 'category':
        this.setCategory(data)
        break
      case 'bakery':
        this.setBakery(data)
        break
      case 'outerShell':
        this.setOuterShell(data)
        break
      default:
        break
    }
  }

  @action
  async setFlavors(value) {
    await this.flavors.push(...value)
    this.flavorList = await _.differenceWith(
      this.flavorList,
      this.flavors,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeFlavor(value) {
    const data = []
    this.flavors.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.flavors = data
    this.flavorList.push(value)
  }

  @action
  async setFillings(value) {
    await this.fillings.push(...value)
    this.fillingList = await _.differenceWith(
      this.fillingList,
      this.fillings,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeFilling(value) {
    const data = []
    this.fillings.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.fillings = data
    this.fillingList.push(value)
  }

  @action
  async setShapes(value) {
    await this.shapes.push(...value)
    this.shapeList = await _.differenceWith(
      this.shapeList,
      this.shapes,
      _.isEqual
    )
    this.toggleItemModal()
  }

  @action
  removeShape(value) {
    const data = []
    this.shapes.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.shapes = data
    this.shapeList.push(value)
  }

  @action
  setLayer(value) {
    this.layer = value.selectedOption
  }

  @action
  setServing(value) {
    this.serving = value.selectedOption
  }

  @action
  async setCategory(value) {
    await this.category.push(...value)
    this.categoriesList = await _.differenceWith(
      this.categoriesList,
      this.category,
      _.isEqual
    )
    this.toggleItemModal()
  }
  @action
  removeCategory(value) {
    const data = []
    this.category.forEach((item) => {
      if (item.value !== value.value) {
        data.push(item)
      }
    })
    this.category = data
    this.categoriesList.push(value)
  }

  @action
  async setBakery(value) {
    this.bakery = value[0]
  }

  @action
  removeBakery = () => {
    this.bakery = null
  }

  @action
  async setOuterShell(value) {
    this.outerShell = value[0]
  }

  @action
  removeOuterShell = () => {
    this.outerShell = null
  }

  @action
  getProduct = async (history) => {
    this.isLoadingProduct = true
    this.changeOpenProductModal()
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    }

    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getcakes',
      body,
      history
    )
    if (value) {
      this.products = value.data.items
      this.paginationProduct =
        parseInt(value.data.pagination.total / this.limitProduct) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoadingProduct = false
    }
  }

  @action
  changeDiscount(target, value) {
    this.discount = value
  }

  @action
  async handleDeleteImage(id, history) {
    const value = await axiFetch.request(
      'cake',
      'media',
      id,
      false,
      'get',
      'delete',
      null,
      history
    )

    if (value) {
      successNotify('Item has been deleted successfully')
      this.images = value.data
    }
  }

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true
  }

  @action
  toggleProductModal = () => {
    this.openProductModal = false
  }

  @action
  changePage(e) {
    this.offsetProduct = (e - 1) * 12
    this.currentPageProduct = e
    this.getProduct()
  }

  @action
  addItemToSelected = async (value) => {
    await value.map((item) => this.productList.push(item))

    const intersection = _.intersectionBy(this.productList, 'id')
    this.productList = [...intersection]
  }

  @action
  removeFromList = (id) => {
    const product = this.productList.filter((item) => item.id !== id)
    this.productList = product
  }

  @action
  changeOrderImages(arr) {
    this.orderImages = arr
  }

  @action
  handleIsAvailable(e, value) {
    this.is_available = value
  }

  @action
  changeOldPrice(value) {
    this.old_price = value
  }

  @action
  reset() {
    this.isLoading = false

    this.openItemModal = false

    this.arName = null

    this.enName = null

    this.price = null

    this.enDescription = null

    this.arDescription = null

    this.productList = null

    this.imageFile = null

    this.images = null

    this.fillings = []

    this.shapes = []

    this.flavors = []

    this.category = null

    this.categoryId = null

    this.categoriesList = []

    this.shapeList = []

    this.shapesId = []

    this.flavorList = []

    this.flavorsId = []

    this.fillingList = []

    this.fillingsId = []

    this.layerList = [{ value: 'nothing', label: '---' }]

    this.layer = null

    this.layerId = null

    this.servingList = []

    this.serving = null

    this.servingId = null

    this.data = null

    this.cakeDetail = null

    this.orderImages = null

    this.search = null

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.openDeleteModal = false

    this.en_name_validation = null

    this.ar_name_validation = null

    this.serving_validation = null

    this.price_validation = null

    this.category_validation = null

    this.bakery_validation = null

    this.searchProduct = null

    this.searchOnProduct = null

    this.limitProduct = 12

    this.offsetProduct = 0

    this.orderProduct = { id: -1 }

    this.filterProduct = {}

    this.currentPageProduct = 1

    this.isLoadingProduct = false

    this.openDeleteModal = false

    this.openProductModal = false

    this.pagination = null

    this.paginationProduct = null

    this.is_available = true

    this.old_price = null

    this.discount = true

    this.bakeriesList = []

    this.bakery = null

    this.outerShellsList = []

    this.outerShell = null

    this.en_description_validation = null

    this.showUploader = true
  }
}
