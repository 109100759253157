import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import moment from 'moment'
import { successNotify } from '../util/Notify'

export default class OrderStore {
  @observable
  data = null

  @observable
  filterOptions = [
    { column: 'all', label: 'All' },
    { column: 'preparing', label: 'Preparing', key: '0' },
    { column: 'sent', label: 'In Transit', key: '1' },
    { column: 'delivered', label: 'Completed', key: '2' },
    { column: 'cancelled', label: 'Cancelled', key: '3' },
  ]

  @observable
  selectedFilterOption = { column: 'all', label: 'All' }

  @observable
  selectedOrderOption = { column: 'id', label: 'Order Date' }

  @observable
  orderOptions = [
    { column: 'code', label: 'Id' },
    { column: 'code_asc', label: 'Id (Ascending)' },
    { column: 'id', label: 'Order Date' },
    { column: 'id_asc', label: 'Order Date (Ascending)' },
    { column: 'extra_detail.customer.name', label: 'Customer' },
    { column: 'extra_detail.customer.name_asc', label: 'Customer (Ascending)' },
    { column: 'extra_detail.customer.bakeryName', label: 'Bakery' },
    {
      column: 'extra_detail.customer.bakeryName_asc',
      label: 'Bakery (Ascending)',
    },
    { column: 'delivery_date', label: 'Delivery Date' },
    { column: 'delivery_date_asc', label: 'Delivery Date (Ascending)' },
    { column: 'total_price', label: 'Price' },
    { column: 'total_price_asc', label: 'Price (Ascending)' },
    { column: 'delivery_status_asc', label: 'Delivery Status' },
    { column: 'delivery_status', label: 'Delivery Status (Ascending)' },
  ]

  @observable
  orderDetail = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  startDate = null

  @observable
  endDate = null

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
    }
    const value = await axiFetch.request(
      'order/list',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = true
    } else {
      this.isLoading = true
    }
  }

  @action
  getOrderDetail = async (id, history) => {
    this.isLoading = false
    const value = await axiFetch.request(
      'order',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.orderDetail = value.data
      this.isLoading = true
    } else {
      this.isLoading = true
    }
  }

  @action
  changeStatus = async (value, id, history) => {
    this.isLoading = false
    const body = { delivery_status: value }
    const data = await axiFetch.request(
      'order',
      false,
      id,
      false,
      'post',
      false,
      body,
      history
    )
    if (data) {
      successNotify('Item has been updated successfully')
      this.orderDetail = data.data
      this.isLoading = true
    } else {
      this.isLoading = true
    }
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.searchOn = [
        'code',
        'extra_detail.customer.bakeryName',
        'extra_detail.customer.name',
      ]
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeDashboardFilter = async (key, value, from, to, history) => {
    if (from && to) {
      this.startDate = from
      this.endDate = to
      this.filter.fromDate = moment(from).format('X')
      this.filter.toDate = moment(to).format('X')
    }
    this.startDate = from
    this.endDate = to
    if (key) {
      this.selectedFilterOption = await this.filterOptions.find(
        (x) => x.key === value
      )
      this.filter.delivery_status = value
      // this.filter = { [key]: value }
    } else {
      delete this.filter.delivery_status
    }
    history.push('/app/order/list')
  }

  @action
  changeFilterBy = async (column, history) => {
    this.selectedFilterOption = await this.filterOptions.find(
      (x) => x.column === column
    )

    this.selectedFilterOption.column === 'all'
      ? delete this.filter.delivery_status
      : (this.filter.delivery_status = this.selectedFilterOption.key)

    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setStartDate = (date, history) => {
    this.startDate = date
    if (date) {
      this.filter.fromDate = moment(this.startDate).format('X')
    } else {
      delete this.filter.fromDate
    }

    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  setEndDate = (date, history) => {
    this.endDate = date
    if (date) {
      this.filter.toDate = moment(this.endDate).format('X')
    } else {
      delete this.filter.toDate
    }

    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderBy = async (column, history) => {
    if (this.selectedOrderOption.column === column) {
      let target = column.includes('_asc')
        ? column.split('_asc')[0]
        : `${column}_asc`
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    } else {
      let target = column
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.filterOptions = [
      { column: 'all', label: 'All' },
      { column: 'preparing', label: 'Preparing', key: '0' },
      { column: 'sent', label: 'In Transit', key: '1' },
      { column: 'delivered', label: 'Completed', key: '2' },
      { column: 'cancelled', label: 'Cancelled', key: '3' },
    ]

    this.selectedFilterOption = { column: 'all', label: 'All' }

    this.selectedOrderOption = { column: 'id', label: 'Order Date' }

    this.orderOptions = [
      { column: 'code', label: 'Id' },
      { column: 'code_asc', label: 'Id (Ascending)' },
      { column: 'id', label: 'Order Date' },
      { column: 'id_asc', label: 'Order Date (Ascending)' },
      { column: 'extra_detail.customer.name', label: 'Customer' },
      {
        column: 'extra_detail.customer.name_asc',
        label: 'Customer (Ascending)',
      },
      { column: 'extra_detail.customer.bakeryName', label: 'Bakery' },
      {
        column: 'extra_detail.customer.bakeryName_asc',
        label: 'Bakery (Ascending)',
      },
      { column: 'delivery_date', label: 'Delivery Date' },
      { column: 'delivery_date_asc', label: 'Delivery Date (Ascending)' },
      { column: 'total_price', label: 'Price' },
      { column: 'total_price_asc', label: 'Price (Ascending)' },
      { column: 'delivery_status_asc', label: 'Delivery Status' },
      { column: 'delivery_status', label: 'Delivery Status (Ascending)' },
    ]

    this.orderDetail = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.startDate = null

    this.endDate = null
  }
}
