import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'

import { NavLink } from 'react-router-dom'
import logo from "../../assets/img/logo.png";
import mobileLogo from "../../assets/img/logo.png";

@inject('stores')
@observer
class TopNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isInFullScreen: false, searchKeyword: '' }
  }

  @observable
  menu = this.props.stores.menu
  @observable
  setting = this.props.stores.setting
  @observable
  login = this.props.stores.login

  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    )
  }
  
 

  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen()

    var docElm = document.documentElement
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen,
    })
  }

  handleLogout = () => {
    this.login.logoutUser(this.props.history)
  }

  menuButtonClick(e, menuClickCount, containerClassnames) {
    e.preventDefault()

    setTimeout(() => {
      var event = document.createEvent('HTMLEvents')
      event.initEvent('resize', false, false)
      window.dispatchEvent(event)
    }, 350)
    this.menu.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.menu.selectedMenuHasSubItems
    )
  }
  mobileMenuButtonClick(e, containerClassnames) {
    e.preventDefault()
    this.menu.clickOnMobileMenu(containerClassnames)
  }
  render() {
    const { containerClassnames, menuClickCount } = this.menu
    return (
      <nav className="navbar fixed-top d-flex flex-row align-items-center" >
        <NavLink
          to="#"
          className="menu-button d-none d-md-block"
          onClick={(e) =>
            this.menuButtonClick(e, menuClickCount, containerClassnames)
          }
        >
          <svg
            className="main"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 17"
          >
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg>
          <svg
            className="sub"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 17"
          >
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </NavLink>
        <NavLink
          to="#"
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={(e) => this.mobileMenuButtonClick(e, containerClassnames)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </NavLink>

      

        <a className="navbar-logo" href="/">
          <div className="logo d-md-block d-xs-none d-none" style={{paddingLeft:'20%', paddingTop:'20%'}}>
            <img
              src={logo}
              alt="cakelogo"
              style={{ width: "80%", borderRadius:"50%" }}
              className="mr-auto ml-auto"
            />
          </div>

          <div className="logo-mobile d-md-none d-xs-block d-none" style={{paddingLeft:'20%', paddingTop:'35%'}}>
            <img
              src={mobileLogo}
              alt="cake logo"
              style={{ width: "60%", borderRadius:"50%" }}
              className="mr-auto ml-auto"
            />
          </div>
</a>
        <div className="ml-auto">
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className=" border" color="empty" >
                <span className="name mr-1">
                  {this.props.userName ? this.props.userName : "Admin"}
                </span>
                <span>
                  <i className="iconsminds-arrow-down" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                <DropdownItem onClick={() => this.handleLogout()}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    )
  }
}

export default injectIntl(TopNav)
