import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class GalleryStore {
  @observable
  data = null

  @observable
  images = [
    {
      src:
        'https://upload.wikimedia.org/wikipedia/commons/8/89/Ropy_pahoehoe.jpg',
      title: 'Ropy pahoehoe',
      description:
        'By Tari Noelani Mattox. Licensed under Public Domain via Commons',
    },
    {
      src:
        'https://upload.wikimedia.org/wikipedia/commons/7/73/Pyroclastic_flows_at_Mayon_Volcano.jpg',
      title: 'Pyroclastic flows at Mayon Volcano',
      description: 'By C.G. Newhall. Licensed under Public Domain via Commons',
    },
    {
      src: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Okataina.jpg',
      title: 'Okataina',
      description: 'By Richard Waitt. Licensed under Public Domain via Commons',
    },
  ]

  @observable
  openImageViewer = false

  @observable
  filterOptions = [{ column: 'all', label: 'All' }]

  @observable
  selectedFilterOption = { column: 'all', label: 'All' }

  @observable
  openDeleteModal = false

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  btnDisable = false

  @observable
  imageFile_validation = null

  @observable
  selectedBakery = []

  @observable
  bakery_validation = null

  @observable
  hasData = false

  @observable
  bakeriesList = []

  @action
  async validation(history, type) {
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })

    this.bakery_validation = await checkInput({
      name: 'Bakery',
      value: this.selectedBakery.label,
      require: true,
    })
    if (type === 'admin') {
      this.imageFile_validation.res &&
        this.bakery_validation.res &&
        this.handleSaveNewImage(history, type)
    } else {
      this.imageFile_validation.res && this.handleSaveNewImage(history, type)
    }
  }

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
    }
    const value = await axiFetch.request(
      'bakery/support/customImage/get',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      !this.hasData && this.getDependencies(history)
      this.isLoading = true
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.bakeries.forEach((item) => {
        this.filterOptions.push({ column: item.id, label: item.name_en })
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      })
    }
    this.hasData = true
  }

  @action
  handleSaveNewImage = async (history, type) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      image: this.imageFile,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    let query =
      type === 'admin' ? `?bakery_id=${this.selectedBakery.value}` : ''
    const value = await axiFetch.request(
      `bakery/support/customImage/add${query}`,
      false,
      false,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.toggleModal()
      this.btnDisable = false
      this.getData()
    } else {
      this.isLoading = true
    }
  }

  @action
  handleDeleteImage = async (id, history) => {
    this.isLoading = false
    const value = await axiFetch.request(
      'bakery/support/customImage',
      false,
      `${id}/delete`,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.toggleDeleteModal()
      this.getData()
    }
  }

  @action
  setBakery(data) {
    this.selectedBakery = data.selectedOption
  }

  @action
  handleOpenImageViewer() {
    this.openImageViewer = !this.openImageViewer
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.imageFile = null
    this.imageFile_validation = null
  }

  @action
  changeOpenDeleteModal() {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal() {
    this.openDeleteModal = false
    this.imageFile = null
    this.selectedBakery = []
    this.bakery_validation = null
    this.imageFile_validation = null
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  changeFilterBy = async (column, history) => {
    this.selectedFilterOption = await this.filterOptions.find(
      (x) => x.column === column
    )

    this.selectedFilterOption.column === 'all'
      ? delete this.filter.target_id
      : (this.filter.target_id = this.selectedFilterOption.column)

    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
    this.data = null

    this.images = [
      {
        src:
          'https://upload.wikimedia.org/wikipedia/commons/8/89/Ropy_pahoehoe.jpg',
        title: 'Ropy pahoehoe',
        description:
          'By Tari Noelani Mattox. Licensed under Public Domain via Commons',
      },
      {
        src:
          'https://upload.wikimedia.org/wikipedia/commons/7/73/Pyroclastic_flows_at_Mayon_Volcano.jpg',
        title: 'Pyroclastic flows at Mayon Volcano',
        description:
          'By C.G. Newhall. Licensed under Public Domain via Commons',
      },
      {
        src: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Okataina.jpg',
        title: 'Okataina',
        description:
          'By Richard Waitt. Licensed under Public Domain via Commons',
      },
    ]

    this.openImageViewer = false

    this.openDeleteModal = false

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.btnDisable = false

    this.imageFile_validation = null

    this.selectedBakery = []

    this.bakery_validation = null

    this.filterOptions = [{ column: 'all', label: 'All' }]

    this.selectedFilterOption = { column: 'all', label: 'All' }

    this.hasData = false

    this.bakeriesList = []
  }
}
