import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Card,
  CardBody,
  CardImg,
  CardText,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classnames from 'classnames'

@inject('stores')
@observer
class OrderList extends Component {
  @observable
  store = this.props.stores.order
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.order" />}
              match={this.props.match}
            />
            <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-block d-md-inline-block">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.orderby" />
                      {this.store.selectedOrderOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.orderOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeOrderByList(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="d-block d-md-inline-block ">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.statusby" />
                      {this.store.selectedFilterOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.filterOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeFilterBy(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search ( Id, Customer, Bakery )`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) => this.store.handleKeyPress(event)}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0 flex-wrap">
                <div className="col-xl-6 col-md-6 col-sm-12 col-12  p-0 pr-lg-2 d-flex">
                  <div className="d-block d-md-inline-block">
                    <DatePicker
                      className="mr-1 float-md-left  mb-1 custom-datePicker"
                      placeholderText="Click to select 'From'"
                      selected={this.store.startDate}
                      isClearable
                      onChange={(date) =>
                        this.store.setStartDate(date, this.props.history)
                      }
                    />
                  </div>

                  <div className="d-block d-md-inline-block ml-1 ">
                    <DatePicker
                      className="mr-1 float-md-left  mb-1 custom-datePicker"
                      placeholderText="Click to select 'To'"
                      selected={this.store.endDate}
                      isClearable
                      onChange={(date) =>
                        this.store.setEndDate(date, this.props.history)
                      }
                    />
                  </div>
                </div>
                <div className="mb-3 col-xl-6 col-md-6 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) =>
                        this.store.handleKeyPress(event, this.props.history)
                      }
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                <div
                  className="col-12 p-2 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <div className="col-1">
                    <p className="col-12 p-2 m-0 text-muted text-small text-center">
                      Image
                    </p>
                  </div>

                  <div className="col-11 d-flex align-items-center">
                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'extra_detail.customer.name'
                          ),
                        }
                      )}
                      onClick={() =>
                        this.store.changeOrderBy('extra_detail.customer.name')
                      }
                    >
                      Customer
                    </p>
                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'extra_detail.customer.bakeryName'
                          ),
                        }
                      )}
                      onClick={() =>
                        this.store.changeOrderBy(
                          'extra_detail.customer.bakeryName'
                        )
                      }
                    >
                      Bakery
                    </p>
                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'code'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('code')}
                    >
                      Id
                    </p>

                    <p
                      className={classnames(
                        'col-1 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'id'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('id')}
                    >
                      Order Date
                    </p>

                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'delivery_date'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('delivery_date')}
                    >
                      {' '}
                      Delivery Date
                    </p>

                    <p
                      className={classnames(
                        'col-1 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'total_price'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('total_price')}
                    >
                      price (KD)
                    </p>

                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'delivery_status'
                          ),
                        }
                      )}
                      onClick={() =>
                        this.store.changeOrderBy('delivery_status')
                      }
                    >
                      {' '}
                      Delivery Status
                    </p>
                  </div>
                </div>
              </Colxx>
              {this.store.data
                ? this.store.data.map((item) => (
                    <Colxx xxs="12" className=" mb-3 pl-0" key={item.id}>
                      <NavLink to={`/app/order/detail/${item.summary.id}`}>
                        <Card className="col-12 pl-0 pr-0 pointer">
                          <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                            <div className="position-relative col-xl-1 col-md-4 col-sm-12">
                              <div
                                className="col-12 position-relative"
                                style={{ paddingTop: '100%' }}
                              >
                                <CardImg
                                  src={item.bakery.image.url}
                                  className=" p-2"
                                  style={{}}
                                ></CardImg>
                              </div>
                            </div>
                            <div className="col-xl-11 col-md-8 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                              <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Customer :{' '}
                                </span>
                                {item.summary.customer.name}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Bakery :{' '}
                                </span>
                                {item.bakery.name}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Id :{' '}
                                </span>
                                {item.summary.code}
                              </CardText>
                              <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Order Date :{' '}
                                </span>
                                {item.summary.date.split(' ')[0]}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Delivery Date :{' '}
                                </span>
                                {item.summary.delivery_date.split(' ')[4]}
                              </CardText>
                              <CardText className="col-xl-1 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Total price (KD) :{' '}
                                </span>
                                {item.summary.total_price}
                              </CardText>
                              <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small  text-xl-center text-md-left">
                                <span className="d-xl-none d-md-inline font-weight-bold">
                                  Delivery status :{' '}
                                </span>
                                <Badge
                                  color={
                                    item.summary.delivery_status === 'delivered'
                                      ? 'success'
                                      : item.summary.delivery_status === 'sent'
                                      ? 'primary'
                                      : item.summary.delivery_status ===
                                        'preparing'
                                      ? 'info'
                                      : 'danger'
                                  }
                                  className="text-middle"
                                >
                                  {item.summary.delivery_status === 'delivered'
                                    ? 'Completed'
                                    : item.summary.delivery_status === 'sent'
                                    ? 'In Transit'
                                    : item.summary.delivery_status ===
                                      'preparing'
                                    ? 'Preparing'
                                    : 'Cancelled'}
                                </Badge>
                              </CardText>
                            </div>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </Colxx>
                  ))
                : null}
            </Row>
            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default OrderList
