import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath } from '../constants/defaultValues'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import { errorNotify } from '../util/Notify'

export default class BakeryStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  bakeryDetail = null

  @observable
  imageFile = null

  @observable
  coverImageFile = null

  @observable
  search = ''

  @observable
  searchOn =['name_en', 'name_ar']

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  newArName = null

  @observable
  newEnName = null

  @observable
  phone = null

  @observable
  email = null

  @observable
  address = null

  @observable
  arAddress = null

  @observable
  btnDisable = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  address_validation = null

  @observable
  ar_address_validation = null

  @observable
  phone_validation = null

  @observable
  email_validation = null

  @observable
  coverImageFile_validation = null

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.phone_validation = await checkInput({
      name: 'Phone Number',
      value: this.phone,
      require: true,
      type: 'number',
      min: 8,
    })
    this.address_validation = await checkInput({
      name: 'Address',
      value: this.address,
      require: true,
      type: 'string',
      min: 10,
    })

    this.ar_address_validation = await checkInput({
      name: 'Arabic Address',
      value: this.arAddress,
      require: true,
      type: 'string',
      min: 10,
    })
    this.email_validation = await checkInput({
      name: 'Email',
      value: this.email,
      require: true,
      type: 'email',
    })

    this.coverImageFile_validation = await checkInput({
      name: 'Cover Image',
      value: this.coverImageFile,
      require: true,
    })

    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })

    this.phone_validation.res &&
      this.address_validation.res &&
      this.ar_address_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.email_validation.res &&
      this.imageFile_validation.res &&
      this.coverImageFile_validation.res &&
      this.handleSaveNewBakery(history)
  }

  @action
  getData = async (history) => {
    this.isLoading = false
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.request(
      'bakery',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = true
    } else {
      this.isLoading = true
    }
  }

  @action
  handleSaveNewBakery = async (history) => {
    this.isLoading = false
    this.btnDisable = true
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      email: this.email,
      phone: this.phone,
      address_en: this.address,
      address_ar: this.arAddress,
    }

    if (this.imageFile) {
      data.image = this.imageFile
    }
    if (this.coverImageFile) {
      data.cover_image = this.coverImageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    await axios
      .post(`${servicePath}/bakery/add`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.openModal = false
        this.btnDisable = false
        history.push(`/app/bakery/detail/${data.data.id}`)
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          errorNotify(error.response.data.meta.msg)
        }
        if (
          error &&
          error.response &&
          error.response.status === 500 &&
          error.response.data.meta.msg
        ) {
          errorNotify(error.response.data.meta.msg)
        }
        if (error && error.response && error.response.status === 403) {
          if (history) {
            history.push('/login')
          }
        }
        this.isLoading = true
        this.btnDisable = false
      })
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.imageFile = null
    this.en_name_validation = null
    this.ar_name_validation = null
    this.address_validation = null
    this.ar_address_validation = null
    this.phone_validation = null
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeCoverImageFile = (file) => {
    this.coverImageFile = file
  }

  @action
  changeEnName = (name) => {
    this.bakeryDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.bakeryDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePhone = (phone) => {
    this.phone = phone
  }

  @action
  changeEmail = (email) => {
    this.email = email
  }

  @action
  changeAddress = (address) => {
    this.address = address
  }

  @action
  changeArAddress = (address) => {
    this.arAddress = address
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null
  
    this.pagination = 0
  
    this.currentPage = 1
  
    this.bakeryDetail = null
  
    this.imageFile = null
  
    this.coverImageFile = null
  
    this.search = ''
  
    this.searchOn =['name_en', 'name_ar']
  
    this.limit = 12
  
    this.offset = 0
  
    this.order = { id: -1 }
  
    this.filter = {}
  
    this.isLoading = false
  
    this.openModal = false
  
    this.newArName = null
  
    this.newEnName = null
  
    this.phone = null
  
    this.email = null
  
    this.address = null
  
    this.arAddress = null
  
    this.btnDisable = false
  
    this.en_name_validation = null
  
    this.ar_name_validation = null
  
    this.imageFile_validation = null
  
    this.address_validation = null
  
    this.ar_address_validation = null
  
    this.phone_validation = null
  
    this.email_validation = null
  
    this.coverImageFile_validation = null
  }
}
