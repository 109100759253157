import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardImg,
  CardText,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import ImageGalleryCard from '../../../components/imageGalleryCard'
import SelectInput from '../../../components/select'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

@inject('stores')
@observer
class CustomDetail extends Component {
  @observable
  store = this.props.stores.productDetail

  @observable
  login = this.props.stores.login
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      imageFile: null,
      discount: false,
      available: false,
      data: null,
      isMulti: false,
    }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getCakeDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleHasDiscount = (value) => {
    this.setState({ discount: value })
  }

  handleIsAvailable = (value) => {
    this.setState({ available: value })
  }

  setData = async (value, target, isMulti) => {
    await this.setState({ data: value, target, isMulti })
    this.store.changeOpenItemModal()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.product" />}
                match={this.props.match}
              />

              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <CardTitle></CardTitle>
                  <Row>
                    <Colxx
                      xxs="12"
                      md="6"
                      lg="4"
                      className="mb-4 d-flex flex-column align-item-center"
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />

                          <>
                            {this.store.images.length ? (
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleDownloadImage()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Download Image</strong>
                                </Label>
                              </Button>
                            ) : (
                              <p className="font-weight-bold">
                                This Product Don't Have Image
                              </p>
                            )}
                            <ImageGalleryCard
                              data={this.store.images}
                              deleteImage={(id) =>
                                this.store.handleDeleteImage(id)
                              }
                              changeOrderImages={(arr) =>
                                this.store.changeOrderImages(arr)
                              }
                            />
                          </>
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2 h-100">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                            </p>
                            <div className="mb-0">
                              <Input
                                disabled={true}
                                name="name"
                                value={
                                  this.store.enName ? this.store.enName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                          </Colxx>

                         

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.price" /> ( KD )
                            </p>
                            <div className="mb-0">
                              <Input
                                disabled={true}
                                name="price"
                                value={this.store.price ? this.store.price : ''}
                                type="text"
                                onChange={(e) =>
                                  this.store.changePrice(e.target.value)
                                }
                              />
                            </div>
                          </Colxx>
                          <Colxx xxs="12" />
                          {this.login.userType === 'admin' && (
                            <Colxx xxs="12" lg="12" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="menu.bakery" />
                              </p>
                              <div
                                className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                                style={{ border: '1px solid #ddd' }}
                              >
                                {this.store.bakery && (
                                  <Card
                                    // key={item.id}
                                    className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  >
                                    <div className=" d-flex align-items-center">
                                      <div
                                        className=" p-0 m-0"
                                        style={{ width: '40px' }}
                                      >
                                        <div
                                          className="position-relative col-12 m-0"
                                          style={{ paddingTop: '100%' }}
                                        >
                                          <CardImg
                                            src={
                                              this.store.bakery.image
                                                ? this.store.bakery.image
                                                : '/assets/img/noimage.png'
                                            }
                                            style={{ borderRadius: '50%' }}
                                          ></CardImg>
                                        </div>
                                      </div>
                                      <CardText
                                        className="text-small pl-2 pr-2 m-0 d-flex "
                                        style={{ flex: 1 }}
                                      >
                                        {this.store.bakery.label}
                                      </CardText>
                                    </div>
                                  </Card>
                                )}
                                {!this.store.bakery ? (
                                  <Button
                                    color="primary"
                                    className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                    onClick={() => {
                                      this.setData(
                                        this.store.bakeriesList,
                                        'bakery',
                                        false
                                      )
                                    }}
                                    style={{ height: 40, width: 70 }}
                                  >
                                    {' '}
                                    <IntlMessages id="pages.add" />
                                    <i className="iconsminds-add" />
                                  </Button>
                                ) : null}
                              </div>
                            </Colxx>
                          )}
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.serving" />
                            </p>

                            <SelectInput
                              disabled={true}
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.serving}
                              onChange={(value) => this.store.setServing(value)}
                              options={this.store.servingList}
                              isMulti={false}
                            />
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.category.map((item) => (
                                <Card
                                  key={item.id}
                                  className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                >
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {item.label}
                                    </CardText>
                                  </div>
                                </Card>
                              ))}
                              {/* {!this.store.category.length ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.categoriesList,
                                      'category',
                                      false
                                    )
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {' '}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null} */}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.filling" />
                            </p>

                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.fillings.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.flavor" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.flavors.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.shape" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.shapes.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.layer" />
                            </p>
                            <SelectInput
                              disabled={true}
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.layer}
                              onChange={(value) => this.store.setLayer(value)}
                              options={this.store.layerList}
                              isMulti={false}
                            />
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.outerShell" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.outerShell && (
                                <Card
                                  // key={item.id}
                                  className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                >
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            this.store.outerShell.image
                                              ? this.store.outerShell.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {this.store.outerShell.label}
                                    </CardText>
                                  </div>
                                </Card>
                              )}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.noteForShope" />
                            </p>
                            <div className="mb-3">
                              <Input
                                name="noteForShop"
                                value={
                                  this.store.enDescription
                                    ? this.store.enDescription
                                    : ''
                                }
                                type="textarea"
                                style={{ resize: 'none' }}
                                onChange={(e) =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.customText" />
                            </p>
                            <div className="mb-3">
                              <Input
                                disabled={true}
                                name="textOnCake"
                                value={
                                  this.store.arDescription
                                    ? this.store.arDescription
                                    : ''
                                }
                                type="textarea"
                                style={{ resize: 'none' }}
                                onChange={(e) =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                            </div>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default CustomDetail
