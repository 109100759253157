import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const setting = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

export const successNotify = (msg) => {
  toast.success(msg, setting);
};

export const errorNotify = (msg) => {
  toast.error(msg, setting);
};
