import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import { Thumb } from '../../../components/Thumb'
import DeleteModal from '../../../components/Modal/deleteModal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Uploader from '../../../components/Uploader'

@inject('stores')
@observer
class OuterShellDetail extends Component {
  @observable
  store = this.props.stores.outerShellDetail

  @observable
  login = this.props.stores.login

  constructor(props) {
    super(props)
    this.state = { id: null, imageFile: null, getImage: false }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getOuterShellDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.store.changeImageFileUploder(data)
    } else {
      this.setState({ getImage: false })
    }
    this.store.validation(this.state.id, this.props.history)
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.outerShell" />}
                match={this.props.match}
              />
              {this.login.userType === 'admin' && (
                <Button
                  color="btn-outline-secondary"
                  className="btn-shadow float-right ml-2"
                  size="lg"
                  onClick={() => this.store.changeOpenDeleteModal()}
                >
                  <IntlMessages id="pages.delete" />
                </Button>
              )}
              <DeleteModal
                target={
                  this.store.outerShellDetail
                    ? this.store.outerShellDetail.name_en
                    : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteOuterShell(this.state.id, this.props.history)
                }
              />
              {this.login.userType === 'admin' && (
                <Button
                  color="primary"
                  className="btn-shadow float-right"
                  size="lg"
                  onClick={() =>
                    this.store.showUploader
                      ? this.store.validation(this.state.id, this.props.history)
                      : this.handleSave()
                  }
                >
                  <IntlMessages id="pages.save" />
                </Button>
              )}
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <Row>
                    <Colxx xxs="12" sm="6" lg="4" className="mb-4">
                      <Card className="mb-12">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              Image
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          {this.store.showUploader ? (
                            <>
                              {' '}
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploader()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Change Image</strong>
                                </Label>
                              </Button>
                              <Thumb
                                key="cat-image"
                                className="almost-full-width"
                                file={this.store.imageFile}
                                src={
                                  this.store.outerShellDetail
                                    ? this.store.outerShellDetail.image.url
                                    : '/assets/img/noimage.png'
                                }
                              />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              getImage={this.state.getImage}
                              flag={true}
                              ratio="1:1"
                              size={{ width: 400, height: 400 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploader()
                              }
                              changeImageFile={(data) => {
                                this.changeImageFile(data)
                              }}
                            />
                          )}
                          {/* <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-3 col-12"
                          >
                            <Label
                              className="btn btn-default no-padding col-12"
                              style={{
                                padding: '0px',
                                marginBottom: '0px',
                              }}
                            >
                              <strong>Change Image</strong>
                              <input
                                className="col-12 p-0 "
                                id="image"
                                name="image"
                                type="file"
                                accept="image/x-png,image/jpeg"
                                onChange={(e) =>
                                  this.store.changeImageFile(
                                    e.currentTarget.files[0],
                                    this.state.id
                                  )
                                }
                                hidden
                              />
                            </Label>
                          </Button>
                          <Thumb
                            key="cat-image"
                            className="almost-full-width"
                            file={this.store.imageFile}
                            src={
                              this.store.outerShellDetail
                                ? this.store.outerShellDetail.image.url
                                : '/assets/img/noimage.png'
                            }
                          /> */}
                          <span className="req-validation ">
                            {this.store.imageFile_validation
                              ? this.store.imageFile_validation.msg
                                ? this.store.imageFile_validation.msg
                                : null
                              : null}
                          </span>
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" sm="6" lg="4" className="mb-8">
                      <Card className="mb-12">
                        <CardBody>
                          <CardTitle className="mb-3">
                            <span className="font-weight-bold text-uppercase">
                              Detail
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.outerShellDetail
                                    ? this.store.outerShellDetail.name_en
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mt-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.outerShellDetail
                                    ? this.store.outerShellDetail.name_ar
                                    : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default OuterShellDetail
