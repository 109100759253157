import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { ToastContainer } from "react-toastify";
import { Colxx } from "../../components/CustomBootstrap/index";
import IntlMessages from "../../util/IntlMessages";
import Loading from "../../components/Loading/index";

@inject("stores")
@observer
class ResetLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { code: null };
  }
  @observable
  store = this.props.stores.login;

  async componentDidMount() {
    let path = this.props.location.pathname.split("/")[2];
    console.log(path);
    this.store.checkCode(path, this.props.history);
    this.setState({ code: path });
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }

  // getUrlParameter(name) {
  //   name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  //   var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  //   var results = regex.exec(window.location.search);
  //   return results === null
  //     ? ""
  //     : decodeURIComponent(results[1].replace(/\+/g, " "));
  // }

  

  render() {
    return (
      <Fragment>
        <ToastContainer  />

        <div className="fixed-background" />
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : this.store.expire ? (
          <Row className="h-100">
            <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="form-side">
                  <NavLink to={`/`} className="white">
                    <span className="logo-single" />
                  </NavLink>{" "}
                  <p className="text-center req-validation">
                    {this.store.expire}
                  </p>
                </div>
              </Card>
            </Colxx>
          </Row>
        ) : (
          <main>
            <div className="container">
              <Row className="h-100">
                <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
                  <Card className="auth-card">
                    <div className="form-side">
                      <NavLink to={`/`} className="white">
                        <span className="logo-single" />
                      </NavLink>
                      <CardTitle className="mb-4">
                        <IntlMessages id="pages.changePassword" />
                      </CardTitle>

                      <Form>
                        <Label className="form-group has-float-label mb-4">
                          <Input
                            type="password"
                            value={this.store.newPassword}
                            onChange={e =>
                              this.store.changeNewPassword(e.target.value)
                            }
                          />
                          <IntlMessages id="pages.newPassword" />
                        </Label>
                        <p className="req-validation text-small mb-3">
                          {this.store.new_password_validation
                            ? this.store.new_password_validation.msg
                              ? this.store.new_password_validation.msg
                              : null
                            : null}
                        </p>
                        <Label className="form-group has-float-label mb-4">
                          <Input
                            type="password"
                            value={this.store.confirmPassword}
                            onChange={e =>
                              this.store.changeConfirmPassword(e.target.value)
                            }
                          />
                          <IntlMessages id="pages.confirmPassword" />
                        </Label>
                        <p className="req-validation text-small mb-3">
                          {this.store.confirm_password_validation
                            ? this.store.confirm_password_validation.msg
                              ? this.store.confirm_password_validation.msg
                              : null
                            : null}
                        </p>
                        <div className="d-flex justify-content-end align-items-center">
                          <Button
                            style={{ width: 128.33, height: 42.53 }}
                            color="primary"
                            className="btn-shadow"
                            size="lg"
                            disabled={this.store.btnDisable}
                            onClick={() =>
                              this.store.validationPassword(
                                this.props.history,
                                this.state.code
                              )
                            }
                          >
                            {!this.store.loginLoading ? (
                              <IntlMessages id="pages.save" />
                            ) : (
                              <Loading/>
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Card>
                </Colxx>
              </Row>
            </div>
          </main>
        )}
      </Fragment>
    );
  }
}

export default ResetLayout;
