import { observable, action } from "mobx";
import { checkInput } from "../constants/validation";
import axiFetch from "../config/fetch";
import _ from "lodash";

export default class LayerStore {
  @observable
  data = null;

  @observable
  pagination = 0;

  @observable
  currentPage = 1;

  @observable
  imageFile = null;

  @observable
  search = "";

  @observable
  searchOn = null;

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  openModal = false;

  @observable
  newArName = null;

  @observable
  newEnName = null;

  @observable
  btnDisable = false;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @observable
  imageFile_validation = null;

  @observable
  noResult = false;

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.newEnName,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.newArName,
      require: true,
      type: "string",
      min: 3,
    });
    this.imageFile_validation = await checkInput({
      name: "Image",
      value: this.imageFile,
      require: true,
    });
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation &&
      this.handleSaveNewLayer(history);
  }

  @action
  getData = async (history) => {
    this.isLoading = false;
    this.noResult = false;
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    };

    const value = await axiFetch.request(
      "layer/list",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.data = value.data.items;
      this.isLoading = true;
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0);

      if (_.isEmpty(value.data.items)) {
        this.noResult = true;
      }
    }
  };

  @action
  handleSaveNewLayer = async (history) => {
    this.isLoading = false;
    this.btnDisable = true;
    let data = {
      name_en: this.newEnName,
      name_ar: this.newArName,
      image: this.imageFile,
    };
    let formData = new FormData();
    for (let key in data) formData.append(key, data[key]);

    const value = await axiFetch.request(
      "layer/add",
      false,
      false,
      false,
      "post",
      false,
      formData,
      history
    );
    if (value) {
      this.openModal = false;
      this.btnDisable = false;
      history.push(`/app/layer/detail/${value.data.id}`);
    } else {
      this.isLoading = true;
      this.btnDisable = false;
    }
  };

  @action
  changeOpenModal = () => {
    this.openModal = true;
  };

  @action
  toggleModal = () => {
    this.openModal = false;
    this.newArName = null;
    this.newEnName = null;
    this.imageFile = null;
    this.en_name_validation = null;
    this.ar_name_validation = null;
    this.imageFile_validation = null;
  };

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  changeImageFile = async (file) => {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name;
  };

  @action
  changeNewArName = (name) => {
    this.newArName = name;
  };

  @action
  changePage(e) {
    this.offset = (e - 1) * 12;
    this.currentPage = e;
    this.getData();
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value;
  };

  @action
  handleKeyPress = (e, history) => {
    if (e.key === "Enter") {
      this.searchOn = ["name_en"];
      this.offset = 0;
      this.currentPage = 1;
      this.getData(history);
    }
  };

  @action
  reset() {
    this.data = null;

    this.pagination = 0;

    this.currentPage = 1;

    this.imageFile = null;

    this.search = null;

    this.searchOn = null;

    this.limit = 12;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.openModal = false;

    this.newArName = null;

    this.newEnName = null;
  }
}
