import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import IntlMessages from '../../util/IntlMessages'
import { Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

@inject('stores')
@observer
class Sidebar extends Component {
  @observable
  store = this.props.stores.menu

  @observable
  login = this.props.stores.login

  constructor(props) {
    super(props)
    this.state = {
      selectedParentMenu: '',
      viewingParentMenu: '',
      userInfo: null,
      subMenu: true,
    }
  }
  handleWindowResize = (event) => {
    if (event && !event.isTrusted) {
      return
    }
    const { containerClassnames } = this.store
    let nextClasses = this.getMenuClassesForResize(containerClassnames)
    this.store.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.store.selectedMenuHasSubItems
    )
  }

  handleDocumentClick = (e) => {
    const container = this.getContainer()
    let isMenuClick = false
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') ||
        e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.parentElement.classList.contains(
          'menu-button-mobile'
        ))
    ) {
      isMenuClick = true
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return
    }
    this.toggle(e)
    this.setState({
      viewingParentMenu: '',
    })
  }

  getMenuClassesForResize = (classes) => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.store
    let nextClasses = classes.split(' ').filter((x) => x !== '')
    const windowWidth = window.innerWidth
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile')
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
      if (
        nextClasses.includes('menu-default') &&
        !nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses.push('menu-sub-hidden')
      }
    } else {
      nextClasses = nextClasses.filter((x) => x !== 'menu-mobile')
      if (
        nextClasses.includes('menu-default') &&
        nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = nextClasses.filter((x) => x !== 'menu-sub-hidden')
      }
    }
    return nextClasses
  }

  getContainer = () => {
    return ReactDOM.findDOMNode(this)
  }

  toggle = () => {
    const { containerClassnames, menuClickCount } = this.store
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter((x) => x !== '')
      : ''

    if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      this.store.setContainerClassnames(
        2,
        containerClassnames,
        this.store.selectedMenuHasSubItems
      )
      this.setState({ subMenu: !this.state.subMenu })
    } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
    ) {
      this.store.setContainerClassnames(
        0,
        containerClassnames,
        this.store.selectedMenuHasSubItems
      )
      this.setState({ subMenu: !this.state.subMenu })
    }
  }

  handleProps = () => {
    this.addEvents()
  }

  addEvents = () => {
    ;['click', 'touchstart'].forEach((event) =>
      document.addEventListener(event, this.handleDocumentClick, true)
    )
  }
  removeEvents = () => {
    ;['click', 'touchstart'].forEach((event) =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    )
  }
  setSelectedLiActive = () => {
    const oldli = document.querySelector('.sub-menu  li.active')
    if (oldli != null) {
      oldli.classList.remove('active')
    }

    /* set selected parent menu */
    const selectedlink = document.querySelector('.sub-menu  a.active')
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active')
      this.setState({
        selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
          'data-parent'
        ),
      })
    } else {
      var selectedParentNoSubItem = document.querySelector(
        '.main-menu  li a.active'
      )
      if (selectedParentNoSubItem !== null) {
        this.setState({
          selectedParentMenu: selectedParentNoSubItem.getAttribute('data-flag'),
        })
      } else if (this.state.selectedParentMenu === '') {
        this.setState({
          selectedParentMenu: 'dashboards',
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive()
      this.toggle()
      window.scrollTo(0, 0)
    }
    this.handleProps()
  }

  async componentDidMount() {
    const userInfo = await window.localStorage.getItem('userInfo')
    this.setState({ userInfo: userInfo })
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
    this.handleProps()
    this.setSelectedLiActive()
  }

  componentWillUnmount() {
    this.removeEvents()
    window.removeEventListener('resize', this.handleWindowResize)
  }

  changeDefaultMenuType = (e, containerClassnames) => {
    let nextClasses = this.getMenuClassesForResize(containerClassnames)
    this.store.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.store.selectedMenuHasSubItems
    )
  }

  openSubMenu(e, selectedParent) {
    e.preventDefault()
    this.changeDefaultMenuType(e, 'menu-sub-hidden')
    this.store.changeSelectedMenuHasSubItems(true)
    this.setState({ subMenu: !this.state.subMenu })

    const { containerClassnames, menuClickCount } = this.store
    const currentClasses = containerClassnames
      ? containerClassnames.split(' ').filter((x) => x !== '')
      : ''

    if (!currentClasses.includes('menu-mobile')) {
      if (
        currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 2 || menuClickCount === 0) &&
        this.state.subMenu === true
      ) {
        this.store.setContainerClassnames(3, containerClassnames, true)
      } else if (
        currentClasses.includes('menu-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3) &&
        this.state.subMenu === true
      ) {
        this.store.setContainerClassnames(2, containerClassnames, true)
      } else if (
        currentClasses.includes('menu-default') &&
        !currentClasses.includes('menu-sub-hidden') &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.store.setContainerClassnames(0, containerClassnames, true)
      }
    } else {
      this.store.addContainerClassname(
        'sub-show-temporary',
        containerClassnames
      )
    }
    this.setState({
      viewingParentMenu: selectedParent,
    })
  }

  changeSelectedParentHasNoSubmenu(e, menu) {
    this.setState({
      viewingParentMenu: menu,
    })
    this.store.changeSelectedMenuHasSubItems(false)
    this.toggle()
  }
  render() {
    return (
      <div className="sidebar">
        <div className="main-menu">
          <div className="scroll">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav vertical className="list-unstyled">
                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'dashboards' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'dashboards',
                  })}
                >
                  <NavLink
                    to="/app/dashboards/default"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'dashboards')
                    }
                  >
                    <i className=" iconsminds-home-4" />{' '}
                    <IntlMessages id="menu.dashboards" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'product' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'product',
                  })}
                >
                  <NavLink
                    to="/app/product"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'product')
                    }
                  >
                    <i className="iconsminds-cake" />{' '}
                    <IntlMessages id="menu.product" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'dependencies' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'dependencies',
                  })}
                >
                  <NavLink
                    to="/app/dependencies"
                    onClick={(e) => this.openSubMenu(e, 'dependencies')}
                  >
                    <i className="iconsminds-three-arrow-fork" />{' '}
                    <IntlMessages id="pages.dependencies" />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'order' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'order',
                  })}
                >
                  <NavLink
                    to="/app/order"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'order')
                    }
                  >
                    <i className="iconsminds-shopping-cart" />{' '}
                    <IntlMessages id="menu.order" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'category' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'category',
                  })}
                >
                  <NavLink
                    to="/app/category"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'category')
                    }
                  >
                    <i className="iconsminds-shop-4" />{' '}
                    <IntlMessages id="menu.category" />
                  </NavLink>
                </NavItem>

                {this.login.userType === 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'collection' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'collection',
                    })}
                  >
                    <NavLink
                      to="/app/collection"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'collection')
                      }
                    >
                      <i className="iconsminds-tag-3" />{' '}
                      <IntlMessages id="menu.collection" />
                    </NavLink>
                  </NavItem>
                )}

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'coupon' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'coupon',
                  })}
                >
                  <NavLink
                    to="/app/coupon"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'coupon')
                    }
                  >
                    <i className="iconsminds-qr-code" />{' '}
                    <IntlMessages id="menu.coupon" />
                  </NavLink>
                </NavItem>

                {this.login.userType === 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'bakery' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'bakery',
                    })}
                  >
                    <NavLink
                      to="/app/bakery"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'bakery')
                      }
                    >
                      <i className="iconsminds-shop-4" />{' '}
                      <IntlMessages id="menu.bakery" />
                    </NavLink>
                  </NavItem>
                )}

{this.login.userType !== 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'bakeryInfo' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'bakeryInfo',
                    })}
                  >
                    <NavLink
                      to="/app/bakeryInfo"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'bakeryInfo')
                      }
                    >
                      <i className="iconsminds-shop-4" />{' '}
                      <IntlMessages id="menu.bakery" />
                    </NavLink>
                  </NavItem>
                )}


                {this.login.userType === 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'featurette' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'featurette',
                    })}
                  >
                    <NavLink
                      to="/app/featurette"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'featurette')
                      }
                    >
                      <i className="iconsminds-magic-wand" />{' '}
                      <IntlMessages id="menu.featurette" />
                    </NavLink>
                  </NavItem>
                )}

                {this.login.userType === 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'appHomePage' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'appHomePage',
                    })}
                  >
                    <NavLink
                      to="/app/appHome"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'appHomePage')
                      }
                    >
                      <i className="iconsminds-smartphone-4" />{' '}
                      <IntlMessages id="menu.appHomepage" />
                    </NavLink>
                  </NavItem>
                )}

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'users' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'users',
                  })}
                >
                  <NavLink
                    to="/app/users"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'users')
                    }
                  >
                    <i className=" iconsminds-male" />{' '}
                    <IntlMessages id="menu.users" />
                  </NavLink>
                </NavItem>

                {this.login.userType === 'admin' && (
                  <NavItem
                    className={classnames({
                      active:
                        (this.state.selectedParentMenu === 'customers' &&
                          this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === 'customers',
                    })}
                  >
                    <NavLink
                      to="/app/customers"
                      onClick={(e) =>
                        this.changeSelectedParentHasNoSubmenu(e, 'customers')
                      }
                    >
                      <i className="iconsminds-handshake" />{' '}
                      <IntlMessages id="menu.customers" />
                    </NavLink>
                  </NavItem>
                )}

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'profile' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'profile',
                  })}
                >
                  <NavLink
                    to="/app/profile/list"
                    onClick={(e) =>
                      this.changeSelectedParentHasNoSubmenu(e, 'profile')
                    }
                  >
                    <i className="iconsminds-profile" />{' '}
                    <IntlMessages id="menu.profile" />
                  </NavLink>
                </NavItem>
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>
        <div className="sub-menu">
          <div className="scroll">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <Nav
                className={classnames({
                  'd-block':
                    (this.state.selectedParentMenu === 'dependencies' &&
                      this.state.viewingParentMenu === '') ||
                    this.state.viewingParentMenu === 'dependencies',
                })}
                data-parent="dependencies"
              >
                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'gallery' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'gallery',
                  })}
                >
                  <NavLink to="/app/gallery">
                    <i className="iconsminds-film-video" />{' '}
                    <IntlMessages id="menu.gallery" />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'filling' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'filling',
                  })}
                >
                  <NavLink to="/app/filling">
                    <i className="iconsminds-chopsticks" />{' '}
                    <IntlMessages id="menu.filling" />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'flavor' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'flavor',
                  })}
                >
                  <NavLink to="/app/flavor">
                    <i className="iconsminds-cocktail" />{' '}
                    <IntlMessages id="menu.flavor" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'shape' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'shape',
                  })}
                >
                  <NavLink to="/app/shape">
                    <i className="iconsminds-speach-bubble-comic" />{' '}
                    <IntlMessages id="menu.shape" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'layer' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'layer',
                  })}
                >
                  <NavLink to="/app/layer">
                    <i className="iconsminds-layer-forward" />{' '}
                    <IntlMessages id="menu.layer" />
                  </NavLink>
                </NavItem>

                <NavItem
                  className={classnames({
                    active:
                      (this.state.selectedParentMenu === 'outerShell' &&
                        this.state.viewingParentMenu === '') ||
                      this.state.viewingParentMenu === 'outerShell',
                  })}
                >
                  <NavLink to="/app/outerShell">
                    <i className="iconsminds-arrow-around" />{' '}
                    <IntlMessages id="menu.outerShell" />
                  </NavLink>
                </NavItem>
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar
