import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "reactstrap";
import SelectInput from "../select";
import IntlMessages from "../../util/IntlMessages";
import { Colxx } from "../CustomBootstrap";


class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}
  render() {
    const {
      openModal,
      toggleModal,
      name,
      email,
      typesList,
      bakeriesList,
      selectedType,
      selectedBakery,
      setType,
      setBakery,
      changeName,
      changeEmail,
      handleSave,
      btnDisable,
      name_validation,
      type_validation,
      bakery_validation,
      email_validation,
      userType
    } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="mb-3 p-0">
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.personName" />
                  <span className="req"> *</span>
                </p>
                <div className="mb-0">
                  <Input
                    name="name"
                    value={name ? name : ""}
                    type="text"
                    onChange={(e) => changeName(e.target.value)}
                  />
                </div>
                <span className="req-validation ">
                  {" "}
                  {name_validation
                    ? name_validation.msg
                      ? name_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
          
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.email" />
                  <span className="req"> *</span>
                </p>
                <div className="mb-0">
                  <Input
                    name="email"
                    value={email ? email : ""}
                    type="email"
                    onChange={(e) => changeEmail(e.target.value)}
                  />
                </div>
                <span className="req-validation">
                  {" "}
                  {email_validation
                    ? email_validation.msg
                      ? email_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
          </Colxx>

         {userType==="admin" && <> <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.type" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <SelectInput
                  className="react-select"
                  classNamePrefix="react-select"
                  value={selectedType}
                  onChange={(value) => setType(value)}
                  options={typesList}
                  isMulti={false}
                ></SelectInput>
              </div>
              <span className="req-validation">
                  {type_validation
                    ? type_validation.msg
                      ? type_validation.msg
                      : null
                    : null}
                </span>
            </Colxx>

            {selectedType && selectedType.label!=='admin'&&
             <Colxx xxs="12" className="p-0 mb-3">
             <p className="text-muted text-small mb-2">
               <IntlMessages id="pages.bakery" />
               <span className="req"> *</span>
             </p>
             <div className="mb-0">
               <SelectInput
                 className="react-select"
                 classNamePrefix="react-select"
                 value={selectedBakery}
                 onChange={(value) => setBakery(value)}
                 options={bakeriesList}
                 isMulti={false}
               ></SelectInput>
             </div>
             <span className="req-validation">
                 {bakery_validation
                   ? bakery_validation.msg
                     ? bakery_validation.msg
                     : null
                   : null}
               </span>
           </Colxx>}</>}
            
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{" "}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UserModal;
