import { observable, action } from "mobx";
import axiFetch from "../config/fetch";
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'



export default class FeaturetteDetailStore {
  @observable
  featuretteDetail = null;

  @observable
  imageFile = null;

  @observable
  arabicImageFile = null;

  @observable
  search = null;

  @observable
  searchOn = null;

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = true;

  @observable
  openDeleteModal = false;

  @observable
  keys = [];

  @observable
  selectedType = [];

  @observable
  itemList = [];

  @observable
  selectedItem = []

  @observable
  categoryList = [];

  @observable
  bakeryList = [];

  @observable
  url = null;

  @observable
  isActive = true;

  @observable
  productsList = []

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  item_validation = null

  @observable
  url_validation = null

  @observable
  type_validation = null

  @observable
  showUploaderEnglish = true

  @observable
  showUploaderArabic = true

  @action
  handleImageUploaderEnglish = () => {
    this.showUploaderEnglish = !this.showUploaderEnglish
  }

  @action
  handleImageUploaderArabic = () => {
    this.showUploaderArabic = !this.showUploaderArabic
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderEnglish(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderEnglish = !this.showUploaderEnglish
  }


  @action
  async changeImageFileUploderArabic(file) {
    this.arabicImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderArabic = !this.showUploaderArabic
  }



  @action
  async validation(id,history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.featuretteDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.featuretteDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    if (this.selectedType.label === 'url') {
      this.url_validation = await checkInput({
        name: 'Url',
        value: this.url,
        require: true,
        type: 'string',
        min: 3,
      })
    }

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType.label,
      require: true,
    })

    this.item_validation = await checkInput({
      name: 'Item',
      value: this.selectedItem ? this.selectedItem.label : null,
      require: true,
    })
    if (this.selectedType.label === 'url') {
        this.url_validation.res &&
        this.type_validation.res &&
        this.en_name_validation.res &&
        this.ar_name_validation.res &&
        this.handleUpdateFeaturette(id, history)
    } else {
        this.item_validation.res &&
        this.type_validation.res &&
        this.en_name_validation.res &&
        this.ar_name_validation.res &&
        this.handleUpdateFeaturette(id,history)
    }
  }

  @action
  async getFeaturetteDetail(id, history) {
    this.isLoading = true;
    const value = await axiFetch.request(
      "featurette",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.featuretteDetail = value.data.items;
      this.isActive = value.data.items.is_active;
      !this.keys.length && await this.getKeys(history)
      !this.productsList.length && await this.getProduct(history)
      !this.bakeryList.length &&
      !this.categoryList.length &&
       await this.getDependencies(history)
      this.isLoading = false;
    }
  }

  @action
  async getKeys(history) {
    const value = await axiFetch.request(
      "featurette",
      false,
      false,
      false,
      "post",
      "keys",
      null,
      history
    );
    if (value) {
      value.data.forEach((key) => {
        if (this.featuretteDetail.target === key.key) {
          this.selectedType = { value: key.key, label: key.title };
        }
        this.keys.push({ value: key.key, label: key.title });
      });
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      "cake",
      false,
      false,
      false,
      "post",
      "getDependencies",
      null,
      history
    );
    if (value) {
      await value.data.categories.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.title,
        })
      );

      await value.data.bakeries.map((item) =>
        this.bakeryList.push({ value: item.id, label: item.name_en })
      );
      switch (this.selectedType.label) {
        case "category":
          this.itemList = [...this.categoryList];
          const category = this.categoryList.filter(
            (item) => item.value === this.featuretteDetail.value
          );
          this.selectedItem = category[0];
          break;
        case "bakery":
          this.itemList = [...this.bakeryList];
          const bakery = this.bakeryList.filter(
            (item) => item.value === this.featuretteDetail.value
          );
          this.selectedItem = bakery[0];
          break;

        case "url":
          this.url = this.featuretteDetail.value;
          break;
        default:
          break;
      }
    }
  }


  @action
  getProduct = async (history) => {
    const body = {
      order: { id: -1 },
      searchOn: null,
      search: '',
      limit: 500,
      offset: 0,
      filter: {},
    }
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getcakes',
      body,
      history
    )
    if (value) {
     await value.data.items.forEach((item) => {
        this.productsList.push({ value: item.id, label: item.name })
      })
      if(this.selectedType.label==='cake'){
        this.itemList = [...this.productsList];
        const cake = this.productsList.filter(
          (item) => item.value === this.featuretteDetail.value
        );
        this.selectedItem = cake[0];
      }
      // this.pagination =
      //   parseInt(value.data.pagination.total / this.limit) +
      //   (value.data.pagination.total % this.limit ? 1 : 0)
    }
  }
  @action
  setType(data) {
    this.selectedType = data.selectedOption
    this.selectedItem = []
    this.item_validation= null
    switch (this.selectedType.label) {
      case 'category':
        this.itemList = [...this.categoryList]
        break
      case 'bakery':
        this.itemList = [...this.bakeryList]
        break
      case 'cake':
        this.itemList = [...this.productsList]
        break
      case 'url':
        this.url = ''
        this.url_validation = null
        break
      default:
        break
    }
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }

  @action
  deleteFeaturette = async (id, history) => {
    this.isLoading = true;
    await axiFetch.request(
      "featurette",
      false,
      id,
      false,
      "get",
      "delete",
      null,
      history
    );
    this.openDeleteModal = false;
    successNotify('Item has been deleted successfully')
    setTimeout(() => {
      history.push("/app/featurette");    }, 1000)
 
  };

  @action
  async handleUpdateFeaturette(id, history) {
    this.isLoading = true;
    let data = {
      name_en: this.featuretteDetail.name_en,
      name_ar: this.featuretteDetail.name_ar,
      is_active: this.isActive ,
      target: this.selectedType.value,
      value:
        this.selectedType.label === "category" ||this.selectedType.label === "cake"||
        this.selectedType.label === "bakery"
          ? parseInt(this.selectedItem.value)
          : this.selectedType.label === "url"
          ? this.url
          : null,
    };
    if (this.imageFile) {
      data.image = this.imageFile;
    }

    if (this.arabicImageFile) {
      data.image_ar = this.arabicImageFile;
    }
    let formData = new FormData();
    for (let key in data) formData.append(key, data[key]);
    const value = await axiFetch.request(
      "featurette",
      false,
      id,
      false,
      "post",
      false,
      formData,
      history
    );
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset();
      this.getFeaturetteDetail(id, history);
    }
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

  @action
  changeImageFile = (file) => {
    this.imageFile = file;
  };

  @action
  changeArabicImageFile = (file) => {
    this.arabicImageFile = file;
  };

  // @action.bound
  // changeEnName = name => {
  //   console.log(this.fillingDetail.name_en);
  //   console.log(name);
  //   this.fillingDetail.name_en = name;
  //   console.log(this.fillingDetail.name_en);
  // };

  @action
  changeName = (name) => {
    this.featuretteDetail.name_en = name;
  };

  @action
  changeArabicName = (name) => {
    this.featuretteDetail.name_ar = name;
  };

  @action
  changeUrl = (value) => {
    this.url = value;
  };

  @action.bound
  changeIsActive = (target, value) => {
    this.isActive = value;
  };

  @action
  reset() {
  this.featuretteDetail = null;

  this.imageFile = null;

  this.arabicImageFile = null;

  this.search = null;

  this.searchOn = null;

  this.limit = 12;

  this.offset = 0;

  this.order = { id: -1 };

  this.filter = {};

  this.isLoading = true;

  this.openDeleteModal = false;

  this.keys = [];

  this.selectedType = [];

  this.itemList = [];

  this.selectedItem=[];

  this.categoryList = [];

  this.bakeryList = [];

  this.url = null;

  this.isActive = true;

  this.productsList = []

  this.en_name_validation = null

  this.ar_name_validation = null

  this.imageFile_validation = null

  this.ar_imageFile_validation = null

  this.item_validation = null

  this.url_validation = null

  this.type_validation = null

  this.showUploaderEnglish = true

  this.showUploaderArabic = true
  }
}
