import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Card,
  CardBody,
  CardText,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import { FormikSwitch } from "../../../components/FormValidations/FormikFields";
import SelectInput from "../../../components/select";

@inject("stores")
@observer
class AreaList extends Component {
  @observable
  store = this.props.stores.bakeryDetail;

  @observable
  setting = this.props.stores.setting;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.store.getData(this.props.history);
  }
  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row className="col-12">
            <Colxx xxs="4" className="mt-3 mb-5">
              <p>Select Area From List</p>
              <SelectInput
                className="react-select"
                classNamePrefix="react-select"
                value={
                  this.store.selectedBakeryArea
                    ? this.store.selectedBakeryArea
                    : null
                }
                onChange={(value) => this.store.setBakery(value)}
                options={this.store.bakeryAreaList}
                isMulti={false}
              />
            </Colxx>
            <Colxx xxs="12">
              <Separator className="mb-5" />
              <p>Supported Areas</p>
              {this.store.bakeryArea
                ? this.store.bakeryArea.map((area, index) => (
                    <>
                      {area.is_supported && (
                        <Card
                          key={index}
                          className="mb-3 col-12 "
                          style={{
                            backgroundColor: area.is_supported
                              ? "gray"
                              : "unset",
                          }}
                        >
                          <CardBody className="d-flex align-items-center flex-wrap col-12">
                            <CardText className="col-xl-2 col-md-12 m-xl-0 mb-md-2 truncate">
                              {area.name_en}
                            </CardText>
                            <FormGroup className="col-xl-2 col-md-12 d-flex align-items-center m-xl-0 mb-md-2 ">
                              <Label className="m-0 text-left d-xl-none d-md-flex align-items-center col-xl-2 col-md-4 ">
                                Supported:{" "}
                              </Label>
                              <div className="d-flex align-items-center col-xl-10 col-md-8">
                                <FormikSwitch
                                  className="custom-switch custom-switch-primary-inverse mr-2"
                                  name={area.name_en}
                                  value={area.is_supported}
                                  onChange={() =>
                                    this.store.handleIsSupported(area.id)
                                  }
                                  // onBlur={handleBlur}
                                />
                              </div>
                            </FormGroup>
                            {area.is_supported ? (
                              <>
                                <FormGroup
                                  className="col-xl-4 col-md-12 d-flex align-items-center m-xl-0 mb-md-2"
                                  style={{ height: 35 }}
                                >
                                  <Label className="m-0 text-left d-flex align-items-center col-xl-3 col-md-4 ">
                                    Price(KD):{" "}
                                  </Label>
                                  <div className="d-flex align-items-center  col-xl-9 col-md-8">
                                    <Input
                                      style={{ height: 35 }}
                                      className="col-12"
                                      type="number"
                                      value={area.price}
                                      min="0"
                                      onChange={(e) =>
                                        this.store.handleChangePrice(
                                          area.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </FormGroup>

                                <FormGroup
                                  className="col-xl-4 col-md-12 d-flex align-items-center m-xl-0 mb-md-2"
                                  style={{ height: 35 }}
                                >
                                  <Label className="m-0 text-left d-flex align-items-center col-xl-3 col-md-4  ">
                                    Delivery(m):{" "}
                                  </Label>
                                  <div className="d-flex align-items-center col-xl-9 col-md-8">
                                    <Input
                                      style={{ height: 35 }}
                                      className="col-12"
                                      type="number"
                                      min="0"
                                      value={area.delivery_time}
                                      onChange={(e) =>
                                        this.store.handleChangeTime(
                                          area.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              </>
                            ) : null}{" "}
                          </CardBody>
                        </Card>
                      )}
                    </>
                  ))
                : null}
            </Colxx>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default AreaList;
