import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import _ from 'lodash'
import { successNotify } from "../util/Notify";

export default class CustomCakeStore {
  @observable
  supportList = null

  @observable
  supportListBackup = null

  @observable
  supportListArray = []

  @observable
  target = null

  @observable
  openCustomCakeModal = false

  @observable
  isLoading = false

  @action
  getBakerySupport(id, history) {
    this.isLoading = false
    axios
      .get(`${servicePath}/bakery/support/get?bakery_id=${id}`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.supportList = data.data.items
        this.supportListBackup = data.data.items
        _.forEach(this.supportList, (key, value) => {
          this.supportListArray.push({ name: value, arr: key })
        })
        this.isLoading = true
      })
      .catch((err) => {
        console.log({ err })
        this.isLoading = true
        checkUnauthorized(err, history)
      })
  }

  // @action
  // async validation(history) {
  //   this.en_name_validation = await checkInput({
  //     name: "English Name",
  //     value: this.newEnName,
  //     require: true,
  //     type: "string",
  //     min: 3
  //   });
  //   this.ar_name_validation = await checkInput({
  //     name: "Arabic Name",
  //     value: this.newArName,
  //     require: true,
  //     type: "string",
  //     min: 3
  //   });
  //   this.imageFile_validation = await checkInput({
  //     name: "Image",
  //     value: this.imageFile,
  //     require: true
  //   });
  //   this.imageFile_validation.res &&
  //     this.ar_name_validation.res &&
  //     this.en_name_validation &&
  //     this.handleSaveNewCategory(history);
  // }

  @action
  changeCustomCakeModal(name) {
    this.target = name
    this.openCustomCakeModal = true
  }

  @action
  toggleCustomCakeModal() {
    this.openCustomCakeModal = false
  }

  @action
  handleCustomItem(data) {
    this.supportList[this.target] = data
  }

  @action
  cancelCustomItem() {
    this.supportListBackup = this.supportList
  }

  @action
  handleChangePrice(target, name, value) {
    let selected = this.supportList[target]
    selected.forEach((item, index) => {
      if (item.name === name) {
        selected[index].price = value
      }
    })
    this.supportList[target] = selected
  }

  @action
  handleChangeIsSupport(target, name, value) {
    let selected = this.supportList[target]
    selected.forEach((item, index) => {
      if (item.name === name) {
        selected[index].is_support = value
      }
    })
    this.supportList[target] = selected
  }

  @action
  handleSave(id, history) {
    this.isLoading = false
    const data = {
      filling: this.supportList.filling,
      flavor: this.supportList.flavor,
      shape: this.supportList.shape,
      serving: this.supportList.serving,
      outershell: this.supportList.outershell,
      image_print: {
        is_supported: this.supportList.bakery_info[0].is_supported,
        price: this.supportList.bakery_info[0].price? this.supportList.bakery_info[0].price: '0',
      },
      text_on_cake: {
        is_supported: this.supportList.bakery_info[1].is_supported,
        price: this.supportList.bakery_info[1].price ? this.supportList.bakery_info[1].price : '0',
      },
    }
    axios
      .post(`${servicePath}/bakery/support/set?bakery_id=${id}`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async(data) => {
        this.supportList = data.data
        this.supportListBackup = data.data
        this.supportListArray = []
        _.forEach(this.supportList, (key, value) => {
          this.supportListArray.push({ name: value, arr: key })
        })
        successNotify('Item has been updated successfully')
        await this.reset()
        this.getBakerySupport(id, history)
      })
      .catch((err) => {
        console.log({ err })
        this.isLoading = true
        checkUnauthorized(err, history)
      })
  }

  @action
  reset() {
    this.supportList = null
    this.supportListBackup = null
    this.supportListArray = []
    this.target = null
    this.openCustomCakeModal = false
    this.isLoading = false
  }
}
