import { observable, action } from 'mobx'
import axios from 'axios'
import { servicePath, checkUnauthorized } from '../constants/defaultValues'
import axiFetch from '../config/fetch'
import { successNotify } from '../util/Notify'

export default class CustomHomeStore {
  @observable
  isLoading = false

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  paginationCategory = 0

  @observable
  currentPageCategory = 1

  @observable
  searchCategory = null

  @observable
  searchOnCategory = null

  @observable
  limitCategory = 12

  @observable
  offsetCategory = 0

  @observable
  orderCategory = { id: -1 }

  @observable
  filterCategory = {}

  @observable
  layout = []

  @observable
  category = null

  @observable
  openCategoryModal = false

  @observable
  openCollectionModal = false

  @observable
  isLoadingCategory = true

  @observable
  selectedCategory = null

  @observable
  collectionsId = []

  @observable
  featurette = null

  @observable
  collection = []

  @observable
  bakeryOption = [
    { value: 'top', label: 'Top Bakery' },
    { value: 'new', label: 'New Bakery' },
    { value: 'bakery', label: 'Random Bakery' },
  ]

  @observable
  selectedBakeryOption = { value: 'top', label: 'Top Bakery' }

  @observable
  items = [
    {
      key: 'category',
      label: 'Category',
      selected: false,
      isMulti: false,
      data: [],
    },
    {
      key: 'vendor',
      label: 'Vendor',
      selected: false,
      isMulti: false,
      titel_en: '',
      title_ar: '',
      type: { value: 'top', label: 'Top Bakery' },
    },
    {
      key: 'featurette',
      label: 'Featurette',
      selected: false,
      isMulti: false,
      data: [],
    },
    {
      key: 'collection',
      label: 'Collection',
      title_en: '',
      title_ar: '',
      selected: false,
      isMulti: true,
      info: null,
    },
  ]

  @action
  handleChangeLayout(data) {
    this.items.forEach((item) => {
      if (item.key === data.key) {
        item.selected = true
        this.layout.unshift(item)
      }
    })
    if (data.key === 'collection') {
      this.handleChangeCollectionModal()
    }
  }

  @action
  async getDefault(history) {
    this.isLoading = true
    axios
      .get(`${servicePath}/home/app`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        this.layout = data.data
        data.data.forEach((item) => {
          if (item.key === 'category') {
            this.selectedCategory = item.data
            this.items.forEach((row) => {
              if (row.key === 'category') {
                row.selected = true
              }
            })
          }
        })

        data.data.forEach((item) => {
          if (item.key === 'featurette') {
            this.items.forEach((row) => {
              if (row.key === 'featurette') {
                row.selected = true
              }
            })
          }
        })

        this.layout.forEach((item) => {
          if (item.key === 'vendor') {
            this.bakeryOption.forEach((type) => {
              if (item.type === type.value) {
                item.type = type
              }
            })
            this.items.forEach((row) => {
              if (row.key === 'vendor') {
                row.selected = true
                row.type = this.selectedBakeryOption
              }
            })
          }
        })

        data.data.forEach((item) => {
          if (item.key === 'collection') {
            this.collectionsId.push(item.info.id)
          }
        })
        await this.getCollection(history)
        await this.getFeaturette(history)
        // this.selectedBakeryOption = { value: "top", label: "Top Bakery" };

        this.isLoading = false
      })
      .catch((err) => {
        checkUnauthorized(err, history)
      })
  }

  @action
  async getFeaturette(history) {
    // this.isLoading = true;
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: 500,
      offset: this.offset,
      // all: true,
    }
    const value = await axiFetch.request(
      'featurette',
      false,
      false,
      false,
      'post',
      'list',
      body,
      history
    )
    if (value) {
      this.featurette = value.data.items
    }
  }

  @action
  getCollection(history) {
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: 500,
      offset: this.offset,
      // all: true,
    }
    axios
      .post(`${servicePath}/collection/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.collection = data.data.items
      })
      .catch((err) => {
        checkUnauthorized(err, history)
      })
  }

  @action
  getCategory(history) {
    this.isLoadingCategory = true
    const body = {
      order: this.orderCategory,
      searchOn: this.searchOnCategory,
      search: this.searchCategory,
      limit: this.limitCategory,
      offset: this.offsetCategory,
    }
    axios
      .post(`${servicePath}/category/list`, body, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then((data) => {
        this.category = data.data.items
        this.paginationCategory =
          parseInt(data.data.pagination.total / this.limitCategory) +
          (data.data.pagination.total % this.limitCategory ? 1 : 0)
        this.isLoadingCategory = false
      })
      .catch((err) => {
        checkUnauthorized(err, history)
      })
  }

  @action
  changePageCategory(e) {
    this.offsetCategory = (e - 1) * 12
    this.currentPageCategory = e
    this.getCategory()
  }

  @action
  handleChangeCategoryModal() {
    this.openCategoryModal = true
  }

  @action
  toggleCategoryModal() {
    this.openCategoryModal = false
  }

  @action
  handleChangeCollectionModal() {
    this.openCollectionModal = true
  }

  @action
  toggleCollectionModal() {
    this.openCollectionModal = false
    let data = this.layout.filter((item) => item.title_en !== '')
    this.layout = [...data]
  }

  @action
  addItemToCategoryList = (value) => {
    this.selectedCategory = value
    this.layout.forEach((item) => {
      if (item.key === 'category') {
        item.data = []
      }
    })

    value.forEach((data) => {
      this.layout.forEach((item) => {
        if (item.key === 'category') {
          item.data.push(data)
        }
      })
    })
  }

  @action
  addItemToCollectionList(value, target, id) {
    if (value[0]) {
      if (id) {
        this.collectionsId = this.collectionsId.filter((item) => item !== id)
        this.layout.forEach((item) => {
          if (
            item.key === 'collection' &&
            item.info.id &&
            item.info.id === id
          ) {
            this.collectionsId.push(value[0].id)
            item.title_en = value[0].name_en
            item.title_ar = value[0].name_ar
            item.info = value[0]
          }
        })
      } else {
        this.layout.forEach((item) => {
          if (item.key === 'collection' && item.title_en === '') {
            this.collectionsId.push(value[0].id)
            item.title_en = value[0].name_en
            item.title_ar = value[0].name_ar
            item.info = value[0]
          }
        })
      }
      this.toggleCollectionModal()
      this.saveHomePage()
    }
  }

  @action
  changeTitleEnVendor(value) {
    this.layout.forEach((item) => {
      if (item.key === 'vendor') {
        item.title_en = value
      }
    })
  }

  @action
  changeTitleArVendor(value) {
    this.layout.forEach((item) => {
      if (item.key === 'vendor') {
        item.title_ar = value
      }
    })
  }

  @action
  removeItem(value) {
    if (value.key === 'category') {
      this.selectedCategory = null
      this.items.forEach((item) => {
        if (item.key === 'category') {
          item.selected = false
        }
      })
    }
    if (value.key === 'featurette') {
      this.items.forEach((item) => {
        if (item.key === 'featurette') {
          item.selected = false
        }
      })
    }

    if (value.key === 'vendor') {
      this.items.forEach((item) => {
        if (item.key === 'vendor') {
          item.selected = false
        }
      })
    }

    if (value.key === 'collection') {
      this.collectionsId = this.collectionsId.filter(
        (id) => id !== value.info.id
      )
    }
    let data = this.layout.filter((item) => item.title_en !== value.title_en)
    this.layout = [...data]
  }

  @action
  changeLayout(data) {
    this.layout = data
  }

  @action
  saveHomePage(history) {
    this.isLoading = true
    const data = []
    this.layout.forEach((item) => {
      if (item.key === 'vendor') {
        data.push({
          key: item.key,
          title_en: item.title_en,
          title_ar: item.title_ar,
          type: item.type.value,
        })
      }
      if (item.key === 'category') {
        const ids = []
        item.data.forEach((category) => {
          ids.push(category.id)
        })
        data.push({
          key: item.key,
          title_en: 'category',
          title_ar: 'alcategory',
          ids: ids,
        })
      }
      if (item.key === 'featurette') {
        data.push({
          key: item.key,
          title_en: 'featurette',
          title_ar: 'alfeaturette',
          ids: [],
        })
      }
      if (item.key === 'collection') {
        data.push({
          key: item.key,
          title_en: 'collection',
          title_ar: 'alcollection',
          ids: item.info.id,
        })
      }
    })
    axios
      .post(`${servicePath}/home/updateHomepage`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      .then((res) => res.data)
      .then(async (data) => {
        successNotify('Item has been updated successfully')
        await this.reset()
        await this.getDefault(history)
      })
      .catch((err) => {
        console.log({ err })
        checkUnauthorized(err, history)
      })
  }

  @action
  setBakeryOption(value) {
    this.selectedBakeryOption = value.selectedOption
    this.layout.forEach((item) => {
      if (item.key === 'vendor') {
        item.type = value.selectedOption
      }
    })
  }

  @action
  reset() {
    // this.isLoading = false;

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.paginationCategory = 0

    this.currentPageCategory = 1

    this.searchCategory = null

    this.searchOnCategory = null

    this.limitCategory = 12

    this.offsetCategory = 0

    this.orderCategory = { id: -1 }

    this.filterCategory = {}

    this.layout = []

    this.category = null

    this.openCategoryModal = false

    this.openCollectionModal = false

    this.isLoadingCategory = true

    this.selectedCategory = null

    this.collectionsId = []

    this.featurette = null

    this.bakeryOption = [
      { value: 'top', label: 'Top Bakery' },
      { value: 'new', label: 'New Bakery' },
      { value: 'random', label: 'Random Bakery' },
    ]

    this.selectedBakeryOption = { value: 1, label: 'Top Bakery' }

    this.items = [
      {
        key: 'category',
        label: 'Category',
        selected: false,
        isMulti: false,
        data: [],
      },
      {
        key: 'vendor',
        label: 'Vendor',
        selected: false,
        isMulti: false,
        titel_en: '',
        title_ar: '',
        type: { value: 'top', label: 'Top Bakery' },
      },
      {
        key: 'featurette',
        label: 'Featurette',
        selected: false,
        isMulti: false,
        data: [],
      },
      {
        key: 'collection',
        label: 'Collection',
        title_en: '',
        title_ar: '',
        selected: false,
        isMulti: true,
        info: null,
      },
    ]
  }
}
