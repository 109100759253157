import React, { Component } from 'react'
import {
  Card,
  CardImg,
  CardSubtitle,
  Row,
  CardBody,
  Button,
  CardText,
  Badge,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Colxx } from '../CustomBootstrap'

class CardImageList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { data, path, click, deleteImage, hasBadge } = this.props
    return (
      <Colxx sm="6" lg="4" xl="3" className="mb-3" key={data.id}>
        {path ? (
          <NavLink to={`/app/${path}/${data.id}`}>
            <Card>
              <div
                className="position-relative"
                style={{ width: '100%', paddingTop: '75%' }}
              >
                <CardImg
                  top
                  alt={data.name_en}
                  src={
                    path === 'product/detail'
                      ? data.images[0]
                        ? data.images[0].url
                        : '/assets/img/noimage.png'
                      : data.image
                      ? data.image.url
                      : '/assets/img/noimage.png'
                  }
                />
              </div>
              {data.name || data.name_en ? (
                <CardBody className="p-3">
                  <Row className="p-0">
                    <Colxx xxs="12">
                      <CardText className="text-one">{`${
                        data.name ? data.name : data.name_en ? data.name_en : ''
                      }`}</CardText>
                    </Colxx>
                    {data.price ? (
                      <Colxx xxs="12" className="mt-2">
                        <CardText className="text-one">{`${data.price} KD`}</CardText>
                      </Colxx>
                    ) : null}
                  </Row>
                </CardBody>
              ) : null}
            </Card>
          </NavLink>
        ) : (
          <Card className="pointer" onClick={click}>
            <div className="position-relative">
              <Button
                onClick={() => deleteImage(data.id)}
                color="primary"
                style={{
                  position: 'absolute',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  padding: '0',
                  right: '5px',
                  top: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 1,
                }}
              >
                <i style={{ fontSize: '20px' }} className="iconsminds-close" />
              </Button>
              {hasBadge && (
                <Badge
                  color={data.bakery ? 'primary' : 'danger'}
                  pill
                  style={{
                    left: '-11px',
                    top: '80%',
                    fontSize: '12px',
                    zIndex: 1,
                  }}
                  className="position-absolute badge-top-left"
                >
                  {data.bakery ? data.bakery.name_en : 'Deleted Bakery'}
                </Badge>
              )}
              <div
                className="position-reletive"
                style={{ width: '100%', paddingTop: '75%' }}
              >
                <CardImg
                  top
                  alt={data.name_en}
                  src={
                    path === 'product/detail'
                      ? data.images[0]
                        ? data.images[0].url
                        : '/assets/img/noimage.png'
                      : data.image
                      ? data.image.url
                      : '/assets/img/noimage.png'
                  }
                />
              </div>
            </div>
            {data.name ? (
              <CardBody className="p-3">
                <Row className="p-0">
                  <Colxx xxs="12">
                    <CardSubtitle>{`${data.name}`}</CardSubtitle>
                  </Colxx>
                </Row>
              </CardBody>
            ) : null}
          </Card>
        )}
      </Colxx>
    )
  }
}

export default CardImageList
