import { observable, action } from "mobx";
import axiFetch from "../config/fetch";

export default class CustomerDetailStore {
  @observable
  customerDetail = null;

  @observable
  imageFile = null;

  @observable
  search = null;

  @observable
  searchOn = null;

  @observable
  limit = 10;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  openDeleteModal = false;

  @action
  getCustomerDetail = async (id, history) => {
    this.isLoading = true;
    this.imageFile = null;
    const value = await axiFetch.request(
      "customer",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.customerDetail = value.data;
      this.isLoading = false;
    }
  };

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

  @action
  changeImageFile = (file) => {
    this.imageFile = file;
  };

  @action.bound
  changeEnName = (name) => {
    this.customerDetail.name_en = name;
  };

  @action
  changeArName = (name) => {
    this.customerDetail.name_ar = name;
  };

  @action
  reset() {
    this.customerDetail = null;

    this.imageFile = null;

    this.search = null;

    this.searchOn = null;

    this.limit = 10;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.openDeleteModal = false;
  }
}
