import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OrderList from './list';
import OrderDetail from './detail';
import PrintOrderDetail from './print';



const Order = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={OrderList} />
            <Route path={`${match.url}/detail/print/:id`} component={PrintOrderDetail} />
            <Route path={`${match.url}/detail/:id`} component={OrderDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Order;