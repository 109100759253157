import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardImg,
} from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import DeleteModal from "../../../components/Modal/deleteModal";
import CollectionModal from "../../../components/Modal/collectionModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

@inject("stores")
@observer
class CollectionDetail extends Component {
  @observable
  store = this.props.stores.collectionDetail;
  constructor(props) {
    super(props);
    this.state = { id: null };
  }
  componentDidMount() {
    let path = this.props.match.params.id;
    this.setState({ id: path });
    this.store.getCollectionDetail(path, this.props.history);
  }

  componentWillUnmount() {
    this.store.reset();
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            {this.store.openProductModal ? (
              <CollectionModal
                openModal={this.store.openProductModal}
                data={this.store.products}
                loading={this.store.isLoadingProduct}
                toggleModal={() => this.store.toggleProductModal()}
                pagination={this.store.paginationProduct}
                limit={this.store.limitProduct}
                currentPage={this.store.currentPageProduct}
                changePage={(e) => this.store.changePage(e)}
                target="product"
                addItems={(data) => this.store.addItemToSelected(data)}
              />
            ) : null}
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.collection" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={
                  this.store.collectionDetail
                    ? this.store.collectionDetail.name_en
                    : null
                }
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteCollection(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.validation(this.state.id, this.props.history)
                }
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: "25px" }}
                >
                  <Row>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {this.store.collectionDetail
                                ? `Collection Detail`
                                : null}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.collectionDetail
                                    ? this.store.collectionDetail.name_en
                                    : ""
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <p className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.collectionDetail
                                    ? this.store.collectionDetail.name_ar
                                    : ""
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </p>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx
                      xxs="12"
                      md="12"
                      lg="4"
                      className="mb-4"
                      style={{ height: "max-content" }}
                    >
                      <Card className="mb-2">
                        <CardBody className=" pb-0">
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {this.store.collectionDetail
                                ? `Collection Product`
                                : null}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx
                            xxs="12"
                            className="p-0 m-0 mb-3 d-flex flex-wrap"
                          >
                            {this.store.productList
                              ? this.store.productList.map((item) => (
                                  <div
                                    key={item.id}
                                    // href={`/app/product/detail/${item.id}`}
                                    className="p-1 col-6"
                                  >
                                    <Card className="col-12 m-0 p-0">
                                      <div
                                        className="position-relative col-12"
                                        style={{ paddingTop: "100%" }}
                                      >
                                        <Button
                                          onClick={() =>
                                            this.store.removeFromList(item.id)
                                          }
                                          color="primary"
                                          style={{
                                            position: "absolute",
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                            padding: "0",
                                            right: "5px",
                                            top: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            style={{ fontSize: "20px" }}
                                            className="iconsminds-close"
                                          />
                                        </Button>

                                        <p
                                          className="pointer m-0"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/app/product/detail/${item.id}`
                                            );
                                          }}
                                          style={{
                                            position: "absolute",
                                            width: "90%",
                                            height: "30px",
                                            borderRadius: "0.75rem",
                                            padding: "8",
                                            right: "5%",
                                            top: "33%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            zIndex: 1,
                                            color: "white",
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.75",
                                          }}
                                        >
                                          {item.name}
                                        </p>
                                        <CardImg
                                          src={item.images[0].url}
                                        ></CardImg>
                                      </div>
                                    </Card>
                                  </div>
                                ))
                              : null}
                          </Colxx>
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                            onClick={() => {
                              this.store.getProduct(this.props.history);
                            }}
                          >
                            <Label
                              className="btn btn-default no-padding"
                              style={{
                                padding: "0px",
                                marginBottom: "0px",
                              }}
                            >
                              <strong>Add New Product</strong>{" "}
                            </Label>
                          </Button>
                          <Colxx xxs="12" lg="12" className="mb-3"></Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default CollectionDetail;
