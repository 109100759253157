import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'

class CouponModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      openModal,
      toggleModal,
      unit,
      changeUnit,
      amount,
      changeAmount,
      amount_validation,
      usage,
      changeUsage,
      usage_validation,
      startDate,
      changeStartDate,
      startDate_validation,
      endDate,
      changeEndDate,
      endDate_validation,
      handleSave,
      btnDisable,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="p-0 mb-3">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.unit" />
                <span className="req"> *</span>
              </p>
              <Colxx className="col-12 d-flex p-0">
                <Colxx xxs="6" className="d-flex align-items-center p-0">
                  <input
                    type="radio"
                    name="radio1"
                    className="mr-1"
                    value="KD"
                    checked={unit === 'KD'}
                    onClick={() => changeUnit('KD')}
                    onChange={() => {}}
                  />
                  KD
                </Colxx>
                <Colxx xxs="6" className="d-flex align-items-center p-0">
                  <input
                    type="radio"
                    name="radio1"
                    className="mr-1"
                    value="%"
                    checked={unit === '%'}
                    onClick={() => changeUnit('%')}
                    onChange={() => {}}
                  />
                  %
                </Colxx>
              </Colxx>
            </Colxx>
            {unit === 'KD' && (
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.amount" /> (KD)
                  <span className="req"> *</span>
                </p>
                <p className="mb-0">
                  <Input
                    name="amount"
                    value={amount ? amount : ''}
                    min="0"
                    type="number"
                    onChange={(e) => changeAmount(e.target.value)}
                  />
                </p>
                <span className="req-validation ">
                  {' '}
                  {amount_validation
                    ? amount_validation.msg
                      ? amount_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
            )}

            {unit === '%' && (
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.amount" /> (%)
                  <span className="req"> *</span>
                </p>
                <p className="mb-0">
                  <Input
                    name="amount"
                    value={amount ? amount : ''}
                    min="0"
                    max="100"
                    type="number"
                    onChange={(e) => changeAmount(e.target.value)}
                  />
                </p>
                <span className="req-validation ">
                  {' '}
                  {amount_validation
                    ? amount_validation.msg
                      ? amount_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
            )}
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.usage" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="usage"
                  value={usage ? usage : ''}
                  type="text"
                  onChange={(e) => changeUsage(e.target.value)}
                />
              </p>
              <span className="req-validation ">
                {' '}
                {usage_validation
                  ? usage_validation.msg
                    ? usage_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>

            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.startDate" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="startDate"
                  value={startDate ? startDate : ''}
                  type="date"
                  onChange={(e) => changeStartDate(e.target.value)}
                />
              </p>
              <span className="req-validation ">
                {' '}
                {startDate_validation
                  ? startDate_validation.msg
                    ? startDate_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.endDate" />
                <span className="req"> *</span>
              </p>
              <p className="mb-0">
                <Input
                  name="endDate"
                  value={endDate ? endDate : ''}
                  type="date"
                  onChange={(e) => changeEndDate(e.target.value)}
                />
              </p>
              <span className="req-validation ">
                {' '}
                {endDate_validation
                  ? endDate_validation.msg
                    ? endDate_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CouponModal
