import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import moment from 'moment'

export default class DashboardStore {
  @observable
  data = null

  @observable
  event = []

  @observable
  isLoading = false

  @observable
  calLoading = false

  @observable
  btnDisable = false

  @observable
  preparingOrders = null

  @observable
  sentOrders = null

  @observable
  canceledOrders = null

  @observable
  complatedOrders = null

  @observable
  totalSale = null

  @observable
  totalSaleItem = null

  @observable
  recentOrders = null

  @observable
  topFilling = null

  @observable
  topFlavor = null

  @observable
  topOuterShell = null

  @observable
  topBakeries = null

  @observable
  topSellers = null

  @observable
  months = moment.months()

  @observable
  years = null

  @observable
  target = 'today'

  @observable
  targetMonth = moment().format('MMMM')

  @observable
  targetYear = moment().format('YYYY')

  @observable
  labels = []

  @observable
  sale = []

  @observable
  fromDate = new Date(
    Date.UTC(
      moment().format('YYYY'),
      moment().format('M') - 1,
      moment().format('D')
    )
  )

  @observable
  toDate = new Date(
    Date.UTC(
      moment().format('YYYY'),
      moment().format('M') - 1,
      moment().format('D')
    )
  )

  @action
  async getMonthDataCal(m, y, history) {
    this.calLoading = true
    const body = `${moment(`${m}/15/${y} 10:00:00 AM `).format('X')}`

    const value = await axiFetch.request(
      `dashboard/calendar/${body}`,
      false,
      false,
      false,
      'get',
      false,
      body,
      history
    )

    if (value) {
      this.event = []
      let date = value.data.date.split('-')
      await value.data.chartData.forEach((item) => {
        this.event.push({
          key: `${item.x}`,
          title: item.y ? `Total Sale : ${item.y} KD` : null,
          start: `${date[1]} ${item.x} ${date[0]}`,
          end: `${date[1]} ${item.x} ${date[0]}`,
        })
      })
      this.calLoading = false
    }
  }

  @action
  async getData(history) {
    this.isLoading = true
    this.sale = []
    this.labels = []
    const body = {
      [this.target]:
        this.target === 'today'
          ? true
          : this.target === 'month'
          ? {
              date: moment(
                `${moment().month(this.targetMonth).format('M')}/15/${
                  this.targetYear
                } 10:00:00 AM `
              ).format('X'),
            }
          : this.target === 'year'
          ? {
              date: moment(`6/15/${this.targetYear} 10:00:00 AM `).format('X'),
            }
          : true,
    }
    const value = await axiFetch.request(
      'dashboard',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )

    if (value) {
      this.data = value.data

      value.data.chartData.forEach((item) => {
        this.labels.push(
          this.target === 'today'
            ? item.x
            : this.target === 'year'
            ? item.x
            : this.target === 'month'
            ? item.x
            : item.x
        )
        this.sale.push(item.y)
      })

      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  handleFilter(history) {
    switch (this.target) {
      case 'allTime':
        this.fromDate = null
        this.toDate = null
        this.getData(history)
        break
      case 'month':
        // this.fromDate = new Date(
        //   moment
        //     .unix(
        //       moment(
        //         `${moment().month(this.targetMonth).format('M')}/15/${
        //           this.targetYear
        //         } 10:00:00 AM `
        //       ).format('X')
        //     )
        //     .startOf('month')
        //     .format('MM/DD/YYYY')
        // ).toUTCString()
        // let startDay = moment
        //   .unix(
        //     moment(
        //       `${moment().month(this.targetMonth).format('M')}/15/${
        //         this.targetYear
        //       } 10:00:00 AM `
        //     ).format('X')
        //   )
        //   .startOf('month')
        //   .format('D')
        this.fromDate = new Date(
          Date.UTC(
            this.targetYear,
            moment().month(this.targetMonth).format('M') - 1,
            1
          )
        )
        // this.toDate = new Date(
        //  ( moment
        //     .unix(
        //       moment(
        //         `${moment().month(this.targetMonth).format('M')}/15/${
        //           this.targetYear
        //         } 10:00:00 AM `
        //       ).format('X')
        //     )
        //     .endOf('month')
        //     .format('MM/DD/YYYY'))
        // ).toUTCString()
        let endDay = moment
          .unix(
            moment(
              `${moment().month(this.targetMonth).format('M')}/15/${
                this.targetYear
              } 10:00:00 AM `
            ).format('X')
          )
          .endOf('month')
          .format('D')
        this.toDate = new Date(
          Date.UTC(
            this.targetYear,
            moment().month(this.targetMonth).format('M') - 1,
            endDay
          )
        )
        this.getData(history)
        break
      case 'year':
        // this.fromDate = new Date(
        //   moment
        //     .unix(moment(`6/15/${this.targetYear} 10:00:00 AM `).format('X'))
        //     .startOf('year')
        //     .format('MM/DD/YYYY')
        // )
        // this.toDate = new Date(
        //   moment
        //     .unix(moment(`6/15/${this.targetYear} 10:00:00 AM `).format('X'))
        //     .endOf('year')
        //     .format('MM/DD/YYYY')
        // )
        this.fromDate = new Date(Date.UTC(this.targetYear, 1, 1))
        this.toDate = new Date(Date.UTC(this.targetYear, 12, 31))
        this.getData(history)
        break

      case 'today':
        this.fromDate = new Date(
          Date.UTC(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D')
          )
        )
        this.fromDate = new Date(
          Date.UTC(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D')
          )
        )
        this.getData(history)
        break

      default:
        break
    }
  }

  @action
  handleTarget(value) {
    this.target = value
  }

  @action
  handleMonth(value) {
    this.targetMonth = value
  }

  @action
  handleYear(value) {
    this.targetYear = value
  }
}
