import { observable, action } from "mobx";
import axiFetch from "../config/fetch";

export default class UserStore {
  @observable
  data = null;

  @observable
  pagination = 0;

  @observable
  currentPage = 1;

  @observable
  imageFile = null;

  @observable
  search = null;

  @observable
  searchOn = ["name", "phonenumber", "email"];

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  openModal = false;

  @observable
  name = null;

  @observable
  password = null;

  @observable
  email = null;

  @observable
  btnDisable = false;

  @action
  getData = async (history) => {
    this.isLoading = true;
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    };

    const value = await axiFetch.request(
      "customer/list",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.data = value.data.items;
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0);
      this.isLoading = false;
    }
  };

  @action
  changeOpenModal = () => {
    this.openModal = true;
  };

  @action
  toggleModal = () => {
    this.openModal = false;
  };

  @action
  changeImageFile = (file) => {
    this.imageFile = file;
  };

  @action
  changeName = (value) => {
    this.name = value;
  };

  @action
  changePassword = (value) => {
    this.password = value;
  };

  @action
  changeEmail = (value) => {
    this.email = value;
  };

  @action
  changePage(e) {
    this.offset = (e - 1) * 12;
    this.currentPage = e;
    this.getData();
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value;
  };

  @action
  handleKeyPress = (e, history) => {
    if (e.key === "Enter") {
      this.offset = 0;
      this.currentPage = 1;
      this.getData(history);
    }
  };

  @action
  reset() {
    this.data = null;

    this.pagination = 0;

    this.currentPage = 1;

    this.imageFile = null;

    this.search = null;

    this.searchOn = ["name", "phonenumber", "email"];

    this.limit = 12;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.openModal = false;

    this.name = null;

    this.password = null;

    this.email = null;
  }
}
