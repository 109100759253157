import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Label,
} from "reactstrap";
// import Select from "react-select";
import IntlMessages from "../../util/IntlMessages";
import { Colxx } from "../CustomBootstrap";
import { Thumb } from "../Thumb";
import { RecThumb } from "../Thumb/recThumb";

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      openModal,
      noImage,
      toggleModal,
      imageFile,
      landscapeImageFile,
      changeImageFile,
      name_en,
      name_ar,
      changeArName,
      changeEnName,
      changeEmail,
      handleSave,
      target,
      changePhone,
      changeAddress,
      changeArAddress,
      phone,
      address,
      address_ar,
      email,
      btnDisable,
      name_en_validation,
      name_ar_validation,
      imageFile_validation,
      phone_validation,
      address_validation,
      address_ar_validation,
      email_validation,
      changeLandscapeImageFile,
      landscapeImageFile_validation,
      CoverImage,
      changeCoverImageFile,
      coverImageFile_validation,
      coverImageFile,
    } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="mb-3 p-0">
            {target !== "gallery" ? (
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.name" />
                  <span className="req"> *</span>
                </p>
                <div className="mb-0">
                  <Input
                    name="name"
                    value={name_en ? name_en : ""}
                    type="text"
                    onChange={(e) => changeEnName(e.target.value)}
                  />
                </div>
                <span className="req-validation ">
                  {" "}
                  {name_en_validation
                    ? name_en_validation.msg
                      ? name_en_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
            ) : null}
            {target !== "gallery" ? (
              <Colxx xxs="12" className="p-0 mb-3">
                <p className="text-muted text-small mb-2">
                  <IntlMessages id="pages.name-ar" />
                  <span className="req"> *</span>
                </p>
                <div className="mb-0">
                  <Input
                    name="name_ar"
                    value={name_ar ? name_ar : ""}
                    type="text"
                    onChange={(e) => changeArName(e.target.value)}
                    className="ar"
                  />
                </div>
                <span className="req-validation">
                  {" "}
                  {name_ar_validation
                    ? name_ar_validation.msg
                      ? name_ar_validation.msg
                      : null
                    : null}
                </span>
              </Colxx>
            ) : null}
            {target === "bakery" ? (
              <>
                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">
                    <IntlMessages id="pages.phone" />
                    <span className="req"> *</span>
                  </p>
                  <div className="mb-0">
                    <Input
                      name="phone"
                      value={phone ? phone : ""}
                      type="text"
                      onChange={(e) => changePhone(e.target.value)}
                    />
                  </div>
                  <span className="req-validation">
                    {" "}
                    {phone_validation
                      ? phone_validation.msg
                        ? phone_validation.msg
                        : null
                      : null}
                  </span>
                </Colxx>

                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">
                    <IntlMessages id="pages.email" />
                    <span className="req"> *</span>
                  </p>
                  <div className="mb-0">
                    <Input
                      name="email"
                      value={email ? email : ""}
                      type="text"
                      onChange={(e) => changeEmail(e.target.value)}
                    />
                  </div>
                  <span className="req-validation">
                    {email_validation
                      ? email_validation.msg
                        ? email_validation.msg
                        : null
                      : null}
                  </span>
                </Colxx>
                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">
                    <IntlMessages id="pages.address" />
                    <span className="req"> *</span>
                  </p>
                  <div className="mb-0">
                    <Input
                      name="address"
                      value={address ? address : ""}
                      type="textarea"
                      onChange={(e) => changeAddress(e.target.value)}
                    />
                  </div>
                  <span className="req-validation">
                    {" "}
                    {address_validation
                      ? address_validation.msg
                        ? address_validation.msg
                        : null
                      : null}
                  </span>
                </Colxx>

                <Colxx xxs="12" className="p-0 mb-3">
                  <p className="text-muted text-small mb-2">
                    <IntlMessages id="pages.arAddress" />
                    <span className="req"> *</span>
                  </p>
                  <div className="mb-0">
                    <Input
                      className="ar"
                      name="arabicAddress"
                      value={address_ar ? address_ar : ""}
                      type="textarea"
                      onChange={(e) => changeArAddress(e.target.value)}
                    />
                  </div>
                  <span className="req-validation">
                    {" "}
                    {address_ar_validation
                      ? address_ar_validation.msg
                        ? address_ar_validation.msg
                        : null
                      : null}
                  </span>
                </Colxx>
              </>
            ) : null}
          </Colxx>
          {!noImage ? (
            <>
              <Colxx xxs="12" className="mb-3 p-0">
                <Thumb
                  key="cat-image"
                  className="almost-full-width "
                  file={imageFile}
                  src={imageFile ? imageFile : "/assets/img/noimage.png"}
                />
                <span className="req-validation">
                  {" "}
                  {imageFile_validation
                    ? imageFile_validation.msg
                      ? imageFile_validation.msg
                      : null
                    : null}
                </span>
                <Button
                  color="primary"
                  size="lg"
                  className="pointer mt-2 col-12"
                  style={{
                    padding: "5px 16px",
                  }}
                >
                  <Label
                    className="btn btn-default no-padding col-12"
                    style={{
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <small>Add Square Image</small>
                    <input
                      className="col-12 p-0 "
                      id="image"
                      name="image"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      onChange={(event) => {
                        changeImageFile(event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                  </Label>
                </Button>
              </Colxx>
              {target === "category" ? (
                <Colxx xxs="12" className="mb-3 p-0">
                  <RecThumb
                    key="cat-image"
                    className="almost-full-width "
                    file={landscapeImageFile}
                    src={
                      landscapeImageFile
                        ? landscapeImageFile
                        : "/assets/img/noimage.png"
                    }
                  />
                  <span className="req-validation">
                    {" "}
                    {landscapeImageFile_validation
                      ? landscapeImageFile_validation.msg
                        ? landscapeImageFile_validation.msg
                        : null
                      : null}
                  </span>
                  <Button
                    color="primary"
                    size="lg"
                    className="pointer mt-2 col-12"
                    style={{
                      padding: "5px 16px",
                    }}
                  >
                    <Label
                      className="btn btn-default no-padding col-12"
                      style={{
                        padding: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <small>Add Rectangle Image</small>
                      <input
                        className="col-12 p-0 "
                        id="image"
                        name="image"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={(event) => {
                          changeLandscapeImageFile(
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </Label>
                  </Button>
                </Colxx>
              ) : null}
            </>
          ) : null}
          {CoverImage ? (
            <>
              <Colxx xxs="12" className="mb-3 p-0">
                <Thumb
                  key="cat-image"
                  className="almost-full-width "
                  file={coverImageFile}
                  src={
                    coverImageFile ? coverImageFile : "/assets/img/noimage.png"
                  }
                />
                <span className="req-validation">
                  {coverImageFile_validation
                    ? coverImageFile_validation.msg
                      ? coverImageFile_validation.msg
                      : null
                    : null}
                </span>
                <Button
                  color="primary"
                  size="lg"
                  className="pointer mt-2 col-12"
                  style={{
                    padding: "5px 16px",
                  }}
                >
                  <Label
                    className="btn btn-default no-padding col-12"
                    style={{
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <small>Add Cover Image</small>
                    <input
                      className="col-12 p-0 "
                      id="image"
                      name="image"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      onChange={(event) => {
                        changeCoverImageFile(event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                  </Label>
                </Button>
              </Colxx>
              {target === "category" ? (
                <Colxx xxs="12" className="mb-3 p-0">
                  <RecThumb
                    key="cat-image"
                    className="almost-full-width "
                    file={landscapeImageFile}
                    src={
                      landscapeImageFile
                        ? landscapeImageFile
                        : "/assets/img/noimage.png"
                    }
                  />
                  <span className="req-validation">
                    {landscapeImageFile_validation
                      ? landscapeImageFile_validation.msg
                        ? landscapeImageFile_validation.msg
                        : null
                      : null}
                  </span>
                  <Button
                    color="primary"
                    size="lg"
                    className="pointer mt-2 col-12"
                    style={{
                      padding: "5px 16px",
                    }}
                  >
                    <Label
                      className="btn btn-default no-padding col-12"
                      style={{
                        padding: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <small>Add Rectangle Image</small>
                      <input
                        className="col-12 p-0 "
                        id="image"
                        name="image"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={(event) => {
                          changeLandscapeImageFile(
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </Label>
                  </Button>
                </Colxx>
              ) : null}
            </>
          ) : null}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => handleSave()}
          >
            Save
          </Button>{" "}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalContainer;
