import { observable, action } from "mobx";

export default class MenuStore {
  @observable
  defaultMenuType = "menu-default sub-hidden";

  @observable
  containerClassnames = this.defaultMenuType;

  @observable
  subHiddenBreakpoint = 1440;

  @observable
  menuHiddenBreakpoint = 768;

  @observable
  menuClickCount = 0;

  @observable
  selectedMenuHasSubItems = this.defaultMenuType === "menu-default";

  @action
  setContainerClassnames(
    clickIndex,
    strCurrentClasses,
    selectedMenuHasSubItems
  ) {
    const currentClasses = strCurrentClasses
      ? strCurrentClasses.split(" ").filter(x => x !== "")
      : "";
    let nextClasses = "";

    if (!selectedMenuHasSubItems) {
      if (currentClasses.includes("menu-default") && clickIndex % 4 === 0) {
        clickIndex = 1;
      }
      if (currentClasses.includes("menu-sub-hidden") && clickIndex % 4 === 3) {
        clickIndex = 1;
      }
    }

    if (clickIndex % 4 === 0) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden";
      }
      clickIndex = 0;
    } else if (clickIndex % 4 === 1) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary";
      }
    } else if (clickIndex % 4 === 2) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
      }
    } else if (clickIndex % 4 === 3) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-show-temporary";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary";
      }
    }
    if (currentClasses.includes("menu-mobile")) {
      nextClasses += " menu-mobile";
    }
    this.containerClassnames = nextClasses;
    this.menuClickCount = clickIndex;
  }

  @action
  changeSelectedMenuHasSubItems(payload) {
    this.selectedMenuHasSubItems = payload;
  }

  @action
  addContainerClassname(classname, strCurrentClasses) {
    const newClasses =
      !strCurrentClasses.indexOf(classname) > -1
        ? strCurrentClasses + " " + classname
        : strCurrentClasses;
    this.containerClassnames = newClasses;
  }

  @action
  clickOnMobileMenu = strCurrentClasses => {
    const currentClasses = strCurrentClasses
      ? strCurrentClasses
          .split(" ")
          .filter(x => x !== "" && x !== "sub-show-temporary")
      : "";
    let nextClasses = "";
    if (currentClasses.includes("main-show-temporary")) {
      nextClasses = currentClasses
        .filter(x => x !== "main-show-temporary")
        .join(" ");
    } else {
      nextClasses = currentClasses.join(" ") + " main-show-temporary";
    }

    this.containerClassnames = nextClasses;
    this.menuClickCount = 0;
  };
}
