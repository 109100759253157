import React, { Component } from 'react';

class AreaList extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <p>
                Hello Area
            </p>
         );
    }
}
 
export default AreaList;