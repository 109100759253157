import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'

export default class FeaturetteStore {
  @observable
  data = null

  @observable
  keys = []

  @observable
  categoryList = []

  @observable
  bakeryList = []

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  imageFileEn = null

  @observable
  imageFileAr = null

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  name = ''

  @observable
  name_ar=''

  @observable
  btnDisable = false

  @observable
  typeList = []

  @observable
  selectedType = []

  @observable
  itemList = []

  @observable
  selectedItem = []

  @observable
  url = ''

  @observable
  productsList = []

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  imageFile_validation = null

  @observable
  ar_imageFile_validation = null

  @observable
  item_validation = null

  @observable
  url_validation = null

  @observable
  type_validation = null

  @action
  async getData(history) {
    this.isLoading = true
    !this.productsList.length && this.getProduct(history)
    !this.keys.length && this.getKeys(history)
    !this.bakeryList.length &&
      !this.categoryList.length &&
      this.getDependencies(history)
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.request(
      'featurette',
      false,
      false,
      false,
      'post',
      'list',
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    }
  }

  @action
  async getKeys(history) {
    const value = await axiFetch.request(
      'featurette',
      false,
      false,
      false,
      'post',
      'keys',
      null,
      history
    )
    if (value) {
      value.data.map((key) =>
        this.keys.push({ value: key.key, label: key.title })
      )
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.categories.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.title,
        })
      )

      await value.data.bakeries.map((item) =>
        this.bakeryList.push({ value: item.id, label: item.name_en })
      )
    }
  }

  @action
  getProduct = async (history) => {
    const body = {
      order: { id: -1 },
      searchOn: null,
      search: '',
      limit: 500,
      offset: 0,
      filter: {},
    }
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getcakes',
      body,
      history
    )
    if (value) {
      value.data.items.map((item) =>
        this.productsList.push({ value: item.id, label: item.name })
      )
      // this.pagination =
      //   parseInt(value.data.pagination.total / this.limit) +
      //   (value.data.pagination.total % this.limit ? 1 : 0)
    }
  }
  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'Name',
      value: this.name,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    if (this.selectedType.label === 'url') {
      this.url_validation = await checkInput({
        name: 'Url',
        value: this.url,
        require: true,
        type: 'string',
        min: 3,
      })
    }

    this.imageFile_validation = await checkInput({
      name: 'English Image',
      value: this.imageFileEn,
      require: true,
    })

    this.ar_imageFile_validation = await checkInput({
      name: 'Arabic Image',
      value: this.imageFileAr,
      require: true,
    })

    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType.label,
      require: true,
    })

    this.item_validation = await checkInput({
      name: 'Item',
      value: this.selectedItem.label,
      require: true,
    })
    if (this.selectedType.label === 'url') {
      this.imageFile_validation.res &&
        this.url_validation.res &&
        this.type_validation.res &&
        this.ar_imageFile_validation.res &&
        this.en_name_validation &&
        this.ar_name_validation &&
        this.handleSaveNewFeaturette(history)
    } else {
      this.imageFile_validation.res &&
        this.item_validation.res &&
        this.type_validation.res &&
        this.ar_imageFile_validation.res &&
        this.en_name_validation &&
        this.ar_name_validation &&
        this.handleSaveNewFeaturette(history)
    }
  }

  @action
  handleSaveNewFeaturette = async (history) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      target: this.selectedType.value,
      name_en: this.name,
      name_ar: this.name_ar,
      value:
        this.selectedType.label === 'category' ||
        this.selectedType.label === 'cake' ||
        this.selectedType.label === 'bakery'
          ? parseInt(this.selectedItem.value)
          : this.selectedType.label === 'url'
          ? this.url
          : null,
      image: this.imageFileEn,
      image_ar: this.imageFileAr,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'featurette',
      false,
      false,
      false,
      'post',
      'add',
      formData,
      history
    )
    if (value) {
      await this.toggleModal()
      this.isLoading = false
      this.btnDisable = false
      history.push(`/app/featurette/detail/${value.data.id}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  setType(data) {
    this.selectedType = data.selectedOption
    this.selectedItem = []
    this.item_validation = null
    switch (this.selectedType.label) {
      case 'category':
        this.itemList = [...this.categoryList]
        break
      case 'bakery':
        this.itemList = [...this.bakeryList]
        break
      case 'cake':
        this.itemList = [...this.productsList]
        break
      case 'url':
        this.url = ''
        this.url_validation = null
        break
      default:
        break
    }
  }

  @action
  setItem(data) {
    this.selectedItem = data.selectedOption
  }
  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.imageFileAr = null
    this.imageFileEn = null
    this.name = ''
    this.url = ''
    this.selectedType = []
    this.selectedItem = []
    this.en_name_validation = null
    this.imageFile_validation = null
    this.ar_imageFile_validation = null
    this.item_validation = null
    this.url_validation = null
    this.type_validation = null
  }



  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileEn(file) {
    this.imageFileEn = this.base64ToBlob(file, 'image/jpg')
  }

  // @action
  // changeImageFileEn = (file) => {
  //   this.imageFileEn = file
  // }

  @action
  changeUrl = (value) => {
    this.url = value
  }

  @action
  changeImageFileAr = (file) => {
    this.imageFileAr = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeName = (name) => {
    this.name = name
  }

  @action
  changeArabicName=(name)=>{
    this.name_ar=name
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  reset() {
  this.data = null

  this.keys = []

  this.categoryList = []

  this.bakeryList = []

  this.pagination = 0

  this.currentPage = 1

  this.imageFileEn = null

  this.imageFileAr = null

  this.search = null

  this.searchOn = null

  this.limit = 12

  this.offset = 0

  this.order = { id: -1 }

  this.filter = {}

  this.isLoading = true

  this.openModal = false

  this.name = ''

  this.name_ar=''

  this.btnDisable = false

  this.typeList = []

  this.selectedType = []

  this.itemList = []

  this.selectedItem = []

  this.url = ''

  this.productsList = []

  this.en_name_validation = null

  this.ar_name_validation = null

  this.imageFile_validation = null

  this.ar_imageFile_validation = null

  this.item_validation = null

  this.url_validation = null

  this.type_validation = null
  }
}
