import { observable, action } from "mobx";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";

export default class ShapeDetailStore {
  @observable
  shapeDetail = null;

  @observable
  imageFile = null;

  @observable
  search = null;

  @observable
  searchOn = null;

  @observable
  limit = 10;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  openDeleteModal = false;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @observable
  imageFile_validation = null;

  @observable
  showUploader = true

  @action
  handleImageUploader = () => {
    this.showUploader = !this.showUploader
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploder(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploader = !this.showUploader
  }

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.shapeDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.shapeDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: !this.imageFile ? this.shapeDetail.image.url : this.imageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateShape(id, history)
  }

  @action
  getShapeDetail = async (id, history) => {
    this.isLoading = false;
    this.imageFile = null;

    this.en_name_validation = null;
    this.ar_name_validation = null;

    const value = await axiFetch.request(
      "shape",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.shapeDetail = value.data.items;
      this.isLoading = true;
    }
  };

  @action
  deleteShape = async (id, history) => {
    this.isLoading = false;
    const value = await axiFetch.request(
      "shape",
      false,
      `${id}/delete`,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.openDeleteModal = false;
      successNotify("Item has been deleted successfully");
      setTimeout(() => {
        history.push("/app/shape");
      }, 1000);
    } else {
      this.isLoading = true;
    }
  };

  @action
  handleUpdateShape = async (id, history) => {
    this.isLoading = false;
    let data = {
      name_en: this.shapeDetail.name_en,
      name_ar: this.shapeDetail.name_ar,
    };
    if (this.imageFile) {
      data.image = this.imageFile;
    }
    let formData = new FormData();
    for (let key in data) formData.append(key, data[key]);
    const value = await axiFetch.request(
      "shape",
      false,
      id,
      false,
      "post",
      false,
      formData,
      history
    );
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getShapeDetail(id);
    } else {
      this.isLoading = true;
    }
  };

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

  @action
  changeImageFile = (file) => {
    this.imageFile = file;
  };

  @action.bound
  changeEnName = (name) => {
    this.shapeDetail.name_en = name;
  };

  @action
  changeArName = (name) => {
    this.shapeDetail.name_ar = name;
  };

  @action
  reset() {
    this.shapeDetail = null;
  
    this.imageFile = null;
  
    this.search = null;
  
    this.searchOn = null;
  
    this.limit = 10;
  
    this.offset = 0;
  
    this.order = { id: -1 };
  
    this.filter = {};
  
    this.isLoading = false;
  
    this.openDeleteModal = false;
  
    this.en_name_validation = null;
  
    this.ar_name_validation = null;
  
    this.imageFile_validation = null;
  
    this.showUploader = true
  }
}
