import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap'
// import Select from "react-select";
import IntlMessages from '../../util/IntlMessages'
import { Colxx } from '../CustomBootstrap'
import ImageUploader from '../Uploader/Uploader'

class CategoryModal extends Component {
  constructor(props) {
    super(props)
    this.state = { getImage: false }
  }

  handleSave = () => {
    this.setState({ getImage: true })
  }

  changeImageFile = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    // this.props.handleSave()
  }

  changeImageFileLandscape = async (data) => {
    if (data) {
      this.setState({ getImage: false })
      await this.props.changeLandscapeImageFile(data)
    } else {
      this.setState({ getImage: false })
    }
    this.props.handleSave()
  }

  render() {
    const {
      openModal,
      toggleModal,
    //   imageFile,
    //   landscapeImageFile,
    //   changeImageFile,
      name_en,
      name_ar,
      changeArName,
      changeEnName,
    //   handleSave,
      btnDisable,
      name_en_validation,
      name_ar_validation,
      imageFile_validation,
    //   changeLandscapeImageFile,
      landscapeImageFile_validation,
    } = this.props
    return (
      <Modal
        isOpen={openModal}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="pages.add-new-modal-title" />
        </ModalHeader>
        <ModalBody>
          <Colxx xxs="12" className="mb-3 p-0">
            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name"
                  value={name_en ? name_en : ''}
                  type="text"
                  onChange={(e) => changeEnName(e.target.value)}
                />
              </div>
              <span className="req-validation ">
                {' '}
                {name_en_validation
                  ? name_en_validation.msg
                    ? name_en_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>

            <Colxx xxs="12" className="p-0 mb-3">
              <p className="text-muted text-small mb-2">
                <IntlMessages id="pages.name-ar" />
                <span className="req"> *</span>
              </p>
              <div className="mb-0">
                <Input
                  name="name_ar"
                  value={name_ar ? name_ar : ''}
                  type="text"
                  onChange={(e) => changeArName(e.target.value)}
                  className="ar"
                />
              </div>
              <span className="req-validation">
                {' '}
                {name_ar_validation
                  ? name_ar_validation.msg
                    ? name_ar_validation.msg
                    : null
                  : null}
              </span>
            </Colxx>
          </Colxx>

          <Colxx xxs="12" className="mb-3 p-0">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.SqrImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.VendorModal = instance)}
              images={this.state.imageFile}
              ratio="1:1"
              size={{ width: 400, height: 400 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFile(data)
              }}
            />
            {/* <Thumb
                  key="cat-image"
                  className="almost-full-width "
                  file={imageFile}
                  src={imageFile ? imageFile : "/assets/img/noimage.png"}
                /> */}
            <span className="req-validation">
              {' '}
              {imageFile_validation
                ? imageFile_validation.msg
                  ? imageFile_validation.msg
                  : null
                : null}
            </span>
            {/* <Button
                  color="primary"
                  size="lg"
                  className="pointer mt-2 col-12"
                  style={{
                    padding: "5px 16px",
                  }}
                >
                  <Label
                    className="btn btn-default no-padding col-12"
                    style={{
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <small>Add Square Image</small>
                    <input
                      className="col-12 p-0 "
                      id="image"
                      name="image"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      onChange={(event) => {
                        changeImageFile(event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                  </Label>
                </Button> */}
          </Colxx>

          <Colxx xxs="12" className="mb-3 p-0">
            <p className="text-muted text-small mb-2">
              <IntlMessages id="pages.recImage" />
              <span className="req"> *</span>
            </p>
            <ImageUploader
              multiple={false}
              ref={(instance) => (this.VendorModal = instance)}
              images={this.state.imageFile}
              ratio="2:1"
              size={{ width: 400, height: 200 }}
              getImage={this.state.getImage}
              changeImageFile={(data) => {
                this.changeImageFileLandscape(data)
              }}
            />
            <span className="req-validation">
              {' '}
              {landscapeImageFile_validation
                ? landscapeImageFile_validation.msg
                  ? landscapeImageFile_validation.msg
                  : null
                : null}
            </span>
            {/* <Button
                    color="primary"
                    size="lg"
                    className="pointer mt-2 col-12"
                    style={{
                      padding: "5px 16px",
                    }}
                  >
                    <Label
                      className="btn btn-default no-padding col-12"
                      style={{
                        padding: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      <small>Add Rectangle Image</small>
                      <input
                        className="col-12 p-0 "
                        id="image"
                        name="image"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={(event) => {
                          changeLandscapeImageFile(
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </Label>
                  </Button> */}
          </Colxx>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            disabled={btnDisable}
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.handleSave()}
          >
            Save
          </Button>{' '}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            disabled={btnDisable}
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default CategoryModal
