import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Card, CardBody, CardText } from "reactstrap";
import { Colxx } from "../../../components/CustomBootstrap";
import { FormikSwitch } from "../../../components/FormValidations/FormikFields";
import TimeRangeSlider from "react-time-range-slider";
import IntlMessages from "../../../util/IntlMessages";

@inject("stores")
@observer
class DayList extends Component {
  @observable
  store = this.props.stores.bakeryDetail;

  @observable
  setting = this.props.stores.setting;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.store.getData(this.props.history);
  }

  renderCard = (data) => {
    return data.map((item, index) => {
      return (
        <Card key={index} className="mb-3 col-12 ">
          <CardBody className="d-flex align-items-center flex-wrap col-12">
            <CardText className="col-xl-2 col-md-12 m-xl-0 mb-md-2 truncate">
              {Object.keys(item)[0]}
            </CardText>

            <div className="col-8">
              <div className="col-12 d-flex">
                <p className="col-6 text-center">
                  {`Start Time : ${
                    item[Object.keys(item)[0]].time_from
                      ? item[Object.keys(item)[0]].time_from
                      : "00:00"
                  }`}
                </p>
                <p className="col-6 text-center">
                  {`End Time : ${
                    item[Object.keys(item)[0]].time_to
                      ? item[Object.keys(item)[0]].time_to
                      : "23:59"
                  }`}
                </p>
              </div>
              <TimeRangeSlider
                disabled={!item[Object.keys(item)[0]].available}
                format={24}
                maxValue={"23:59"}
                minValue={"00:00"}
                name={"time_range"}
                onChangeStart={() => this.store.changeStartHandler()}
                onChangeComplete={() => this.store.changeCompleteHandler()}
                onChange={(time) =>
                  this.store.timeChangeHandler(
                    time,
                    index,
                    Object.keys(item)[0]
                  )
                }
                step={15}
                value={{
                  start: item[Object.keys(item)[0]].time_from
                    ? item[Object.keys(item)[0]].time_from
                    : "00:00",
                  end: item[Object.keys(item)[0]].time_to
                    ? item[Object.keys(item)[0]].time_to
                    : "23:59",
                }}
              />
            </div>

            <div className=" align-items-center  col-2">
              <p className="text-muted text-small mb-2 text-center mx-auto">
                <IntlMessages id="pages.available" />
              </p>
              <FormikSwitch
                className="custom-switch custom-switch-primary-inverse mx-auto mt-4"
                name={`default`}
                value={item[Object.keys(item)[0]].available}
                onChange={() =>
                  this.store.handleIsAvailable(
                    index,
                    item[Object.keys(item)[0]].available,
                    Object.keys(item)[0]
                  )
                }
              />
            </div>
          </CardBody>
        </Card>
      );
    });
  };

  render() {
    return (
      <Fragment>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row className="col-12">
            <Colxx xxs="12">
              {this.store.delivery_days &&
                this.store.delivery_days &&
                this.renderCard(this.store.delivery_days)}
            </Colxx>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default DayList;
