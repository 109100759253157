import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'

export default class AddProductStore {
  @observable
  isLoading = false

  @observable
  arName = null

  @observable
  enName = null

  @observable
  price = null

  @observable
  enDescription = null

  @observable
  arDescription = null

  @observable
  imageFile = null

  @observable
  fillings = []

  @observable
  shapes = []

  @observable
  flavors = []

  @observable
  category = null

  @observable
  categoryId = null

  @observable
  categoriesList = []

  @observable
  shapeList = []

  @observable
  shapesId = []

  @observable
  flavorList = []

  @observable
  flavorsId = []

  @observable
  fillingList = []

  @observable
  fillingsId = []

  @observable
  layerList = []

  @observable
  layer = null

  @observable
  layerId = null

  @observable
  outerShellList = []

  @observable
  outerShell = null

  @observable
  outerShellId = null

  @observable
  bakeryList = []

  @observable
  bakery = null

  @observable
  bakeryId = null

  @observable
  servingList = []

  @observable
  serving = null

  @observable
  servingId = null

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  serving_validation = null

  @observable
  price_validation = null

  @observable
  category_validation = null

  @observable
  image_validation = null

  @observable
  bakery_validation = null

  @observable
  en_description_validation = null

  @action
  async validation(history, type, bakeryId) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.enName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.arName,
      require: true,
      type: 'string',
      min: 3,
    })

    this.en_description_validation = await checkInput({
      name: 'English Description',
      value: this.enDescription,
      require: true,
      type: 'string',
      min: 3,
    })

    this.category_validation = await checkInput({
      name: 'Category',
      value: this.category,
      require: true,
    })

    this.bakery_validation = await checkInput({
      name: 'Bakery',
      value: this.bakery,
      require: true,
    })
    this.price_validation = await checkInput({
      name: 'Price',
      value: this.price,
      require: true,
      type: 'number',
      minValue: '0',
    })
    this.serving_validation = await checkInput({
      name: 'Serving',
      value: this.serving,
      require: true,
    })

    this.image_validation = await checkInput({
      name: 'Image',
      value: this.imageFile,
      require: true,
    })
    if(type==="admin"){
       this.price_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.en_description_validation.res &&
      this.serving_validation.res &&
      this.category_validation.res &&
      this.image_validation.res &&
      this.bakery_validation.res &&
      this.handleSaveCake(history, type, bakeryId)
    }else{
      this.price_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.en_description_validation.res &&
      this.serving_validation.res &&
      this.category_validation.res &&
      this.image_validation.res &&
      this.handleSaveCake(history, type, bakeryId)
    }
   
  }

  @action
  async getDependencies(history) {
    this.isLoading = false

    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    this.data = value.data
    await value.data.categories.map((category) =>
      this.categoriesList.push({
        value: category.id,
        label: category.title,
      })
    )
    await value.data.flavors.map((flavor) =>
      this.flavorList.push({ value: flavor.id, label: flavor.name })
    )

    await value.data.outerShells.map((flavor) =>
      this.outerShellList.push({ value: flavor.id, label: flavor.name })
    )

    await value.data.bakeries.map((bakery) =>
      this.bakeryList.push({ value: bakery.id, label: bakery.name_en })
    )

    await value.data.shapes.map((shape) =>
      this.shapeList.push({ value: shape.id, label: shape.name })
    )

    await value.data.fillings.map((filling) =>
      this.fillingList.push({ value: filling.id, label: filling.name })
    )

    await value.data.layers.map((layer) =>
      this.layerList.push({ value: layer.id, label: layer.name })
    )

    await value.data.servings.map((item) =>
      this.servingList.push({ value: item.id, label: item.name })
    )

    this.isLoading = true
  }

  @action
  changeEnName(value) {
    this.enName = value
  }

  @action
  changeArName(value) {
    this.arName = value
  }

  @action
  changeEnDescription(value) {
    this.enDescription = value
  }

  @action
  changeArDescription(value) {
    this.arDescription = value
  }

  @action
  changePrice(value) {
    this.price = value
  }

  @action
  setFlavors(value) {
    this.flavors = value.selectedOption
  }

  @action
  setFillings(value) {
    this.fillings = value.selectedOption
  }

  @action
  setShapes(value) {
    this.shapes = value.selectedOption
  }

  @action
  setLayer(value) {
    this.layer = value.selectedOption
  }

  @action
  setServing(value) {
    this.serving = value.selectedOption
  }

  @action
  setCategory(value) {
    this.category = value.selectedOption
  }

  @action
  setOuterShell(value) {
    this.outerShell = value.selectedOption
  }

  @action
  setBakery(value) {
    this.bakery = value.selectedOption
  }

  // @action
  // changeImageFile(file) {
  //   this.imageFile = file
  // }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFile(file) {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  async handleSaveCake(history, type, bakeryId) {
    this.isLoading = false
    await this.fillings.map((item) => this.fillingsId.push(item.value))

    await this.flavors.map((item) => this.flavorsId.push(item.value))

    await this.shapes.map((item) => this.shapesId.push(item.value))
    const data = {
      name_en: this.enName,
      name_ar: this.arName,
      price: this.price,
      description_en: this.enDescription,
      image: this.imageFile,
      serving_id: this.serving.value,
      category_id: this.category.value,
      bakery_id: type==="admin"? this.bakery.value : bakeryId,
    }
    if (this.arDescription) {
      data.description_ar = this.arDescription
    }
    if (this.layer) {
      data.layer_id = this.layer.value
    }
  
    if (this.outerShell) {
      data.outershell_id = this.outerShell.value
    }
    if (this.fillingsId.length) {
      data.fillings = JSON.stringify(this.fillingsId)
    }

    if (this.shapesId.length) {
      data.shapes = JSON.stringify(this.shapesId)
    }

    if (this.flavorsId.length) {
      data.flavors = JSON.stringify(this.flavorsId)
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'add',
      formData,
      history
    )
    if (value) {
      await this.reset()
      this.isLoading = true
      history.push(`/app/product/detail/${value.data.id}`)
    } else {
      this.isLoading = true
    }
  }

  @action
  reset() {
  this.isLoading = false

  this.arName = null

  this.enName = null

  this.price = null

  this.enDescription = null

  this.arDescription = null

  this.imageFile = null

  this.fillings = []

  this.shapes = []

  this.flavors = []

  this.category = null

  this.categoryId = null

  this.categoriesList = []

  this.shapeList = []

  this.shapesId = []

  this.flavorList = []

  this.flavorsId = []

  this.fillingList = []

  this.fillingsId = []

  this.layerList = []

  this.layer = null

  this.layerId = null

  this.outerShellList = []

  this.outerShell = null

  this.outerShellId = null

  this.bakeryList = []

  this.bakery = null

  this.bakeryId = null

  this.servingList = []

  this.serving = null

  this.servingId = null

  this.en_name_validation = null

  this.ar_name_validation = null

  this.serving_validation = null

  this.price_validation = null

  this.category_validation = null

  this.image_validation = null

  this.bakery_validation = null

  this.en_description_validation = null

  }
}
