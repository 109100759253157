import React from "react";

export class RecThumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.file || typeof nextProps.file.name !== "string") {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };
      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    let { file, src, className } = this.props;
    let { thumb } = this.state;
    let alt = "";

    if (!file && !src) return null;
    if (file && typeof file.name == "string") {
      alt = file.name;
    } else {
      alt = "just a preview image";
      thumb = src;
    }
    className = "img-thumbnail mb-2 " + className;

    return (
      <div className="position-relative mb-2" style={{width:'100%', paddingTop:'50%'}}>
        <img
          src={thumb}
          alt={alt}
          className={className}
          style={{
            objectFit: "cover",
            borderRadius: "0.75rem",
            height: '100%',
            width: "100%",
            top:0,
            left:0,
            right:0,
            bottom:0,
            position:'absolute'
          }}
          key="cat-thumb"
        />
      </div>
    );
  }
}
