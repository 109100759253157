import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'

export default class CakeStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  cakeDetail = null

  @observable
  currentPage = 1

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = ['name_en', 'name_ar']

  @observable
  selectedOrderOption = { column: 'id', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'name_en', label: 'Title' },
    { column: 'name_en_asc', label: 'Title (Ascending)' },
    { column: 'id', label: 'Create Date' },
    { column: 'id_asc', label: 'Create Date (Ascending)' },
    { column: 'price', label: 'Price' },
    { column: 'price_asc', label: 'Price (Ascending)' },
  ]

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  bakeryList = []

  @observable
  categoryList = []

  @observable
  bakery = { value: 0, label: 'All' }

  @observable
  category = { value: 0, label: 'All' }

  @observable
  loadingDependencies = false

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
      filter: this.filter,
    }
    // const dependency = await
    !this.categoryList.length && this.getDependencies(history)
    // if (dependency) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getcakes',
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
    // }
  }

  @action
  async getDependencies(history) {
    this.loadingDependencies = true
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.categories.map((category) =>
        this.categoryList.push({
          value: category.id,
          label: category.title,
        })
      )
      this.categoryList.unshift({ value: 0, label: 'All' })

      await value.data.bakeries.map((item) =>
        this.bakeryList.push({ value: item.id, label: item.name_en })
      )
      this.bakeryList.unshift({ value: 0, label: 'All' })
      // return true;
      this.loadingDependencies = false
    }
  }
  @action
  async handleKeyPress(e, history) {
    if (e.key === 'Enter') {
      this.currentPage = 1
      this.offset = 0
      this.getData(history)
    }
  }

  @action
  handleChangeSearch(value) {
    this.search = value
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeEnName = (name) => {
    this.cakeDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.cakeDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }
  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  setCategory(value, history) {
    this.category = value.selectedOption
    if (this.category.label !== 'All') {
      this.filter = {
        bakery_id: this.bakery
          ? this.bakery.label !== 'All'
            ? this.bakery.value
            : null
          : null,
        category_id: this.category.value,
      }
    } else {
      this.filter = {
        bakery_id: this.bakery
          ? this.bakery.label !== 'All'
            ? this.bakery.value
            : null
          : null,
        category_id: null,
      }
    }
    this.currentPage = 1
    this.offset = 0
    // this.filter = {bakery_id :this.bakery ?  this.bakery.value: null , category_id : this.category.value }
    this.getData(history)
  }

  @action
  setBakery(value, history) {
    this.bakery = value.selectedOption
    if (this.bakery.label !== 'All') {
      this.filter = {
        bakery_id: this.bakery.value,
        category_id: this.category
          ? this.category.label !== 'All'
            ? this.category.value
            : null
          : null,
      }
    } else {
      this.filter = {
        bakery_id: null,
        category_id: this.category
          ? this.category.label !== 'All'
            ? this.category.value
            : null
          : null,
      }
    }
    this.currentPage = 1
    this.offset = 0
    // this.filter = {
    //   bakery_id: this.bakery.value,
    //   category_id: this.category ? this.category.value : null
    // };
    this.getData(history)
  }
  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  reset() {
    this.data = null
  
    this.pagination = 0
  
    this.cakeDetail = null
  
    this.currentPage = 1
  
    this.imageFile = null
  
    this.search = ''
  
    this.searchOn = ['name_en', 'name_ar']
  
    this.selectedOrderOption = { column: 'id', label: 'Create Date' }
  
    this.orderOptions = [
      { column: 'name_en', label: 'Title' },
      { column: 'name_en_asc', label: 'Title (Ascending)' },
      { column: 'id', label: 'Create Date' },
      { column: 'id_asc', label: 'Create Date (Ascending)' },
      { column: 'price', label: 'Price' },
      { column: 'price_asc', label: 'Price (Ascending)' },
    ]
  
    this.limit = 12
  
    this.offset = 0
  
    this.order = { id: -1 }
  
    this.filter = {}
  
    this.isLoading = true
  
    this.bakeryList = []
  
    this.categoryList = []
  
    this.bakery = { value: 0, label: 'All' }
  
    this.category = { value: 0, label: 'All' }
  
    this.loadingDependencies = false
  }
}
