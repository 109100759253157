import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BakeryDetail from './detail';



const BakeryInfo = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} /> */}
            {/* <Route path={`${match.url}/list`} component={BakeryList} /> */}
            <Route path={`${match.url}`} component={BakeryDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default BakeryInfo;