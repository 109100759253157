import React, { Component, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  CardBody,
  CardText,
  Input,
  Button,
  CardImg,
  CardHeader,
  CardTitle,
} from "reactstrap";
import ItemModal from "../Modal/ItemModal";
import IntlMessages from "../../util/IntlMessages";
import { Colxx, Separator } from "../CustomBootstrap";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import CollectionModal from "../Modal/collectionModal";
import SelectInput from "../select";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

@inject("stores")
@observer
class DndCard extends Component {
  @observable
  store = this.props.stores.customHome;

  constructor(props) {
    super(props);
    this.state = { items: null, collectionId: null };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    this.setState({ items: this.store.layout });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.data });
  }

  async onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = await reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      items,
    });
    this.store.changeLayout(this.state.items);
  }

  changeTitleEn = (value) => {
    let data = [...this.state.items];
    data.forEach(item => {
       if (item.key === "vendor") {
        item.title_en = value;
      }
    });
    this.setState({ items: data });
  };

  changeTitleAr = (value) => {
    let data = [...this.state.items];
    data.forEach((item) => {
      if (item.key === "vendor") {
        item.title_ar = value;
      }
    });
    this.setState({ items: data });
  };


  setBakeryOption(value) {
    let data = [...this.state.items];
    data.forEach((item) => {
      if (item.key === "vendor") {
        item.type = value.selectedOption;
      }
    });
    this.setState({ items: data });

  }

  getCategory = () => {
    this.store.getCategory(this.props.history);
    this.store.handleChangeCategoryModal();
  };

  getCollection = (item) => {
    this.setState({ collectionId: item.info.id });
    this.store.handleChangeCollectionModal();
  };

  removeCategory =(value) => {
    const categories=[]
    let data = [...this.state.items];
        data.forEach( async(item) => {
      if (item.key === 'category') {
        await item.data.forEach(row=>{
          if(row.id!== value.id){
            categories.push(row)
          }
        })
        item.data=categories
      }
    })
    this.store.addItemToCategoryList(categories)
    this.setState({ items: data });
  }


  render() {
    return (
      <Fragment>
        {this.state.items ? (
          <DragDropContext onDragEnd={this.onDragEnd}>
            {this.store.openCategoryModal ? (
              <CollectionModal
                openModal={this.store.openCategoryModal}
                data={this.store.category}
                loading={this.store.isLoadingCategory}
                toggleModal={() => this.store.toggleCategoryModal()}
                pagination={this.store.paginationCategory}
                limit={this.store.limitCategory}
                currentPage={this.store.currentPageCategory}
                changePage={(e) => this.store.changePageCategory(e)}
                target="category"
                addItems={(data) => this.store.addItemToCategoryList(data)}
                selectedItems={this.store.selectedCategory}
              ></CollectionModal>
            ) : null}
            {this.store.openCollectionModal ? (
              <ItemModal
                openModal={this.store.openCollectionModal}
                toggleModal={() => this.store.toggleCollectionModal()}
                target={"collection"}
                data={this.store.collection}
                addItems={(data, target, id) => {
                  this.store.addItemToCollectionList(data, target, id);
                }}
                isMulti={false}
                // selected={this.state.collectionId}
                selectedList={this.store.collectionsId}
              ></ItemModal>
            ) : null}
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div style={{ position: "relative" }}>
                            <Card
                              className="mb-2"
                              style={{ overflow: "unset" }}
                            >
                              <CardHeader className="d-flex justify-content-between align-items-center pr-4 pl-4 pt-2 pb-2">
                                <CardTitle
                                  className="font-weight-bold m-0 text-center"
                                  style={{
                                    height: "max-content",
                                    fontSize: 20,
                                  }}
                                >
                                  {item.key}
                                </CardTitle>
                                <Button
                                  color="primary"
                                  className="btn-shadow p-0 m-0 text-center"
                                  onClick={() => this.store.removeItem(item)}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: 20 }}
                                    className="iconsminds-close pointer p-0 m-0"
                                  ></i>
                                </Button>
                              </CardHeader>
                              <Separator></Separator>
                              <CardBody className="p-4">
                                {item.key === "category" ? (
                                  <Colxx xxs="12" className="p-0">
                                    <div className="d-flex flex-wrap col-12">
                                      {item.data.map((item, index) => (
                                        <Card
                                          key={index + 1}
                                          className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                          style={{
                                            width: "max-content",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div
                                              className=" p-0 m-0"
                                              style={{ width: "40px" }}
                                            >
                                              <div
                                                className="position-relative col-12 m-0"
                                                style={{ paddingTop: "100%" }}
                                              >
                                                <CardImg
                                                  src={
                                                    item.image
                                                      ? item.image.url
                                                      : "/assets/img/noimage.png"
                                                  }
                                                  style={{
                                                    borderRadius: "50%",
                                                  }}
                                                ></CardImg>
                                              </div>
                                            </div>
                                            <CardText className="text-small pl-2 pr-2 m-0">
                                              {item.name}
                                            </CardText>
                                            <i
                                              className="iconsminds-close remove-icon"
                                              onClick={() =>
                                                this.removeCategory(item)
                                              }
                                            ></i>
                                          </div>
                                        </Card>
                                      ))}
                                    </div>
                                    <Colxx xxs="12" className="p-0 ">
                                      <Button
                                        color="primary"
                                        className="btn-shadow d-flex justify-content-center align-items-center p-0 float-sm-right "
                                        onClick={() => {
                                          this.getCategory();
                                        }}
                                        style={{ height: 40, width: 70 }}
                                      >
                                        {" "}
                                        <IntlMessages id="pages.set" />
                                        <i className="iconsminds-add" />
                                      </Button>
                                    </Colxx>
                                  </Colxx>
                                ) : null}

                                {item.key === "featurette" ? (
                                  <Colxx xxs="12" className="p-0">
                                    <div className="d-flex flex-wrap col-12">
                                      {item.data.map((item, index) => (
                                        <Card
                                          key={index + 1}
                                          className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                          style={{
                                            width: "max-content",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div
                                              className=" p-0 m-0"
                                              style={{ width: "40px" }}
                                            >
                                              <div
                                                className="position-relative col-12 m-0"
                                                style={{ paddingTop: "100%" }}
                                              >
                                                <CardImg
                                                  src={
                                                    item.image
                                                      ? item.image.url
                                                      : "/assets/img/noimage.png"
                                                  }
                                                  style={{
                                                    borderRadius: "50%",
                                                  }}
                                                ></CardImg>
                                              </div>
                                            </div>
                                            <CardText className="text-small pl-2 pr-2 m-0">
                                              {item.name}
                                            </CardText>
                                          </div>
                                        </Card>
                                      ))}
                                    </div>
                                  </Colxx>
                                ) : null}

                                {item.key === "vendor" ? (
                                  <Colxx xxs="12" className="p-0">
                                    <div className="d-flex flex-wrap">
                                      <Colxx xxs="12" lg="6" className="mb-3">
                                        <p className="text-muted text-small mb-2">
                                          <IntlMessages id="pages.name" />
                                          <span className="req"> *</span>
                                        </p>
                                        <div className="mb-0">
                                          <Input
                                            name="name"
                                            value={
                                              item.title_en ? item.title_en : ""
                                            }
                                            type="text"
                                            onChange={(e) =>
                                              this.changeTitleEn(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Colxx>
                                      <Colxx xxs="12" lg="6" className="mb-3">
                                        <p className="text-muted text-small mb-2">
                                          <IntlMessages id="pages.name-ar" />
                                          <span className="req"> *</span>
                                        </p>
                                        <div className="mb-0">
                                          <Input
                                            name="name_ar"
                                            value={
                                              item.title_ar ? item.title_ar : ""
                                            }
                                            type="text"
                                            onChange={(e) =>
                                              this.changeTitleAr(e.target.value)
                                            }
                                            className="ar"
                                          />
                                        </div>
                                      </Colxx>
                                      <Colxx
                                        xxs="12"
                                        lg="6"
                                        className="mb-3"
                                        style={{ overflow: "unset" }}
                                      >
                                        <p className="text-muted text-small mb-2">
                                          <IntlMessages id="pages.type" />
                                          <span className="req"> *</span>
                                        </p>
                                        <div className="mb-0">
                                          <SelectInput
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            value={item.type ? item.type:null
                                              // this.store.selectedBakeryOption
                                              //   ? this.store
                                              //       .selectedBakeryOption
                                              //   : null
                                            }
                                            onChange={(value) =>
                                              this.setBakeryOption(value)
                                            }
                                            options={this.store.bakeryOption}
                                            isMulti={false}
                                          ></SelectInput>
                                        </div>
                                      </Colxx>
                                    </div>
                                  </Colxx>
                                ) : null}

                                {item.key === "collection" ? (
                                  <Colxx xxs="12" className="p-0">
                                    <div className="d-flex flex-wrap">
                                      <Colxx xxs="12" lg="6" className="mb-3">
                                        <p className="text-muted text-small mb-2">
                                          <IntlMessages id="pages.name" />
                                        </p>
                                        <p className="mb-0">
                                          {item.title_en ? item.title_en : ""}
                                        </p>
                                      </Colxx>
                                      <Colxx xxs="12" lg="6" className="mb-3">
                                        <p className="text-muted text-small mb-2">
                                          <IntlMessages id="pages.name-ar" />
                                        </p>
                                        <p className="mb-0">
                                          {item.title_ar ? item.title_ar : ""}
                                        </p>
                                      </Colxx>
                                    </div>
                                    <Colxx xxs="12" className="p-0 mt-3">
                                      {/* <Button
                                        color="primary"
                                        className="btn-shadow d-flex justify-content-center align-items-center p-0 float-sm-right "
                                        onClick={() => {
                                          this.getCollection(item);
                                        }}
                                        style={{ height: 40, width: 70 }}
                                      >
                                        {" "}
                                        <IntlMessages id="pages.set" />
                                        <i className="iconsminds-add" />
                                      </Button> */}
                                    </Colxx>
                                  </Colxx>
                                ) : null}
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
      </Fragment>
    );
  }
}

export default DndCard;
