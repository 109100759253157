import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UsersList from './list';
import UsersDetail from './detail';



const Users = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={UsersList} />
            <Route path={`${match.url}/detail/:id`} component={UsersDetail} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Users;