import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'

export default class RequireStore {

    @observable
    hasData=false

  @observable
  categoriesList = []

  @observable
  bakeriesList = []

  @action
  async getDependencies (history) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.categories.map((category) =>
        this.categoriesList.push({
          value: category.id,
          label: category.title,
        })
      )

      await value.data.bakeries.map((item) =>
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      )
    }

    this.hasData=true
  }
}
