import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import CardImageList from '../../../components/Card/cardImageList'
import Pagination from '../../../components/List/Pagination'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DeleteModal from '../../../components/Modal/deleteModal'
import GalleryModal from '../../../components/Modal/galleryModal'

@inject('stores')
@observer
class GalleryList extends Component {
  @observable
  store = this.props.stores.gallery

  @observable
  login = this.props.stores.login

  @observable
  require = this.props.stores.require

  @observable
  setting = this.props.stores.setting
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    this.store.getData(this.props.history)
    // if (!this.require.hasData) {
    //   this.require.getDependencies(this.props.history)
    // }
  }

  componentWillUnmount() {
    this.store.reset()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.gallery" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => this.store.changeOpenModal()}
            >
              <IntlMessages id="pages.add-new" />
            </Button>
            <GalleryModal
              openModal={this.store.openModal}
              toggleModal={() => this.store.toggleModal()}
              imageFile={this.store.imageFile}
              changeImageFile={(file) => this.store.changeImageFile(file)}
              handleSave={() =>
                this.store.validation(this.props.history, this.login.userType)
              }
              btnDisable={this.store.btnDisable}
              imageFile_validation={this.store.imageFile_validation}
              bakeriesList={this.store.bakeriesList}
              selectedBakery={this.store.selectedBakery}
              setBakery={(data) => this.store.setBakery(data)}
              bakery_validation={this.store.bakery_validation}
              userType={this.login.userType}
            ></GalleryModal>
            <Row className="col-12 justify-content-start p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-block d-md-inline-block ">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.filterby" />
                      {this.store.selectedFilterOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.filterOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeFilterBy(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12">
                <DeleteModal
                  target={`Selected Image`}
                  openModal={this.store.openDeleteModal}
                  toggleModal={() => this.store.toggleDeleteModal()}
                  action={() =>
                    this.store.handleDeleteImage(
                      this.state.id,
                      this.props.history
                    )
                  }
                ></DeleteModal>
                <Row>
                  {this.store.data
                    ? this.store.data.map((item) => (
                        <CardImageList
                          data={item}
                          // openImageViewer={() =>
                          //   this.store.handleOpenImageViewer()
                          // }
                          key={item.id}
                          hasBadge={true}
                          deleteImage={(id) => {
                            this.setState({ id: id })
                            this.store.changeOpenDeleteModal()
                          }}
                        />
                      ))
                    : null}
                </Row>
              </Colxx>
            </Row>

            {this.store.pagination > 1 ? (
              <Pagination
                totalPage={this.store.pagination}
                numberLimit={this.store.limit}
                onChangePage={(e) => this.store.changePage(e)}
                currentPage={this.store.currentPage}
              />
            ) : null}
          </>
        )}
      </Fragment>
    )
  }
}

export default GalleryList
