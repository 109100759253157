import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import { successNotify } from '../util/Notify'

export default class CategoryStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  categoryDetail = null

  @observable
  imageFile = null

  @observable
  landscapeImageFile = null

  @observable
  imageFile_validation = null

  @observable
  landscapeImageFile_validation = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = false

  @observable
  openModal = false

  @observable
  openDeleteModal = false

  @observable
  showType = 'row'

  @observable
  newArName = null

  @observable
  ar_name_validation = null

  @observable
  newEnName = null

  @observable
  en_name_validation = null

  @observable
  btnDisable = false

  @observable
  successAction = false

  @observable
  successMessage = ''

  @observable
  showUploaderSqr = true

  @observable
  showUploaderRec = true

  @action
  handleImageUploaderSqr = () => {
    this.showUploaderSqr = !this.showUploaderSqr
  }

  @action
  handleImageUploaderRec = () => {
    this.showUploaderRec = !this.showUploaderRec
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderSqr(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderSqr = !this.showUploaderSqr
  }


  @action
  async changeImageFileUploderRec(file) {
    this.landscapeImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderRec = !this.showUploaderRec
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.request(
      'category',
      false,
      false,
      false,
      'post',
      'list',
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getCategoryDetail = async (id, history) => {
    this.isLoading = false
    this.imageFile = null
    this.landscapeImageFile = null
    const value = await axiFetch.request(
      'category',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.categoryDetail = value.data.items
      this.isLoading = true
    } else {
      this.isLoading = true
    }
  }

  @action
  async deleteCategory(id, history) {
    this.isLoading = false
    let value = await axiFetch.request(
      'category',
      false,
      id,
      false,
      'get',
      'delete',
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/category')
      }, 1000)
    } else {
      this.isLoading = true
    }
  }

  @action
  async handleUpdateCategory(id, history) {
    this.isLoading = false
    let data = {
      title_en: this.categoryDetail.name_en,
      title_ar: this.categoryDetail.name_ar,
    }
    if (this.imageFile) {
      data.image = this.imageFile
    }
    if (this.landscapeImageFile) {
      data.image_landscape = this.landscapeImageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    let value = await axiFetch.request(
      'category',
      false,
      id,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.isLoading = true
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getCategoryDetail(id, history)
    } else {
      this.isLoading = true
    }
  }

  @action
  async validation(history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.newEnName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.newArName,
      require: true,
      type: 'string',
      min: 3,
    })
    this.imageFile_validation = await checkInput({
      name: 'Square Image',
      value: this.imageFile,
      require: true,
    })
    this.landscapeImageFile_validation = await checkInput({
      name: 'Rectangle Image',
      value: this.landscapeImageFile,
      require: true,
    })
    this.imageFile_validation.res &&
      this.landscapeImageFile_validation.res &&
      this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleSaveNewCategory(history)
  }

  @action
  async updateValidation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.categoryDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.categoryDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateCategory(id, history)
  }

  @action
  async handleSaveNewCategory(history) {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      title_en: this.newEnName,
      title_ar: this.newArName,
      image: this.imageFile,
      image_landscape: this.landscapeImageFile,
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])
    const value = await axiFetch.request(
      'category',
      false,
      false,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.isLoading = false
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/category/detail/${value.data.id}`)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.newArName = null
    this.newEnName = null
    this.imageFile = null
    this.en_name_validation = null
    this.ar_name_validation = null
    this.imageFile_validation = null
    this.landscapeImageFile_validation = null
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeLandscapeImageFile = (file) => {
    this.landscapeImageFile = this.base64ToBlob(file, 'image/jpg')
  }

  @action
  changeEnName = (name) => {
    this.categoryDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.categoryDetail.name_ar = name
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }
  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.searchOn = ['title_en']
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
  this.data = null

  this.pagination = 0

  this.currentPage = 1

  this.categoryDetail = null

  this.imageFile = null

  this.landscapeImageFile = null

  this.imageFile_validation = null

  this.landscapeImageFile_validation = null

  this.search = ''

  this.searchOn = null

  this.limit = 12

  this.offset = 0

  this.order = { id: -1 }

  this.filter = {}

  this.isLoading = false

  this.openModal = false

  this.openDeleteModal = false

  this.showType = 'row'

  this.newArName = null

  this.ar_name_validation = null

  this.newEnName = null

  this.en_name_validation = null

  this.btnDisable = false

  this.successAction = false

  this.successMessage = ''

  this.showUploaderSqr = true

  this.showUploaderRec = true
  }
}
