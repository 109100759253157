export default {
  category: {
    post: { list: "list" },
    get: { delete: "delete" },
  },

  cake: {
    post: {
      getDependencies: "getDependencies",
      add: "add",
      getcakes: "getcakes",
    },
    get: { delete: "delete" },
  },

  featurette: {
    post: { list: "list", keys: "keys", add: "add" },
    get: { delete: "delete" },
  },

  coupon: {
    post: { list: "list", add: "add" },
    get: { delete: "delete" },
  },

  dashboard: {
    post: {
      list: "list",
      add: "add",
      monthly: "monthly_orders",
      year: "year_orders",
    },
    get: { delete: "delete" },
  },
};
