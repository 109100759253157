import { observable, action } from 'mobx'
import moment from 'moment'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify, errorNotify } from '../util/Notify'

export default class BakeryDetailStore {
  @observable
  bakeryDetail = null

  @observable
  bakeryArea = null

  @observable
  delivery_days = null

  @observable
  imageFile = null

  @observable
  coverImageFile = null

  @observable
  listOfDay = moment.weekdays()

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 10

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  en_name_validation = null

  @observable
  ar_name_validation = null

  @observable
  is_available_validation = null

  @observable
  pre_order_validation = null

  @observable
  address_validation = null

  @observable
  ar_address_validation = null

  @observable
  phone_validation = null

  @observable
  imageFile_validation = null

  @observable
  coverImageFile_validation = null

  @observable
  bakeryAreaList = []

  @observable
  selectedBakeryArea = null

  @observable
  showUploaderMain = true

  @observable
  showUploaderCover = true

  @action
  handleImageUploaderMain = () => {
    this.showUploaderMain = !this.showUploaderMain
  }

  @action
  handleImageUploaderCover = () => {
    this.showUploaderCover = !this.showUploaderCover
  }

  @action
  base64ToBlob(base64, mime) {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = window.atob(base64)
    var byteArrays = []

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mime })
  }

  @action
  async changeImageFileUploderMain(file) {
    this.imageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderMain = !this.showUploaderMain
  }

  @action
  async changeImageFileUploderCover(file) {
    this.coverImageFile = await this.base64ToBlob(file, 'image/jpg')
    this.showUploaderCover = !this.showUploaderCover
  }

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: 'English Name',
      value: this.bakeryDetail.name_en,
      require: true,
      type: 'string',
      min: 3,
    })
    this.ar_name_validation = await checkInput({
      name: 'Arabic Name',
      value: this.bakeryDetail.name_ar,
      require: true,
      type: 'string',
      min: 3,
    })

    this.address_validation = await checkInput({
      name: 'Address',
      value: this.bakeryDetail.address,
      require: true,
      type: 'string',
      min: 10,
    })

    this.ar_address_validation = await checkInput({
      name: 'Arabic Address',
      value: this.bakeryDetail.address_ar,
      require: true,
      type: 'string',
      min: 10,
    })

    this.phone_validation = await checkInput({
      name: 'Phone Number',
      value: this.bakeryDetail.phone,
      require: true,
      type: 'number',
      min: 8,
    })

    this.imageFile_validation = await checkInput({
      name: 'Image',
      value: this.imageFile ? this.imageFile : this.bakeryDetail.image,
      require: true,
    })

    this.coverImageFile_validation = await checkInput({
      name: 'Cover Image',
      value: this.coverImageFile
        ? this.coverImageFile
        : this.bakeryDetail.cover_image,
      require: true,
    })

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.address_validation.res &&
      this.ar_address_validation.res &&
      this.phone_validation.res &&
      this.imageFile_validation.res &&
      this.coverImageFile_validation.res &&
      this.handleUpdateBakery(id, history)
  }

  @action
  getBakeryDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'bakery',
      false,
      id,
      false,
      'get',
      false,
      {},
      history
    )
    if (value) {
      await this.getBakeryArea(id, history)
      this.bakeryDetail = value.data
      let delivery_days = []
      delivery_days = value.data.delivery_days
        ? Object.keys(value.data.delivery_days).map((key) => ({
            [key]: value.data.delivery_days[key],
          }))
        : {
            sunday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            monday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            tuesday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            wednesday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            thursday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            friday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
            saturday: {
              time_from: '00:00',
              time_to: '23:59',
              available: true,
            },
          }

      this.delivery_days = delivery_days
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  getBakeryArea = async (id, history) => {
    const value = await axiFetch.request(
      'bakery/areas',
      false,
      `list?bakery_id=${id}`,
      false,
      'get',
      false,
      {},
      history
    )

    if (value) {
      this.bakeryArea = value.data
      this.bakeryAreaList = []
      this.bakeryArea.forEach((item) => {
        if (item.parent_id) {
          if (!item.is_supported) {
            return this.bakeryAreaList.push({
              value: item.id,
              label: item.name_en,
            })
          }
        }
      })
    } else {
      this.isLoading = false
    }
  }

  @action
  setBakery(value) {
    this.selectedBakeryArea = value.selectedOption
    this.bakeryArea.forEach((area) => {
      if (area.id === value.selectedOption.value) {
        area.is_supported = !area.is_supported
      }
    })
  }

  @action
  deleteBakery = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'bakery',
      false,
      `${id}/delete`,
      false,
      'get',
      false,
      {},
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify('Item has been deleted successfully')
      setTimeout(() => {
        history.push('/app/bakery')
      }, 1000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateBakery = async (id, history) => {
    this.isLoading = true
    var result = {}
    for (var i = 0; i < this.delivery_days.length; i++) {
      result[Object.keys(this.delivery_days[i])[0]] = this.delivery_days[i][
        Object.keys(this.delivery_days[i])[0]
      ]
    }
    let data = {
      name_en: this.bakeryDetail.name_en,
      name_ar: this.bakeryDetail.name_ar,
      is_available: this.bakeryDetail.is_available,
      custom_cake_available: this.bakeryDetail.custom_cake_available,
      pre_order: this.bakeryDetail.pre_order === 'Available' ? true : false,
      address_en: this.bakeryDetail.address,
      address_ar: this.bakeryDetail.address_ar,
      phone: this.bakeryDetail.phone,
      delivery_days: JSON.stringify(result),
    }
    if (this.imageFile) {
      data.image = this.imageFile
    }
    if (this.coverImageFile) {
      data.cover_image = this.coverImageFile
    }
    let formData = new FormData()
    for (let key in data) formData.append(key, data[key])

    const value = await axiFetch.request(
      'bakery',
      false,
      `update?bakery_id=${id}`,
      false,
      'post',
      false,
      formData,
      history
    )
    if (value) {
      this.imageFile = null
      this.coverImageFile = null
      successNotify('Item has been updated successfully')
      this.getBakeryDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  areaValidation = (id, history) => {
    let priceValidation = false
    let timeValidation = false

    this.bakeryArea.forEach((area) => {
      if (area.is_supported) {
        !area.price && (priceValidation = true)
        !area.delivery_time && (timeValidation = true)
      }
    })
    if (priceValidation) {
      errorNotify('Please fill all the price fields.')
    } else if (timeValidation) {
      errorNotify('Please fill all the delivery fields.')
    } else {
      this.handleUpdateBakeryArea(id, history)
    }
  }

  @action
  handleUpdateBakeryArea = async (id, history) => {
    this.isLoading = true
    var areas = []
    this.bakeryArea.forEach((area) => {
      if (area.is_supported) {
        areas = [
          ...areas,
          {
            area_id: area.id,
            price: area.price,
            delivery_time: area.delivery_time,
          },
        ]
      }
    })

    const body = { areas }
    const value = await axiFetch.request(
      'bakery',
      false,
      `areas?bakery_id=${id}`,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      this.selectedBakeryArea = null
      await this.getBakeryArea(id, history)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action
  changeImageFile = (file) => {
    this.imageFile = file
  }

  @action
  changeCoverImageFile = (file) => {
    this.coverImageFile = file
  }

  @action
  changeEnName = (name) => {
    this.bakeryDetail.name_en = name
  }

  @action
  changeArName = (name) => {
    this.bakeryDetail.name_ar = name
  }

  @action
  changeAddress = (value) => {
    this.bakeryDetail.address = value
  }

  @action
  changeArAddress = (value) => {
    this.bakeryDetail.address_ar = value
  }

  @action
  changePhone = (number) => {
    this.bakeryDetail.phone = number
  }

  @action
  changeDelivery = (number) => {
    this.bakeryDetail.delivery = number
  }

  @action
  changeNewEnName = (name) => {
    this.newEnName = name
  }

  @action
  changeNewArName = (name) => {
    this.newArName = name
  }

  @action
  handleChangePreOrder(value) {
    this.bakeryDetail.pre_order =
      value === 'Available' ? 'UnAvailable' : 'Available'
  }

  @action
  handleChangeIsAvailable(value) {
    this.bakeryDetail.is_available = !value
  }

  @action
  handleChangeCustomCakeAvailable = (value) => {
    this.bakeryDetail.custom_cake_available = !value
  }

  @action
  handleIsAvailable(index, value, day) {
    this.delivery_days[index][day].available = !value
  }

  @action
  handleIsSupported(id) {
    this.bakeryArea.forEach((area) => {
      if (area.id === id) {
        area.is_supported = !area.is_supported
      }
    })
  }

  @action
  handleChangeTime(id, value) {
    this.bakeryArea.forEach((area) => {
      if (area.id === id) {
        area.delivery_time = value
      }
    })
  }

  @action
  handleChangePrice(id, value) {
    this.bakeryArea.forEach((area) => {
      if (area.id === id) {
        area.price = value
      }
    })
  }

  @action
  changeCompleteHandler() {}

  @action
  changeStartHandler() {}

  @action
  timeChangeHandler(time, index, day) {
    this.delivery_days[index][day].time_from = time.start
    this.delivery_days[index][day].time_to = time.end
  }

  @action
  reset() {
    this.bakeryDetail = null

    this.bakeryArea = null

    this.delivery_days = null

    this.imageFile = null

    this.coverImageFile = null

    this.listOfDay = moment.weekdays()

    this.search = null

    this.searchOn = null

    this.limit = 10

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openDeleteModal = false

    this.en_name_validation = null

    this.ar_name_validation = null

    this.is_available_validation = null

    this.pre_order_validation = null

    this.address_validation = null

    this.ar_address_validation = null

    this.phone_validation = null

    this.imageFile_validation = null

    this.coverImageFile_validation = null

    this.bakeryAreaList = []

    this.selectedBakeryArea = null

    this.showUploaderMain = true

    this.showUploaderCover = true
  }
}
