import React, { Component, Fragment } from 'react'
import {
  Row,
  Card,
  CardBody,
  CardText,
  Button,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import CouponModal from '../../../components/Modal/couponModal'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import IntlMessages from '../../../util/IntlMessages'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import Pagination from '../../../components/List/Pagination'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classnames from 'classnames'

@inject('stores')
@observer
class CouponList extends Component {
  @observable
  store = this.props.stores.coupon
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.store.getData(this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }
  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.coupon" />}
              match={this.props.match}
            />
            <Button
              color="primary"
              className="btn-shadow float-right"
              size="lg"
              onClick={() => this.store.changeOpenModal()}
            >
              <IntlMessages id="pages.add-new" />
            </Button>
            <CouponModal
              openModal={this.store.openModal}
              toggleModal={() => this.store.toggleModal()}
              unit={this.store.unit}
              amount={this.store.amount}
              usage={this.store.usage}
              startDate={this.store.startDate}
              endDate={this.store.endDate}
              changeUsage={(data) => this.store.changeUsage(data)}
              changeUnit={(data) => this.store.changeUnit(data)}
              changeAmount={(data) => this.store.changeAmount(data)}
              changeStartDate={(data) => this.store.changeStartDate(data)}
              changeEndDate={(data) => this.store.changeEndDate(data)}
              btnDisable={this.store.btnDisable}
              handleSave={() => this.store.validation(this.props.history)}
              target="coupon"
              amount_validation={this.store.amount_validation}
              usage_validation={this.store.usage_validation}
              startDate_validation={this.store.startDate_validation}
              endDate_validation={this.store.endDate_validation}
            />
            <Row className="col-12 justify-content-end p-0 m-0 flex-wrap align-items-center">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12">
                <div className="d-block d-md-inline-block">
                  <UncontrolledDropdown className="mr-1 float-md-left btn-group mb-1">
                    <DropdownToggle caret color="outline-dark" size="xs">
                      <IntlMessages id="pages.orderby" />
                      {this.store.selectedOrderOption.label}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.store.orderOptions.map((order, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() =>
                              this.store.changeOrderByList(
                                order.column,
                                this.props.history
                              )
                            }
                          >
                            {order.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end float-lg-right  float-sm-none p-0">
                <div className="mb-3 col-xl-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center p-0">
                  <div className="search-sm d-inline-block m-sm-auto m-outo m-md-0 mr-1 mb-1 align-top p-0">
                    <input
                      type="text"
                      name="keyword"
                      id="search"
                      value={this.store.search}
                      placeholder={`Search Coupon Code`}
                      onChange={(e) =>
                        this.store.hendleChangeKey(e.target.value)
                      }
                      onKeyPress={(event) => this.store.handleKeyPress(event)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <>
            <Row>
              <Colxx xxs="12" className="pl-0 d-xl-block d-md-none">
                <div
                  className="col-12 p-2 d-flex align-items-center mb-3"
                  style={{ borderBottom: '1px solid gray' }}
                >
                  <div className="col-12 d-flex align-items-center">
                    <p
                      className={classnames(
                        'col-1 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'amount'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('amount')}
                    >
                      Amount
                    </p>
                    <p
                      className={classnames(
                        'col-3 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'code'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('code')}
                    >
                      Coupon Code
                    </p>

                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'start'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('start')}
                    >
                      Start date
                    </p>

                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'end'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('end')}
                    >
                      End date
                    </p>

                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'usage'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('usage')}
                    >
                      Usage
                    </p>
                    <p
                      className={classnames(
                        'col-2 p-2 m-0 pointer text-center',
                        {
                          'color-theme-1': this.store.selectedOrderOption.column.includes(
                            'remaining'
                          ),
                        }
                      )}
                      onClick={() => this.store.changeOrderBy('remaining')}
                    >
                      Remaining
                    </p>
                  </div>
                </div>
              </Colxx>
              <Colxx xxs="12 pl-0">
                {this.store.data.map((item) => (
                  <NavLink className="" key={item.id} to="#">
                    <Card className="col-xl-12 col-md-4 col-sm-12 pt-3 pb-3 pl-0 pr-0 pointer mb-3">
                      <CardBody className="d-flex  p-2 pl-0 pr-0 align-items-center flex-wrap">
                        <div className="col-xl-12 col-md-12 col-sm-12 d-flex align-items-xl-center align-items-md-start flex-wrap">
                          <CardText className="col-xl-1 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Amount :{' '}
                            </span>
                            {`${Number(item.amount)} ${item.unit}`}
                          </CardText>
                          <CardText className="col-xl-3 col-md-12  p-2 m-0  text-small truncate text-xl-center text-md-left ">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Coupon Code :{' '}
                            </span>
                            {item.code}
                          </CardText>
                          <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Start date :{' '}
                            </span>
                            {moment(item.start).format('MM/DD/YYYY')}
                          </CardText>
                          <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              End date :{' '}
                            </span>
                            {item.end
                              ? moment(item.end).format('MM/DD/YYYY')
                              : 'Limitless'}
                          </CardText>
                          <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              usage :{' '}
                            </span>
                            {item.usage}
                          </CardText>

                          <CardText className="col-xl-2 col-md-12 p-2 m-0 text-small truncate text-xl-center text-md-left">
                            <span className="d-xl-none d-md-inline font-weight-bold">
                              Remaining :{' '}
                            </span>
                            {item.remaining}
                          </CardText>
                        </div>
                      </CardBody>
                    </Card>
                  </NavLink>
                ))}
              </Colxx>
              {this.store.pagination > 1 ? (
                <Pagination
                  totalPage={this.store.pagination}
                  numberLimit={this.store.limit}
                  onChangePage={(e) =>
                    this.store.changePage(e, this.props.history)
                  }
                  currentPage={this.store.currentPage}
                />
              ) : null}
            </Row>
          </>
        )}
      </Fragment>
    )
  }
}

export default CouponList
