import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Colxx } from "../../components/CustomBootstrap";
import Loading from "../../components/Loading/index";

@inject("stores")
@observer
class ForgotPasswordLayout extends Component {
  @observable
  store = this.props.stores.login;

  constructor(props) {
    super(props);
    this.state = {
      email: "demo@gogo.com",
    };
  }

  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }
  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="8" lg="6" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id="user.forgot-password" />
                    </CardTitle>
                    <Form>
                      <Label className="form-group has-float-label mb-4">
                        <Input 
                         type="email"
                         value={this.store.forgetEmail}
                         onChange={e =>
                           this.store.changeForgetEmail(e.target.value)
                         }
                        />
                        <IntlMessages id="user.email" />
                      </Label>

                      <div className="d-flex justify-content-end align-items-center">
                        <Button
                          style={{ width: 128.33, height: 42.53 }}
                          color="primary"
                          className="btn-shadow"
                          size="lg"
                          onClick={() => this.store.setEmailPassword(this.props.history)}
                          disabled={this.store.btnDisable}
                        >
                          {!this.store.loginLoading ? (
                            <IntlMessages id="user.reset-password-button" />
                          ) : (
                            <Loading></Loading>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default ForgotPasswordLayout;
