import LoginStore from "./login.store";
import SettingStore from "./setting.store";
import MenuStore from "./menu.store";
import ProductStore from "./product.store";
import ProductDetailStore from "./productDetail.store";
import AddProductStore from "./addProduct.store";
import CategoryStore from "./category.store";
import BakeryDetailStore from "./bakeryDetail.store";
import BakeryStore from "./bakery.store";
import FillingDetailStore from "./fillingDetail.store";
import FillingStore from "./filling.store";
import FlavorDetailStore from "./flavorDetail.store";
import FlavorStore from "./flavor.store";
import ShapeDetailStore from "./shapeDetail.store";
import ShapeStore from "./shape.store";
import LayerDetailStore from "./layerDetail.store";
import LayerStore from "./layer.store";
import UserStore from "./user.store";
import UserDetailStore from "./userDetail.store";
import CustomerStore from "./customer.store";
import CustomerDetailStore from "./customerDetail.store";
import OuterShellStore from "./outerShell.store";
import OuterShellDetailStore from "./outerShellDetail.store";
import CustomCakeStore from "./customCake.store";
import GalleryStore from "./gallery.store";
import CustomHomeStore from "./customHome.store";
import CouponStore from "./coupon.store";
import OrderStore from "./order.store";
import CollectionStore from "./collection.store";
import CollectionDetailStore from "./collectionDetail.store";
import FeaturetteStore from "./featurette.store";
import FeaturetteDetailStore from "./featuretteDetail.store";
import DashboardStore from "./dashboard.store";
import ProfileStore from "./profile.store";
import RequireStore from './require.store'

const login = new LoginStore();
const setting = new SettingStore();
const menu = new MenuStore();
const product = new ProductStore();
const productDetail = new ProductDetailStore();
const addProduct = new AddProductStore();
const category = new CategoryStore();
const bakery = new BakeryStore();
const bakeryDetail = new BakeryDetailStore();
const filling = new FillingStore();
const fillingDetail = new FillingDetailStore();
const flavor = new FlavorStore();
const flavorDetail = new FlavorDetailStore();
const shape = new ShapeStore();
const shapeDetail = new ShapeDetailStore();
const layer = new LayerStore();
const layerDetail = new LayerDetailStore();
const user = new UserStore();
const userDetail = new UserDetailStore();
const customer = new CustomerStore();
const customerDetail = new CustomerDetailStore();
const outerShell = new OuterShellStore();
const outerShellDetail = new OuterShellDetailStore();
const customCake = new CustomCakeStore();
const gallery = new GalleryStore();
const customHome = new CustomHomeStore();
const coupon = new CouponStore();
const order = new OrderStore();
const collection = new CollectionStore();
const collectionDetail = new CollectionDetailStore();
const featurette = new FeaturetteStore();
const featuretteDetail = new FeaturetteDetailStore();
const dashboard = new DashboardStore();
const profile = new ProfileStore();
const require = new RequireStore();

export default {
  login,
  setting,
  menu,
  product,
  productDetail,
  addProduct,
  category,
  bakery,
  bakeryDetail,
  filling,
  fillingDetail,
  flavor,
  flavorDetail,
  shape,
  shapeDetail,
  layer,
  layerDetail,
  user,
  userDetail,
  customer,
  customerDetail,
  outerShell,
  outerShellDetail,
  customCake,
  gallery,
  customHome,
  coupon,
  order,
  collection,
  collectionDetail,
  featurette,
  featuretteDetail,
  dashboard,
  profile,
  require
};
