import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'

export default class UserStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  currentPage = 1

  @observable
  search = null

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  name = null

  @observable
  email = null

  @observable
  btnDisable = false

  @observable
  typesList = [
    { value: 1, label: 'admin' },
    { value: 2, label: 'bakery' },
    { value: 3, label: 'subBakery' },
  ]

  @observable
  selectedType = null

  @observable
  bakeriesList = []

  @observable
  selectedBakery = null

  @observable
  email_validation = null

  @observable
  name_validation = null

  @observable
  type_validation = null

  @observable
  bakery_validation = null

  @action
  async validation(history, type, bakeryId) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.email_validation = await checkInput({
      name: 'Email',
      value: this.email,
      require: true,
      type: 'email',
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && this.selectedType.label !== 'admin') {
      this.bakery_validation = await checkInput({
        name: 'Bakery',
        value: this.selectedBakery,
        require: true,
      })
    }

    if (type === 'admin') {
      if (this.selectedType && this.selectedType.label !== 'admin') {
        this.email_validation.res &&
          this.name_validation.res &&
          this.type_validation.res &&
          this.bakery_validation.res &&
          this.handleSaveNewAdmin(history, type, bakeryId)
      } else {
        this.email_validation.res &&
          this.name_validation.res &&
          this.type_validation.res &&
          this.handleSaveNewAdmin(history, type, bakeryId)
      }
    } else {
      this.email_validation.res &&
        this.name_validation.res &&
        this.handleSaveNewAdmin(history, type, bakeryId)
    }
  }

  @action
  getData = async (history) => {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.request(
      'admin/list',
      false,
      false,
      false,
      'post',
      false,
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.getDependencies(history)
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.bakeries.map((item) =>
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      )
    }
  }

  @action
  handleSaveNewAdmin = async (history, type, bakeryId) => {
    this.isLoading = true
    this.btnDisable = true
    let data = {
      name: this.name,
      email: this.email,
      type: type === 'admin' ? this.selectedType.label : 'subBakery',
      // bakery_id:
      //   this.selectedType.label !== 'admin' ? this.selectedBakery.value : null,
    }
    if (this.selectedType && this.selectedType.label !== 'admin') {
      data.bakery_id = this.selectedBakery.value
    } else if (type !== 'admin') {
      data.bakery_id = bakeryId
    }

    const value = await axiFetch.request(
      'admin',
      false,
      false,
      false,
      'post',
      false,
      data,
      history
    )
    if (value) {
      this.openModal = false
      this.btnDisable = false
      history.push(`/app/users/detail/${value.data.id}`)
    } else {
      this.btnDisable = false
      this.isLoading = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.name = null
    this.email = null
    this.selectedBakery = null
    this.selectedType = null
    this.name_validation = null
    this.email_validation = null
    this.type_validation = null
    this.bakery_validation = null
  }

  @action
  changeName = (value) => {
    this.name = value
  }

  @action
  changeEmail = (value) => {
    this.email = value
  }

  @action
  changePage(e) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData()
  }

  @action
  setBakery(value) {
    this.selectedBakery = value.selectedOption
  }

  @action
  setType(value) {
    this.selectedType = value.selectedOption
  }

  @action
  reset() {
    this.data = null

    this.newArName = null

    this.newEnName = null

    this.pagination = 0

    this.currentPage = 1

    this.imageFile = null

    this.search = null

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = false

    this.openModal = false

    this.name = null

    this.password = null

    this.email = null
  }
}
