import React, { Component } from 'react'
import {
  Modal,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardImg,
  CardText,
  ModalBody,
} from 'reactstrap'
import IntlMessages from '../../util/IntlMessages'
import { Separator } from '../CustomBootstrap'
import { Fragment } from 'react'
import Pagination from '../List/Pagination'

class CollectionModal extends Component {
  constructor(props) {
    super(props)
    this.state = { items: [], selected: [] }
  }
  async componentDidMount() {
    const data = []
    let selected = []
    if (this.props.selectedItems) {
      await this.props.selectedItems.forEach((item) => {
        item = { ...item, selected: true }
        selected.push(item)
      })
      await this.setState({ selected: [...selected] })
    }
    if (this.props.data) {
      await this.props.data.map(async (item) => {
        item = { ...item, selected: false }
        await this.state.selected.forEach((x) => {
          if (item.id === x.id) {
            item.selected = true
          }
        })
        data.push(item)
      })

      await this.setState({ items: [...data] })
    }
  }

  componentWillUnmount() {
    this.setState({ items: [] })
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const data = []
    await nextProps.data.forEach(async (item) => {
      item = { ...item, selected: false }
      await this.state.selected.forEach((x) => {
        if (item.id === x.id) {
          item.selected = true
        }
      })
      data.push(item)
    })

    await this.setState({ items: [...data] })
  }
  cancelCustomItem = () => {
    this.props.toggleModal()
  }

  selectedItem = async (value) => {
    let selected = [...this.state.selected]
    if (value.selected) {
      let arr = []
      await this.state.items.forEach((item) => {
        if (item.id === value.id) {
          item.selected = !item.selected
        }
      })
      await selected.forEach((item) => {
        if (item.id !== value.id) {
          arr.push(item)
        }
      })

      selected = arr
    } else {
      await this.state.items.forEach((item) => {
        if (item.id === value.id) {
          item.selected = !item.selected
          selected.push(item)
        }
      })
    }

    await this.setState({ items: this.state.items, selected: selected })
  }

  removeItem = (value) => {
    let selected = []
    let items = [...this.state.items]
    this.state.selected.forEach((item) => {
      if (item.id !== value.id) {
        selected.push(item)
      }
    })

    items.forEach((item) => {
      if (item.id === value.id) {
        item.selected = !item.selected
      }
    })
    this.setState({ selected: selected, items: items })
  }

  submitCustomItem = async () => {
    let data = []
    await this.state.selected.forEach((item) => {
      if (item.selected) {
        delete item.selected
        data.push(item)
      }
    })
    this.props.addItems(data)
    this.props.toggleModal()
  }

  render() {
    const { openModal, target, toggleModal } = this.props
    return (
      <Fragment>
        <Modal isOpen={openModal} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id={`menu.${target}`} />
          </ModalHeader>
          {this.props.loading && !this.state.selected.length ? (
            <ModalBody>
              <div className="loading"></div>
            </ModalBody>
          ) : (
            <ModalBody className="col-12 d-flex flex-wrap">
              <div
                className="col-9 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2 position-relative"
                style={{ height: 'max-content' }}
              >
                {this.props.loading && this.state.selected.length ? (
                  <div className="loading "></div>
                ) : (
                  <>
                    {this.state.items
                      ? this.state.items.map((item) => (
                          <div className="col-4 p-1 m-0">
                            <Card
                              onClick={() => this.selectedItem(item)}
                              className="p-1  m-0 d-flex align-items.center pointer col-12"
                              style={{
                                maxWidth: '100%',
                                border: item.selected
                                  ? '1px solid green'
                                  : '1px solid #ddd',
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className=" p-0 m-0 col-3"
                                  // style={{ width: "40px !important" }}
                                >
                                  <div
                                    className="position-relative col-12 m-0"
                                    style={{ paddingTop: '100%' }}
                                  >
                                    <CardImg
                                      src={
                                        this.props.target === 'product'
                                          ? item.images
                                            ? item.images[0].url
                                            : '/assets/img/noimage.png'
                                          : item.image
                                          ? item.image.url
                                          : '/assets/img/noimage.png'
                                      }
                                      className="position-absolute"
                                      style={{
                                        borderRadius: '50%',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                      }}
                                    ></CardImg>
                                  </div>
                                </div>
                                <CardText className="text-small pl-2 pr-2 m-0  text-truncate col-7">
                                  {item.name}
                                </CardText>
                                <i
                                  className={
                                    item.selected
                                      ? 'iconsminds-yes selected-icon col-2 p-0'
                                      : 'iconsminds-add add-icon col-2 p-0'
                                  }
                                ></i>
                              </div>
                            </Card>
                          </div>
                        ))
                      : null}
                    {this.props.pagination > 1 ? (
                      <Pagination
                        totalPage={this.props.pagination}
                        numberLimit={this.props.limit}
                        onChangePage={(e) => this.props.changePage(e)}
                        currentPage={this.props.currentPage}
                      />
                    ) : null}
                  </>
                )}
              </div>

              <div
                className="col-3 "
                style={{ borderLeft: '1px solid gray', padding: 12 }}
              >
                <CardText className="text-center">Selected Items</CardText>
                <Separator></Separator>
                {this.state.selected.map((item) => (
                  <div className="col-12 p-0 m-0 mb-2">
                    <Card
                      className="p-1  m-0 d-flex align-items.center pointer col-12"
                      style={{
                        maxWidth: '100%',
                        border: item.selected
                          ? '1px solid green'
                          : '1px solid #ddd',
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className=" p-0 m-0 col-3"
                          // style={{ width: "40px !important" }}
                        >
                          <div
                            className="position-relative col-12 m-0"
                            style={{ paddingTop: '100%' }}
                          >
                            <CardImg
                              src={
                                this.props.target === 'product'
                                  ? item.images
                                    ? item.images[0].url
                                    : '/assets/img/noimage.png'
                                  : item.image
                                  ? item.image.url
                                  : '/assets/img/noimage.png'
                              }
                              className="position-absolute"
                              style={{
                                borderRadius: '50%',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                              }}
                            ></CardImg>
                          </div>
                        </div>
                        <CardText className="text-small pl-2 pr-2 m-0  text-truncate col-7">
                          {item.name}
                        </CardText>
                        <i
                          className="iconsminds-close add-icon col-2 p-0"
                          onClick={() => this.removeItem(item)}
                        ></i>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </ModalBody>
          )}

          <ModalFooter className="d-flex justify-content-center">
            <Button
              color="primary"
              className="btn-shadow"
              size="lg"
              onClick={() => this.submitCustomItem()}
            >
              {`Save`}
            </Button>{' '}
            <Button
              className="btn-outline-secondary btn-lg btn-shadow"
              onClick={() => this.cancelCustomItem()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default CollectionModal
