import { observable, action } from 'mobx'
import axiFetch from '../config/fetch'
import { checkInput } from '../constants/validation'
import { successNotify } from '../util/Notify'

export default class UserDetailStore {
  @observable
  userDetail = null

  @observable
  imageFile = null

  @observable
  isLoading = true

  @observable
  openDeleteModal = false

  @observable
  typesList = [
    { value: 1, label: 'admin' },
    { value: 2, label: 'bakery' },
    { value: 3, label: 'subBakery' },
  ]

  @observable
  typesListMap = [
    { value: 1, label: 'admin' },
    { value: 2, label: 'bakery' },
    { value: 3, label: 'subBakery' },
  ]

  @observable
  selectedType = null

  @observable
  bakeriesList = []

  @observable
  selectedBakery = null

  @observable
  name_validation = null

  @observable
  type_validation = null

  @observable
  bakery_validation = null

  @action
  async validation(id, history, type) {
    this.name_validation = await checkInput({
      name: 'Name',
      value: this.userDetail.name,
      require: true,
      type: 'string',
      min: 3,
    })
    this.type_validation = await checkInput({
      name: 'Type',
      value: this.selectedType,
      require: true,
    })

    if (this.selectedType && this.selectedType.label !== 'admin') {
      this.bakery_validation = await checkInput({
        name: 'Bakery',
        value: this.selectedBakery,
        require: true,
      })
    }

    if (type === 'admin') {
      if (this.selectedType && this.selectedType.label !== 'admin') {
        this.name_validation.res &&
          this.type_validation.res &&
          this.bakery_validation.res &&
          this.handleUpdateUser(id, history, type)
      } else {
        this.name_validation.res &&
          this.type_validation.res &&
          this.handleUpdateUser(id, history, type)
      }
    } else {
      this.name_validation.res && this.handleUpdateUser(id, history, type)
    }
  }

  @action
  getUserDetail = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'admin',
      false,
      id,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.userDetail = value.data
      await this.getDependencies(history)
      await this.typesListMap.forEach((item) => {
        if ((item.label = value.data.type)) {
          this.selectedType = item
        }
      })
      this.isLoading = false
    }
  }

  @action
  deleteUser = async (id, history) => {
    this.isLoading = true
    const value = await axiFetch.request(
      'admin',
      false,
      `${id}/delete`,
      false,
      'get',
      false,
      null,
      history
    )
    if (value) {
      this.openDeleteModal = false
      successNotify(value.meta.msg)
      setTimeout(() => {
        history.push('/app/users/list')
      }, 2000)
    } else {
      this.isLoading = false
    }
  }

  @action
  handleUpdateUser = async (id, history, type) => {
    this.isLoading = true
    let data = {
      id: id,
      name: this.userDetail.name,
      type: this.selectedType.label,
    }
    if (this.selectedType.label !== 'admin') {
      data.bakery_id =
        type === 'admin'
          ? this.selectedBakery.value
          : this.userDetail.parent_id
          ? this.userDetail.parent_id
          : this.userDetail.type_id
    }

    const value = await axiFetch.request(
      'admin',
      false,
      id,
      'update',
      'post',
      false,
      data,
      history
    )
    if (value) {
      successNotify('Item has been updated successfully')
      this.getUserDetail(id, history)
    } else {
      this.isLoading = false
    }
  }

  @action
  async getDependencies(history) {
    const value = await axiFetch.request(
      'cake',
      false,
      false,
      false,
      'post',
      'getDependencies',
      null,
      history
    )
    if (value) {
      await value.data.bakeries.forEach((item) => {
        if (item.id === this.userDetail.type_id) {
          this.selectedBakery = { value: item.id, label: item.name_en }
        }
        this.bakeriesList.push({ value: item.id, label: item.name_en })
      })
    }
  }

  @action
  setBakery(value) {
    this.selectedBakery = value.selectedOption
  }

  @action
  setType(value) {
    this.selectedType = value.selectedOption
  }

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true
  }

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false
  }

  @action.bound
  changeName = (name) => {
    this.userDetail.name = name
  }

  @action
  reset() {
    this.userDetail = null

    this.imageFile = null

    this.isLoading = true

    this.openDeleteModal = false

    this.typesList = [
      { value: 1, label: 'admin' },
      { value: 2, label: 'bakery' },
      { value: 3, label: 'subBakery' },
    ]

    this.typesListMap = [
      { value: 1, label: 'admin' },
      { value: 2, label: 'bakery' },
      { value: 3, label: 'subBakery' },
    ]

    this.selectedType = null

    this.bakeriesList = []

    this.selectedBakery = null

    this.name_validation = null

    this.type_validation = null

    this.bakery_validation = null
  }
}
