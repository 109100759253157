import { observable, action } from 'mobx'
import { checkInput } from '../constants/validation'
import axiFetch from '../config/fetch'
import { successNotify } from "../util/Notify";

export default class CouponStore {
  @observable
  data = null

  @observable
  pagination = 0

  @observable
  selectedOrderOption = { column: 'id', label: 'Create Date' }

  @observable
  orderOptions = [
    { column: 'id', label: 'Create Date' },
    { column: 'id_asc', label: 'Create Date (Ascending)' },
    { column: 'amount', label: 'Amount' },
    { column: 'amount_asc', label: 'Amount (Ascending)' },
    { column: 'code', label: 'Code' },
    { column: 'code_asc', label: 'Code (Ascending)' },
    { column: 'start', label: 'Start Date' },
    { column: 'start_asc', label: 'Start Date (Ascending)' },
    { column: 'end', label: 'End Date' },
    { column: 'end_asc', label: 'End Date (Ascending)' },
    { column: 'usage', label: 'Usage' },
    { column: 'usage_asc', label: 'Usage (Ascending)' },
    { column: 'remaining', label: 'Remaining' },
    { column: 'remaining_asc', label: 'Remaining (Ascending)' },
  ]

  @observable
  btnDisable = false

  @observable
  currentPage = 1

  @observable
  bakeryDetail = null

  @observable
  imageFile = null

  @observable
  search = ''

  @observable
  searchOn = null

  @observable
  limit = 12

  @observable
  offset = 0

  @observable
  order = { id: -1 }

  @observable
  filter = {}

  @observable
  isLoading = true

  @observable
  openModal = false

  @observable
  unit = 'KD'

  @observable
  usage = 1

  @observable
  amount = 10

  @observable
  startDate = null

  @observable
  endDate = null

  @observable
  amount_validation = null

  @observable
  usage_validation = null

  @observable
  startDate_validation = null

  @observable
  endDate_validation = null

  @action
  changeUnit = (value) => {
    this.unit = value
  }

  @action
  changeCount = (value) => {
    this.count = value
  }

  @action
  changeAmount = (value) => {
    this.amount = value
  }

  @action
  changeAmountKd = (value) => {
    this.amount_kd = value
  }

  @action
  changeUsage = (value) => {
    this.usage = value
  }

  @action
  changeStartDate = (value) => {
    this.startDate = value
  }

  @action
  changeEndDate = (value) => {
    this.endDate = value
  }

  @action
  async validation(history) {
    this.amount_validation = null

    this.usage_validation = null

    this.startDate_validation = null

    this.endDate_validation = null

    if (this.endDate && this.startDate && this.endDate < this.startDate) {
      this.endDate_validation = {
        res: false,
        msg: "'End Date' Should Be Bigger Than 'Start Date'",
      }
      return
    }

    this.amount_validation = await checkInput({
      name: 'Amount',
      value: this.amount,
      require: true,
      type: 'number',
      minValue: 0,
      maxValue: this.unit === '%' && 100,
    })

    this.startDate_validation = await checkInput({
      name: 'Start Date',
      value: this.startDate,
      require: true,
    })

    this.endDate_validation = await checkInput({
      name: 'End Date',
      value: this.endDate,
      require: true,
    })
    this.usage_validation = await checkInput({
      name: 'Usage',
      value: this.usage,
      require: true,
      type: 'number',
      minValue: 0,
    })

    this.usage_validation.res &&
      this.amount_validation.res &&
      this.startDate_validation.res &&
      this.endDate_validation.res &&
      this.handleSaveNewCoupon(history)
  }

  @action
  async getData(history) {
    this.isLoading = true
    const body = {
      order: this.order,
      searchOn: this.searchOn,
      search: this.search,
      limit: this.limit,
      offset: this.offset,
    }
    const value = await axiFetch.request(
      'coupon',
      false,
      false,
      false,
      'post',
      'list',
      body,
      history
    )
    if (value) {
      this.data = value.data.items
      this.pagination =
        parseInt(value.data.pagination.total / this.limit) +
        (value.data.pagination.total % this.limit ? 1 : 0)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  }

  @action
  async handleSaveNewCoupon(history) {
    this.isLoading = true
    this.btnDisable = true
    let body = {
      unit: this.unit,
      usage: this.usage,
      amount: this.amount,
      start: this.startDate === '' ? null : this.startDate,
      end: this.endDate === '' ? null : this.endDate,
    }
    const value = await axiFetch.request(
      'coupon',
      false,
      false,
      false,
      'post',
      'add',
      body,
      history
    )
    if (value) {
      successNotify('Item has been added successfully')
      await this.reset()
      await this.toggleModal()
      this.getData(history)
    } else {
      this.isLoading = false
      this.btnDisable = false
    }
  }

  @action
  changeOpenModal = () => {
    this.openModal = true
  }

  @action
  toggleModal = () => {
    this.openModal = false
    this.amount = 10
    this.usage = 1
    this.unit = 'KD'
    this.endDate = null
    this.startDate = null
    this.amount_validation = null
    this.usage_validation = null
    this.startDate_validation = null
    this.endDate_validation = null
  }

  @action
  changePage(e, history) {
    this.offset = (e - 1) * 12
    this.currentPage = e
    this.getData(history)
  }

  @action
  hendleChangeKey = (value) => {
    this.search = value
  }

  @action
  handleKeyPress = (e, history) => {
    if (e.key === 'Enter') {
      this.searchOn = ['code']
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  changeOrderByList = async (column, history) => {
    this.selectedOrderOption = await this.orderOptions.find(
      (x) => x.column === column
    )
    this.order = {
      [this.selectedOrderOption.column.includes('_asc')
        ? this.selectedOrderOption.column.split('_asc')[0]
        : this.selectedOrderOption
            .column]: this.selectedOrderOption.column.includes('_asc') ? 1 : -1,
    }
    this.offset = 0
    this.currentPage = 1
    this.getData(history)
  }

  @action
  changeOrderBy = async (column, history) => {
    if (this.selectedOrderOption.column === column) {
      let target = column.includes('_asc')
        ? column.split('_asc')[0]
        : `${column}_asc`
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    } else {
      let target = column
      this.selectedOrderOption = await this.orderOptions.find(
        (x) => x.column === target
      )
      this.order = {
        [this.selectedOrderOption.column.includes('_asc')
          ? this.selectedOrderOption.column.split('_asc')[0]
          : this.selectedOrderOption
              .column]: this.selectedOrderOption.column.includes('_asc')
          ? 1
          : -1,
      }
      this.offset = 0
      this.currentPage = 1
      this.getData(history)
    }
  }

  @action
  reset() {
    this.data = null

    this.pagination = 0

    this.selectedOrderOption = { column: 'id', label: 'Create Date' }

    this.orderOptions = [
      { column: 'id', label: 'Create Date' },
      { column: 'id_asc', label: 'Create Date (Ascending)' },
      { column: 'amount', label: 'Amount' },
      { column: 'amount_asc', label: 'Amount (Ascending)' },
      { column: 'code', label: 'Code' },
      { column: 'code_asc', label: 'Code (Ascending)' },
      { column: 'start', label: 'Start Date' },
      { column: 'start_asc', label: 'Start Date (Ascending)' },
      { column: 'end', label: 'End Date' },
      { column: 'end_asc', label: 'End Date (Ascending)' },
      { column: 'usage', label: 'Usage' },
      { column: 'usage_asc', label: 'Usage (Ascending)' },
      { column: 'remaining', label: 'Remaining' },
      { column: 'remaining_asc', label: 'Remaining (Ascending)' },
    ]

    this.btnDisable = false

    this.currentPage = 1

    this.bakeryDetail = null

    this.imageFile = null

    this.search = ''

    this.searchOn = null

    this.limit = 12

    this.offset = 0

    this.order = { id: -1 }

    this.filter = {}

    this.isLoading = true

    this.openModal = false

    this.unit = 'KD'

    this.usage = 1

    this.amount = 10

    this.startDate = null

    this.endDate = null

    this.amount_validation = null

    this.usage_validation = null

    this.startDate_validation = null

    this.endDate_validation = null
  }
}
