import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  Row,
  CardBody,
  CardSubtitle,
  CardImg,
} from "reactstrap";
import IntlMessages from "../../util/IntlMessages";
import { Colxx } from "../CustomBootstrap";

class CustomCakeModal extends Component {
  constructor(props) {
    super(props);
    this.state = { items: null, supportedItem: null };
  }

  componentDidMount() {
    // this.setState({items:[ ...this.props.data[this.props.target]]})

    this.setState({
      items: JSON.parse(JSON.stringify(this.props.data[this.props.target])),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      items: JSON.parse(JSON.stringify(nextProps.data[nextProps.target])),
    });
  }

  componentWillUnmount() {
    // this.setState({items:null})
  }

  handleCustomItem = async (id) => {
    if (!this.state.supportedItem) {
      await this.setState({
        supportedItem: JSON.parse(
          JSON.stringify(this.props.data[this.props.target])
        ),
      });
    }
    let selected = this.state.items;
    selected.forEach((item, index) => {
      if (item.id === id) {
        selected[index].is_supported = !selected[index].is_supported;
      }
    });
    this.setState({ items: selected });
  };

  submitCustomItem = () => {
    this.props.action(this.state.items);
    this.props.toggleModal();
  };

  cancelCustomItem = async () => {
    await this.setState({ items: this.state.supportedItem });
    this.props.toggleModal();
  };

  render() {
    const { openModal, target, toggleModal } = this.props;
    return (
      <Modal isOpen={openModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id={`menu.${target}`} />
        </ModalHeader>
        <ModalBody className="d-flex flex-wrap">
          {this.state.items
            ? this.state.items.map((item) => (
                <Colxx sm="6" lg="4" xl="2" className="mb-3" key={item.id}>
                  <Card
                    onClick={() => this.handleCustomItem(item.id)}
                    className="h-100"
                  >
                    <div className="position-relative">
                      <CardImg
                        height={75}
                        top
                        alt={item.name}
                        src={
                          item.image
                            ? item.image.url
                            : "/assets/img/noimage.png"
                        }
                      />
                      {item.is_supported ? (
                        <Button
                          color="success"
                          style={{
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            padding: "0",
                            right: "5px",
                            top: "5px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            style={{ fontSize: "12px" }}
                            className="iconsminds-yes"
                          />
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            padding: "0",
                            right: "5px",
                            top: "5px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            style={{ fontSize: "12px" }}
                            className="iconsminds-close"
                          />
                        </Button>
                      )}
                    </div>
                    <CardBody className="p-3">
                      <Row className="p-0">
                        <Colxx xxs="12">
                          <CardSubtitle
                            style={{ fontSize: 11 }}
                          >{`${item.name}`}</CardSubtitle>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Colxx>
              ))
            : null}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="primary"
            className="btn-shadow"
            size="lg"
            onClick={() => this.submitCustomItem()}
          >
            {`Save`}
          </Button>{" "}
          <Button
            className="btn-outline-secondary btn-lg btn-shadow"
            onClick={() => this.cancelCustomItem()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CustomCakeModal;
