import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CouponList from "./list";

const Coupon = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} component={CouponList} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Coupon;
