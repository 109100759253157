import React, { Component } from 'react'
import { Card, CardText, CardBody, Badge } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Colxx } from '../CustomBootstrap'

class CardThumbList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { data, path } = this.props
    return (
      <Colxx xxs="12" md="6">
        <NavLink to={`/app/${path}/${data.id}`} className="d-flex col-12">
          <Card className="d-flex flex-row mb-3 col-12 p-0">
            {data.images || data.image ? (
              <img
                alt={data.name}
                src={
                  path === 'product/detail'
                    ? data.images[0]
                      ? data.images[0].url
                      : '/assets/img/noimage.png'
                    : data.image
                    ? data.image.url
                    : '/assets/img/noimage.png'
                }
                className="list-thumbnail responsive border-0"
              />
            ) : null}

            {data.name && path !== 'customers/detail' ? (
              <div className="p-0 pl-3 d-flex flex-grow-1 min-width-zero">
                <div className="card-body p-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                  <NavLink
                    to={`/app/${path}/${data.id}`}
                    className="w-50 w-sm-100"
                  >
                    <p className="list-item-heading mb-1 truncate">{`${data.name}`}</p>
                    {data.price ? (
                      <p className="list-item-heading mt-2 mb-1 truncate">{`${data.price} KD`}</p>
                    ) : null}
                  </NavLink>
                </div>
              </div>
            ) : null}

            {path === 'collection/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_en}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{` ${data.name_ar}`}</CardText>
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-center truncate">{`${
                    data.in_homepage ? 'Show in Home' : 'Dont Show in Home'
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'customers/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-left truncate">{`${
                    data.phonenumber ? data.phonenumber : '-'
                  }`}</CardText>
                </CardBody>
              </NavLink>
            ) : null}

            {path === 'users/detail' ? (
              <NavLink to={`/app/${path}/${data.id}`} className="col-12">
                <CardBody className="d-flex flex-wrap align-items-center m-0 col-12 p-3">
                  <CardText className="col-xl-4 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.name}`}</CardText>
                  <CardText className="col-xl-5 col-md-12 m-xl-0 mb-md-2 text-left truncate">{` ${data.email}`}</CardText>
                  <CardText className="col-xl-3 col-md-12 m-xl-0 mb-md-2 text-center truncate text-white pt-1 pb-1">
                    <Badge
                      color={data.type === 'admin' ? 'primary' : 'info'}
                      className="text-one"
                    >{`${data.type}`}</Badge>
                  </CardText>
                </CardBody>
              </NavLink>
            ) : null}
          </Card>
        </NavLink>
      </Colxx>
    )
  }
}

export default CardThumbList
