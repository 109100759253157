import { observable, action } from "mobx";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";

export default class CustomerDetailStore {
  @observable
  profileDetail = null;

  @observable
  isLoading = false;

  @observable
  name = "";

  @observable
  recent_password = "";

  @observable
  password = "";

  @observable
  confirm_pass = "";

  @observable
  name_validation = null;

  @observable
  password_validation = null;

  @observable
  confirm_pass_validation = null;

  @observable
  openPassModal = false;

  @observable
  saveBtnDisable = false;

  @observable
  recent_password_validation = null;

  @action
  changePassModal = () => {
    this.openPassModal = true;
  };

  @action
  togglePassModal = () => {
    this.openPassModal = false;
    this.reset();
  };

  @action
  async changePassValidation() {
    this.password_validation = await checkInput({
      name: "Old password",
      value: this.password,
      require: true,
      type: "string",
    });
    this.recent_password_validation = await checkInput({
      name: "New password",
      value: this.recent_password,
      require: true,
      min: 6,
    });
    this.confirm_pass_validation = await checkInput({
      name: "Confirm password",
      value: this.confirm_pass,
      require: true,
      min: 6,
    });

    if (
      this.recent_password &&
      this.confirm_pass &&
      this.recent_password !== this.confirm_pass
    ) {
      this.confirm_pass_validation = {
        res: false,
        msg: "New password and confirm password must be matched!",
      };
      return;
    }

    this.recent_password_validation.res &&
      this.password_validation.res &&
      this.confirm_pass_validation.res &&
      this.updatePassword();
  }

  @action
  async saveProfileValidation() {
    this.name_validation = await checkInput({
      name: "Name",
      value: this.profileDetail.name,
      require: true,
      type: "string",
    });

    this.name_validation.res && this.updateProfile();
  }

  @action
  getProfileDetail = async (history) => {
    this.isLoading = true;
    this.saveBtnDisable = false;
    const value = await axiFetch.request(
      "admin/profile/get",
      false,
      false,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.profileDetail = value.data;
      this.isLoading = false;
    }
  };

  @action
  updateProfile = async (history) => {
    this.isLoading = true;
    this.saveBtnDisable = true;
    const body = {
      name: this.profileDetail.name,
    };

    const value = await axiFetch.request(
      "admin/update",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.getProfileDetail();
      this.isLoading = false;
      successNotify(value.meta.msg);
    }
  };

  @action
  updatePassword = async (history) => {
    this.isLoading = true;
    const body = {
      password: this.recent_password,
      recent_password: this.password,
    };

    const value = await axiFetch.request(
      "admin/update",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.getProfileDetail();
      this.isLoading = false;
      successNotify(value.meta.msg);
      this.togglePassModal();
    }
    else{
      this.isLoading=false
    }
  };

  @action
  changeOpenModal = () => {
    this.openModal = true;
  };

  @action.bound
  changeName = (name) => {
    this.profileDetail.name = name;
  };

  changePassword = (pass) => {
    this.password = pass;
  };

  changeRecentPassword = (pass) => {
    this.recent_password = pass;
  };

  changeConfirmPassword = (pass) => {
    this.confirm_pass = pass;
  };

  @action
  reset() {
    this.name_validation = null;

    this.password_validation = null;

    this.confirm_pass_validation = null;

    this.recent_password_validation = null;

    this.recent_password = "";

    this.password = "";

    this.confirm_pass = "";
  }
}
