import { observable, action } from "mobx";
import _ from "lodash";
import axiFetch from "../config/fetch";
import { checkInput } from "../constants/validation";
import { successNotify } from "../util/Notify";

export default class CollectionDetailStore {
  @observable
  collectionDetail = null;

  @observable
  productList = null;

  @observable
  products = null;

  @observable
  search = null;

  @observable
  searchOn = null;

  @observable
  limit = 12;

  @observable
  offset = 0;

  @observable
  order = { id: -1 };

  @observable
  filter = {};

  @observable
  isLoading = false;

  @observable
  searchProduct = null;

  @observable
  searchOnProduct = null;

  @observable
  limitProduct = 12;

  @observable
  offsetProduct = 0;

  @observable
  orderProduct = { id: -1 };

  @observable
  filterProduct = {};

  @observable
  currentPageProduct = 1;

  @observable
  isLoadingProduct = false;

  @observable
  openDeleteModal = false;

  @observable
  openProductModal = false;

  @observable
  pagination = null;

  @observable
  paginationProduct = null;

  @observable
  en_name_validation = null;

  @observable
  ar_name_validation = null;

  @action
  async validation(id, history) {
    this.en_name_validation = await checkInput({
      name: "English Name",
      value: this.collectionDetail.name_en,
      require: true,
      type: "string",
      min: 3,
    });
    this.ar_name_validation = await checkInput({
      name: "Arabic Name",
      value: this.collectionDetail.name_ar,
      require: true,
      type: "string",
      min: 3,
    });

    this.ar_name_validation.res &&
      this.en_name_validation.res &&
      this.handleUpdateCollection(id, history);
  }

  @action
  getCollectionDetail = async (id, history) => {
    this.isLoading = false;
    const value = await axiFetch.request(
      "Collection",
      false,
      id,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.collectionDetail = value.data.collection;
      this.productList = value.data.products;
      this.isLoading = true;
    } else {
      this.isLoading = true;
    }
  };

  @action
  deleteCollection = async (id, history) => {
    this.isLoading = false;
    const value = await axiFetch.request(
      "Collection",
      false,
      `${id}/delete`,
      false,
      "get",
      false,
      null,
      history
    );
    if (value) {
      this.openDeleteModal = false;
      successNotify("Item has been deleted successfully");
      setTimeout(() => {
        history.push("/app/collection");
      }, 1000);
    } else {
      this.isLoading = true;
    }
  };

  @action
  getProduct = async (history) => {
    this.isLoadingProduct = true;
    this.changeOpenProductModal();
    const body = {
      order: this.orderProduct,
      searchOn: this.searchOnProduct,
      search: this.searchProduct,
      limit: this.limitProduct,
      offset: this.offsetProduct,
      filter: this.filterProduct,
    };
    const value = await axiFetch.request(
      "cake/getcakes",
      false,
      false,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      this.products = value.data.items;
      this.paginationProduct =
        parseInt(value.data.pagination.total / this.limitProduct) +
        (value.data.pagination.total % this.limit ? 1 : 0);
      this.isLoadingProduct = false;
    } else {
      this.isLoading = false;
    }
  };

  @action
  handleUpdateCollection = async (id, history) => {
    this.isLoading = false;
    let product_ids = [];
    this.productList.forEach((item) => {
      product_ids.push(item.id);
    });
    let body = {
      name_en: this.collectionDetail.name_en,
      name_ar: this.collectionDetail.name_ar,
      product_ids,
    };

    const value = await axiFetch.request(
      "collection",
      false,
      id,
      false,
      "post",
      false,
      body,
      history
    );
    if (value) {
      successNotify('Item has been updated successfully')
      await this.reset()
      this.getCollectionDetail(id);
    } else {
      this.isLoading = true;
    }
  };

  @action
  addItemToSelected = (value) => {
    value.map((item) => this.productList.push(item));
    const intersection = _.intersectionBy(this.productList, "id");
    this.productList = intersection;
  };

  @action
  removeFromList = (id) => {
    const product = this.productList.filter((item) => item.id !== id);
    this.productList = product;
  };

  @action
  changeOpenDeleteModal = () => {
    this.openDeleteModal = true;
  };

  @action
  changeOpenProductModal = () => {
    this.openProductModal = true;
  };

  @action
  toggleDeleteModal = () => {
    this.openDeleteModal = false;
  };

  @action
  toggleProductModal = () => {
    this.openProductModal = false;
  };

  @action
  changePage(e) {
    this.offsetProduct = (e - 1) * 12;
    this.currentPageProduct = e;
    this.getProduct();
  }

  @action.bound
  changeEnName = (name) => {
    this.collectionDetail.name_en = name;
  };

  @action
  changeArName = (name) => {
    this.collectionDetail.name_ar = name;
  };

  @action
  reset() {
    this.collectionDetail = null;

    this.productList = null;

    this.products = null;

    this.search = null;

    this.searchOn = null;

    this.limit = 12;

    this.offset = 0;

    this.order = { id: -1 };

    this.filter = {};

    this.isLoading = false;

    this.searchProduct = null;

    this.searchOnProduct = null;

    this.limitProduct = 12;

    this.offsetProduct = 0;

    this.orderProduct = { id: -1 };

    this.filterProduct = {};

    this.currentPageProduct = 1;

    this.isLoadingProduct = false;

    this.openDeleteModal = false;

    this.openProductModal = false;

    this.pagination = null;

    this.paginationProduct = null;

    this.en_name_validation = null;

    this.ar_name_validation = null;
  }
}
