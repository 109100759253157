import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProductList from './list';
import ProductDetail from './detail';
import AddProduct from './add';
import CustomDetail from './customDetail/index';
// import CustomCake from './custom';


const Product = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
            <Route path={`${match.url}/list`} component={ProductList} />
            <Route path={`${match.url}/detail/:id`} component={ProductDetail} />
            <Route path={`${match.url}/customCakeDetail/:id`} component={CustomDetail} />
            <Route path={`${match.url}/addProduct`} component={AddProduct} />
            {/* <Route path={`${match.url}/customCake`} component={CustomCake} /> */}
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Product;