import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {
  Row,
  Button,
  Input,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardImg,
  CardText,
} from 'reactstrap'
import IntlMessages from '../../../util/IntlMessages'
import { Colxx, Separator } from '../../../components/CustomBootstrap'
import BreadcrumbContainer from '../../../components/BreadcrumbContainer'
import DeleteModal from '../../../components/Modal/deleteModal'
import ImageGalleryCard from '../../../components/imageGalleryCard'
import SelectInput from '../../../components/select'
import { FormikSwitch } from '../../../components/FormValidations/FormikFields'
import ItemModal from '../../../components/Modal/ItemModal'
import CollectionModal from '../../../components/Modal/collectionModal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Uploader from '../../../components/Uploader'

@inject('stores')
@observer
class ProductDetail extends Component {
  @observable
  store = this.props.stores.productDetail

  @observable
  login = this.props.stores.login
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      imageFile: null,
      discount: false,
      available: false,
      data: null,
      isMulti: false,
    }
  }
  componentDidMount() {
    let path = this.props.match.params.id
    this.setState({ id: path })
    this.store.getCakeDetail(path, this.props.history)
  }

  componentWillUnmount() {
    this.store.reset()
  }

  handleHasDiscount = (value) => {
    this.setState({ discount: value })
  }

  handleIsAvailable = (value) => {
    this.setState({ available: value })
  }

  setData = async (value, target, isMulti) => {
    await this.setState({ data: value, target, isMulti })
    this.store.changeOpenItemModal()
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        {!this.store.isLoading ? (
          <div className="loading"></div>
        ) : (
          <Row>
            {this.store.openItemModal ? (
              <ItemModal
                openModal={this.store.openItemModal}
                toggleModal={() => this.store.toggleItemModal()}
                target={this.state.target}
                data={this.state.data}
                addItems={(data, target) => {
                  this.store.setItems(data, target)
                }}
                isMulti={this.state.isMulti}
              ></ItemModal>
            ) : null}

            {this.store.openProductModal ? (
              <CollectionModal
                openModal={this.store.openProductModal}
                data={this.store.products}
                loading={this.store.isLoadingProduct}
                toggleModal={() => this.store.toggleProductModal()}
                pagination={this.store.paginationProduct}
                limit={this.store.limitProduct}
                currentPage={this.store.currentPageProduct}
                changePage={(e) => this.store.changePage(e)}
                target="product"
                addItems={(data) => this.store.addItemToSelected(data)}
              />
            ) : null}
            <Colxx xxs="12">
              <BreadcrumbContainer
                heading={<IntlMessages id="menu.product" />}
                match={this.props.match}
              />
              <Button
                color="btn-outline-secondary"
                className="btn-shadow float-right ml-2"
                size="lg"
                onClick={() => this.store.changeOpenDeleteModal()}
              >
                <IntlMessages id="pages.delete" />
              </Button>
              <DeleteModal
                target={this.store.enName ? this.store.enName : null}
                openModal={this.store.openDeleteModal}
                toggleModal={() => this.store.toggleDeleteModal()}
                action={() =>
                  this.store.deleteCake(this.state.id, this.props.history)
                }
              />
              <Button
                color="primary"
                className="btn-shadow float-right"
                size="lg"
                onClick={() =>
                  this.store.validation(this.state.id, this.props.history)
                }
              >
                <IntlMessages id="pages.save" />
              </Button>
              <Separator className="mb-5" />
            </Colxx>
            <Colxx xxs="12">
              <Row className="d-flex justify-content-center">
                <Colxx
                  xxs="12"
                  lg="12"
                  className="mb-6 border-bottom"
                  style={{ marginBottom: '25px' }}
                >
                  <CardTitle></CardTitle>
                  <Row>
                    <Colxx
                      xxs="12"
                      md="6"
                      lg="4"
                      className="mb-4 d-flex flex-column align-item-center"
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Image`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          {this.store.showUploader ? (
                            <>
                              <Button
                                color="primary"
                                size="lg"
                                className="pointer mb-4 col-12"
                                style={{}}
                                onClick={() => {
                                  this.store.handleImageUploader()
                                }}
                              >
                                <Label
                                  className="btn btn-default no-padding col-12"
                                  style={{
                                    padding: '0px',
                                    marginBottom: '0px',
                                  }}
                                >
                                  <strong>Add New Image</strong>
                                </Label>
                              </Button>
                              <ImageGalleryCard
                                data={this.store.images}
                                deleteImage={(id) =>
                                  this.store.handleDeleteImage(id)
                                }
                                changeOrderImages={(arr) =>
                                  this.store.changeOrderImages(arr)
                                }
                              />
                            </>
                          ) : (
                            <Uploader
                              multiple={true}
                              ref={(instance) =>
                                (this.productDetail = instance)
                              }
                              images={this.state.imageFile}
                              ratio="1:1"
                              size={{ width: 400, height: 400 }}
                              handleImageUploader={() =>
                                this.store.handleImageUploader()
                              }
                              changeImageFile={(data) => {
                                this.store.changeImageFile(
                                  data,
                                  this.state.id,
                                  this.props.history
                                )
                              }}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx xxs="12" md="6" lg="4" className="mb-4 ">
                      <Card className="mb-2 h-100">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Detail`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx xxs="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.available" />
                            </p>

                            <div className="d-flex align-items-center col-12 p-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name="available"
                                value={this.store.is_available}
                                onChange={(e) =>
                                  this.store.handleIsAvailable(
                                    e.target,
                                    !this.store.is_available
                                  )
                                }
                              />
                            </div>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={
                                  this.store.enName ? this.store.enName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeEnName(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.en_name_validation
                                ? this.store.en_name_validation.msg
                                  ? this.store.en_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.name-ar" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name_ar"
                                value={
                                  this.store.arName ? this.store.arName : ''
                                }
                                type="text"
                                onChange={(e) =>
                                  this.store.changeArName(e.target.value)
                                }
                                className="ar"
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.ar_name_validation
                                ? this.store.ar_name_validation.msg
                                  ? this.store.ar_name_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.price" /> ( KD )
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-0">
                              <Input
                                name="name"
                                value={this.store.price ? this.store.price : ''}
                                type="text"
                                onChange={(e) =>
                                  this.store.changePrice(e.target.value)
                                }
                              />
                            </div>
                            <span className="req-validation ">
                              {this.store.price_validation
                                ? this.store.price_validation.msg
                                  ? this.store.price_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.discount" />
                            </p>

                            <div className="d-flex align-items-center col-12 p-0">
                              <FormikSwitch
                                className="custom-switch custom-switch-primary-inverse"
                                name="discount"
                                value={this.store.discount}
                                onChange={(e) =>
                                  this.store.changeDiscount(
                                    e.target,
                                    !this.store.discount
                                  )
                                }
                              />
                            </div>
                          </Colxx>
                          {this.store.discount ? (
                            <Colxx xxs="12" lg="12" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="pages.oldPrice" />( KD )
                                <span className="req"> *</span>
                              </p>
                              <div className="mb-0">
                                <Input
                                  name="name"
                                  value={
                                    this.store.old_price
                                      ? this.store.old_price
                                      : ''
                                  }
                                  type="text"
                                  onChange={(e) =>
                                    this.store.changeOldPrice(e.target.value)
                                  }
                                />
                              </div>
                              <span className="req-validation ">
                                {this.store.price_validation
                                  ? this.store.price_validation.msg
                                    ? this.store.price_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          ) : null}
                          <Colxx xxs="12" />
                          {this.login.userType === 'admin' && (
                            <Colxx xxs="12" lg="12" className="mb-3">
                              <p className="text-muted text-small mb-2">
                                <IntlMessages id="menu.bakery" />
                                <span className="req"> *</span>
                              </p>
                              <div
                                className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                                style={{ border: '1px solid #ddd' }}
                              >
                                {this.store.bakery && (
                                  <Card
                                    // key={item.id}
                                    className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  >
                                    <div className=" d-flex align-items-center">
                                      <div
                                        className=" p-0 m-0"
                                        style={{ width: '40px' }}
                                      >
                                        <div
                                          className="position-relative col-12 m-0"
                                          style={{ paddingTop: '100%' }}
                                        >
                                          <CardImg
                                            src={
                                              this.store.bakery.image
                                                ? this.store.bakery.image
                                                : '/assets/img/noimage.png'
                                            }
                                            style={{ borderRadius: '50%' }}
                                          ></CardImg>
                                        </div>
                                      </div>
                                      <CardText
                                        className="text-small pl-2 pr-2 m-0 d-flex "
                                        style={{ flex: 1 }}
                                      >
                                        {this.store.bakery.label}
                                      </CardText>
                                      <i
                                        className="iconsminds-close remove-icon "
                                        onClick={() =>
                                          this.store.removeBakery()
                                        }
                                      ></i>
                                    </div>
                                  </Card>
                                )}
                                {!this.store.bakery ? (
                                  <Button
                                    color="primary"
                                    className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                    onClick={() => {
                                      this.setData(
                                        this.store.bakeriesList,
                                        'bakery',
                                        false
                                      )
                                    }}
                                    style={{ height: 40, width: 70 }}
                                  >
                                    {' '}
                                    <IntlMessages id="pages.add" />
                                    <i className="iconsminds-add" />
                                  </Button>
                                ) : null}
                              </div>
                              <span className="req-validation ">
                                {this.store.bakery_validation
                                  ? this.store.bakery_validation.msg
                                    ? this.store.bakery_validation.msg
                                    : null
                                  : null}
                              </span>
                            </Colxx>
                          )}
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.serving" />
                              <span className="req"> *</span>
                            </p>

                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.serving}
                              onChange={(value) => this.store.setServing(value)}
                              options={this.store.servingList}
                              isMulti={false}
                            />
                            <span className="req-validation ">
                              {this.store.serving_validation
                                ? this.store.serving_validation.msg
                                  ? this.store.serving_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>
                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.category" />
                              <span className="req"> *</span>
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.category.map((item) => (
                                <Card
                                  key={item.id}
                                  className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                >
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {item.label}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon "
                                      onClick={() =>
                                        this.store.removeCategory(item)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              {!this.store.category.length ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.categoriesList,
                                      'category',
                                      false
                                    )
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {' '}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                            <span className="req-validation ">
                              {this.store.category_validation
                                ? this.store.category_validation.msg
                                  ? this.store.category_validation.msg
                                  : null
                                : null}
                            </span>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.filling" />
                            </p>

                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.fillings.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon"
                                      onClick={() =>
                                        this.store.removeFilling(item)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.fillingList,
                                    'filling',
                                    true
                                  )
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {' '}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.flavor" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.flavors.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon"
                                      onClick={() =>
                                        this.store.removeFlavor(item)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.flavorList,
                                    'flavor',
                                    true
                                  )
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {' '}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.shape" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.shapes.map((item) => (
                                <Card
                                  key={item.id}
                                  className="p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                  style={{
                                    width: 'max-content',
                                    maxWidth: '100%',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            item.image
                                              ? item.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText className="text-small pl-2 pr-2 m-0">
                                      {item.label}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon"
                                      onClick={() =>
                                        this.store.removeShape(item)
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              ))}
                              <Button
                                color="primary"
                                className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                onClick={() => {
                                  this.setData(
                                    this.store.shapeList,
                                    'shape',
                                    true
                                  )
                                }}
                                style={{ height: 40, width: 70 }}
                              >
                                {' '}
                                <IntlMessages id="pages.add" />
                                <i className="iconsminds-add" />
                              </Button>
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.layer" />
                            </p>
                            <SelectInput
                              className="react-select"
                              classNamePrefix="react-select"
                              value={this.store.layer}
                              onChange={(value) => this.store.setLayer(value)}
                              options={this.store.layerList}
                              isMulti={false}
                            />
                          </Colxx>

                          <Colxx xxs="12" lg="12" className="mb-3">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="menu.outerShell" />
                            </p>
                            <div
                              className="col-12 d-flex flex-wrap align-items-center p-2 m-0 mt-2 mb-2"
                              style={{ border: '1px solid #ddd' }}
                            >
                              {this.store.outerShell && (
                                <Card
                                  // key={item.id}
                                  className="col-12 p-1 d-flex align-items.center mr-1 mb-1 mt-1"
                                >
                                  <div className=" d-flex align-items-center">
                                    <div
                                      className=" p-0 m-0"
                                      style={{ width: '40px' }}
                                    >
                                      <div
                                        className="position-relative col-12 m-0"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <CardImg
                                          src={
                                            this.store.outerShell.image
                                              ? this.store.outerShell.image
                                              : '/assets/img/noimage.png'
                                          }
                                          style={{ borderRadius: '50%' }}
                                        ></CardImg>
                                      </div>
                                    </div>
                                    <CardText
                                      className="text-small pl-2 pr-2 m-0 d-flex "
                                      style={{ flex: 1 }}
                                    >
                                      {this.store.outerShell.label}
                                    </CardText>
                                    <i
                                      className="iconsminds-close remove-icon "
                                      onClick={() =>
                                        this.store.removeOuterShell()
                                      }
                                    ></i>
                                  </div>
                                </Card>
                              )}
                              {!this.store.outerShell ? (
                                <Button
                                  color="primary"
                                  className="btn-shadow d-flex justify-content-center align-items-center p-0"
                                  onClick={() => {
                                    this.setData(
                                      this.store.outerShellsList,
                                      'outerShell',
                                      false
                                    )
                                  }}
                                  style={{ height: 40, width: 70 }}
                                >
                                  {' '}
                                  <IntlMessages id="pages.add" />
                                  <i className="iconsminds-add" />
                                </Button>
                              ) : null}
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description" />
                              <span className="req"> *</span>
                            </p>
                            <div className="mb-3">
                              <Input
                                name="address"
                                value={
                                  this.store.enDescription
                                    ? this.store.enDescription
                                    : ''
                                }
                                type="textarea"
                                onChange={(e) =>
                                  this.store.changeEnDescription(e.target.value)
                                }
                              />
                              <span className="req-validation ">
                                {this.store.en_description_validation
                                  ? this.store.en_description_validation.msg
                                    ? this.store.en_description_validation.msg
                                    : null
                                  : null}
                              </span>
                            </div>
                          </Colxx>

                          <Colxx xxs="12" lg="12">
                            <p className="text-muted text-small mb-2">
                              <IntlMessages id="pages.description-ar" />
                            </p>
                            <div className="mb-3">
                              <Input
                                name="address"
                                value={
                                  this.store.arDescription
                                    ? this.store.arDescription
                                    : ''
                                }
                                type="textarea"
                                className="ar"
                                onChange={(e) =>
                                  this.store.changeArDescription(e.target.value)
                                }
                              />
                            </div>
                          </Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx
                      xxs="12"
                      md="12"
                      lg="4"
                      className="mb-4"
                      style={{ height: 'max-content' }}
                    >
                      <Card className="mb-2">
                        <CardBody>
                          <CardTitle className="mb-3 col-12">
                            <span className="font-weight-bold text-uppercase">
                              {`Similar Product`}
                            </span>
                          </CardTitle>
                          <Separator className="mb-5" />
                          <Colxx
                            xxs="12"
                            className="p-0 m-0 mb-3 d-flex flex-wrap"
                          >
                            {this.store.productList
                              ? this.store.productList.map((item) => (
                                  <div
                                    // href={`/app/product/detail/${item.id}`}
                                    className="p-1 col-6"
                                    key={item.id}
                                  >
                                    <Card className="col-12 m-0 p-0">
                                      <div
                                        className="position-relative col-12"
                                        style={{ paddingTop: '100%' }}
                                      >
                                        <Button
                                          onClick={() =>
                                            this.store.removeFromList(item.id)
                                          }
                                          color="primary"
                                          style={{
                                            position: 'absolute',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            padding: '0',
                                            right: '5px',
                                            top: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            style={{ fontSize: '20px' }}
                                            className="iconsminds-close"
                                          />
                                        </Button>

                                        <p
                                          className="pointer m-0"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/app/product/detail/${item.id}`
                                            )
                                          }}
                                          style={{
                                            position: 'absolute',
                                            width: '90%',
                                            height: '30px',
                                            borderRadius: '0.75rem',
                                            padding: '8',
                                            right: '5%',
                                            top: '33%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 1,
                                            color: 'white',
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.75',
                                          }}
                                        >
                                          {item.name}
                                        </p>
                                        <CardImg
                                          src={item.images[0].url}
                                        ></CardImg>
                                      </div>
                                    </Card>
                                  </div>
                                ))
                              : null}
                          </Colxx>
                          <Button
                            color="primary"
                            size="lg"
                            className="pointer mb-4 col-12"
                            style={{}}
                            onClick={() =>
                              this.store.getProduct(this.props.history)
                            }
                          >
                            <Label
                              className="btn btn-default no-padding"
                              style={{
                                padding: '0px',
                                marginBottom: '0px',
                              }}
                            >
                              <strong>Add New Similar</strong>{' '}
                            </Label>
                          </Button>
                          <Colxx xxs="12" lg="12" className="mb-3"></Colxx>
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )}
      </Fragment>
    )
  }
}

export default ProductDetail
